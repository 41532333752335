/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import {
	getLocationGroup,
	getLocationGroupFilterParametersFunc,
	getLocationGroupSelectListFunc,
} from 'redux/features/dataManagement/adminPanel/locationGroupSlice'
import { FaRegEdit } from 'react-icons/fa'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import SelectDr from 'components/common/SelectDr'
import { getGiftLimit, getGiftLimitFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftLimitSlice'
import { permissionTypes } from 'types/permissionTypes'
import { AiOutlineCheckSquare, AiOutlineCloseSquare  } from 'react-icons/ai'
import {Tooltip} from 'react-tooltip';


const CustomSortIcon = () => {
	return <img src={images.filterico} alt='' style={{ margin: '0px' }} />
}

const GiftLimit = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.giftLimit)

	const { modalId, modalStatus, modalToggle } = modals
	const { giftLimit, giftLimitFilterParameters } = adminPanel

	const [giftLimitCompanyId, setGiftLimitCompanyId] = useState()
	const [giftLimitYear, setGiftLimitYear] = useState({value: new Date().getFullYear(), label: new Date().getFullYear()})
	const [giftLimitCurrencyId, setGiftLimitCurrencyId] = useState()
	const [giftLimitIncomingLimit, setGiftLimitIncomingLimit] = useState()
	const [giftLimitOutgoingLimit, setGiftLimitOutgoingLimit] = useState()
	const [targetCompanyId, setTargetCompanyId] = useState()
	// const [isGeneralManagerApprovalRequired, setIsGeneralManagerApprovalRequired] = useState()
	const [isManagerApprovalRequiredForIncoming, setIsManagerApprovalRequiredForIncoming] = useState()
	//const [statusChecked, setStatusChecked] = useState(0)

	//create-initial -- states
	const [giftLimitCompanyIdInitial, setGiftLimitCompanyIdInitial] = useState()
	const [giftLimitYearInitial, setGiftLimitYearInitial] = useState({value: new Date().getFullYear(), label: new Date().getFullYear()})
	//copy-from-existing -- states
	const [giftLimitCompanyIdCopy, setGiftLimitCompanyIdCopy] = useState()
	const [giftLimitSourceYearCopy, setGiftLimitSourceYearCopy] = useState()
	const [giftLimitTargetYearCopy, setGiftLimitTargetYearCopy] = useState()

	//option states
	const [companySelectList, setCompanySelectList] = useState()
	const [currencySelectList, setCurrencySelectList] = useState()
	const [giftLimitYearOptions, setGiftLimitYearOptions] = useState([])


	//table filter area
	const [filters, setFilters] = useState({
		companyId: '',
		targetCompanyId: '',
		year: '',			
		sortingOrderByVal: "desc",
		sortingColumnName: "",
		page: 1,
	})


	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations

	const navigate = useNavigate()

	const handleRemove = (item) => {
		dispatch(
			openModal({ open: true, type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'gift-limit' } } })
		)
	}
	const handleEdit = (item) => {
		request(`/company-gift-limit/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res?.data?.result,
								// buradan bana status dönmemekte.
								companySelectList: companySelectList,
								currencySelectList: currencySelectList,
								giftLimitYearOptions: giftLimitYearOptions,
								choiceCompanySelectList: {
									value: res?.data?.result?.companyId,
									label: companySelectList?.find((compSelectList) => compSelectList?.name == res?.data?.result?.companyId)?.value,
								},
								choiceTargetCompanySelectList: {
									value: res?.data?.result?.targetCompanyId,
									label: companySelectList?.find((compSelectList) => compSelectList?.name == res?.data?.result?.targetCompanyId)?.value,
								},
								choiceCurrencySelectList: {
									value: res?.data?.result?.currencyId,
									label: currencySelectList?.find((currSelectList) => currSelectList?.name == res?.data?.result?.currencyId)?.value,
								},
								choiceGiftLimitYearOptions: {
									value: res?.data?.result?.year,
									label: giftLimitYearOptions?.find((yearOpt) => yearOpt?.name == res?.data?.result?.year)?.value,
								},
								//parentName: getLocationGroupSelectList?.find(getLocGroup => getLocGroup?.name == res?.data?.result?.parentId)?.value,
								editItem: 'gift-limit',
							},
						},
					})
				)
			}
		})
	}

	const COLUMNS = [
		{
			label: t('companyName'),
			renderCell: (item) =>  <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
			{item.companyName}
			{item.subCompaniesIncludedToLimit && 
			// <img title={t('subsidiariesIncludedInLimit')} src={images.warningRounded} alt='' />
			<>
			<img
				data-tooltip-id="tooltip-info"
				data-tooltip-content={
				`${t('subsidiariesIncludedInLimit')}`
				}
				src={images.warningRounded}
				alt=''
			/>
			<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
			</>
			}
			{item.usingParentCompanyLimit && 
			// <img title={t('usingParentCompanyLimit')} src={images.warningRounded} alt='' />
			<>
			<img
				data-tooltip-id="tooltip-info"
				data-tooltip-content={
				`${t('usingParentCompanyLimit')}`
				}
				src={images.warningRounded}
				alt=''
			/>
			<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
			</>
			}
		</div>,
			name: 'companyName',
		},
		{
			label: t('giftRelatedOrganizationName'),
			renderCell: (item) => item.targetCompanyName,
			name: 'targetCompanyName',
		},
		{
			label: t('year'),
			renderCell: (item) => item.year,
			name: 'year',
		},
		{
			label: t('CurrencyUnit'),
			renderCell: (item) => item.currencyCode,
			name: 'currencyCode',
		},
		// renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
		{
			label: t('incomingLimit'),
			renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.incomingLimit),
			name: 'incomingLimit',
		},
		{
			label: t('incomingLimitRemaining'),
			renderCell: (item) =>
				new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.remainingIncomingLimit),
			name: 'remainingIncomingLimit',
		},
		{
			label: t('outgoingLimit'),
			renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.outgoingLimit),
			name: 'outgoingLimit',
		},
		{
			label: t('outgoingLimitRemaining'),
			renderCell: (item) =>
				new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item?.remainingOutgoingLimit),
			name: 'remainingOutgoingLimit',
		},
		// {
		// 	label: t('generalManagerApproval'),
		// 	renderCell: (item) =>
		// 		item.isGeneralManagerApprovalRequired ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
		// 	name: 'isGeneralManagerApprovalRequired',
		// },
		{
			label: t('isManagerApprovalRequiredForIncoming'),
			renderCell: (item) =>
				item.isManagerApprovalRequiredForIncoming ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
			name: 'isManagerApprovalRequiredForIncoming',
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					{currentUser?.permissions[permissionTypes.CompanyGiftLimitsUpsert] && <div className='editBtn' onClick={(e) => e.stopPropagation()}>
						<FaRegEdit size={15} onClick={() => handleEdit(item)} />
						<div onClick={() => handleEdit(item)} className=''>
						{t('edit')}
						</div>
					</div>}
					{currentUser?.permissions[permissionTypes.CompanyGiftLimitsDelete] && <div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
						<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
						<div onClick={() => handleRemove(item)} className=''>
						{t('delete')}
						</div>
					</div>}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.companyId, filters.targetCompanyId, filters.year, filters.sortingColumnName, filters.sortingOrderByVal])

	useEffect(() => {
		request(`/company-gift-limit?Page=${filters?.page}&CompanyId=${filters.companyId}&TargetCompanyId=${filters.targetCompanyId}&Year=${filters.year}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					//buraya yeni parametreler eklenecek ...
					companyId: res.data.result.companyId,
					year: res.data.result.year,
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getGiftLimitFilterParametersFunc(filterPrm))
				dispatch(getGiftLimit(res.data.result.items))
			}
		})
	}, [filters])
	
	const today = new Date().getFullYear()
	const twentyYearsAgo = new Date().getFullYear() - 20
	const fiveYearsLater = new Date().getFullYear() + 5

	let yearOptions = useMemo(() => {
		const options = []
		for (let year = fiveYearsLater; year >= twentyYearsAgo; year--) {
			options.push({ name: year, value: year })
		}
	
		return options
	}, [twentyYearsAgo, fiveYearsLater])

	let companySelectOptions = useMemo(() => {
		const options = []
		request('/company/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				options.push(...res.data.result.items)
			}
		})
		return options
	}, [])

	let currencySelectOptions = useMemo(() => {
		const options = []
		request('/currency/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				options.push(...res.data.result.items)
			}
		})
		return options
	}, [])

	useEffect(() => {
		setCompanySelectList(companySelectOptions)
		setCurrencySelectList(currencySelectOptions)
		setGiftLimitYearOptions(yearOptions)
	}, [])

	let nodes = giftLimit

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request(`/company-gift-limit/export-excel?Page=${filters?.page}&CompanyId=${filters.companyId}&TargetCompanyId=${filters.targetCompanyId}&Year=${filters.year}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('company-gift-limit.xlsx', res.data.result)
			}
		})
	}

	const giftLimitAddBtn = () => {
		request(`/company-gift-limit`, {
			method: 'POST',
			data: {
				companyId: giftLimitCompanyId?.value,
				year: giftLimitYear?.value,
				currencyId: giftLimitCurrencyId?.value,
				incomingLimit: giftLimitIncomingLimit,
				outgoingLimit: giftLimitOutgoingLimit,
				targetCompanyId: targetCompanyId?.value,
				//status: statusChecked,
				//isGeneralManagerApprovalRequired: isGeneralManagerApprovalRequired == 1 ? true : false,
				isManagerApprovalRequiredForIncoming: isManagerApprovalRequiredForIncoming == 1 ? true : false
			},
		}).then((res) => {
			if (res.data.ok) {
				request('/company-gift-limit', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getGiftLimitFilterParametersFunc(filterPrm))
						dispatch(getGiftLimit(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				setGiftLimitCompanyId()
				setTargetCompanyId()
				setGiftLimitCurrencyId()
				setGiftLimitIncomingLimit()
				setGiftLimitOutgoingLimit()
				setGiftLimitYear()
			}, 100)
		})
	}

	const giftLimitCreateInitialAddBtn = () => {
		request(`/company-gift-limit/create-initial`, {
			method: 'POST',
			data: {
				companyId: giftLimitCompanyIdInitial?.value,
				year: giftLimitYearInitial?.value
			},
		}).then((res) => {
			if (res.data.ok) {
				request('/company-gift-limit', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getGiftLimitFilterParametersFunc(filterPrm))
						dispatch(getGiftLimit(res.data.result.items))
					}
				})
			}
			toast.success('Oluşturma işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				setGiftLimitCompanyIdInitial()
				setGiftLimitYearInitial()
			}, 100)
		})
	}
	const giftLimitCopyExistingAddBtn = () => {
		request(`/company-gift-limit/copy-from-existing`, {
			method: 'POST',
			data: {
				companyId: giftLimitCompanyIdCopy?.value,
				sourceYear: giftLimitSourceYearCopy?.value,
				targetYear: giftLimitTargetYearCopy?.value,
			},
		}).then((res) => {
			if (res.data.ok) {
				request('/company-gift-limit', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getGiftLimitFilterParametersFunc(filterPrm))
						dispatch(getGiftLimit(res.data.result.items))
					}
				})
			}
			toast.success('Aktarma işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				setGiftLimitCompanyId()
				setGiftLimitSourceYearCopy()
				setGiftLimitTargetYearCopy()
			}, 100)
		})
	}

	const goToPrevPage = () => {
		if (giftLimitFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: giftLimitFilterParameters?.pageIndex - 1}))
	}

	const goToNextPage = () => {
		if (giftLimitFilterParameters?.pageIndex + 1 >= giftLimitFilterParameters?.totalPages) return
		setFilters(prev => ({...prev, page: giftLimitFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: giftLimitFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	return (
		<div className='panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div style={{ gap: '10px', flexWrap: 'wrap' }} className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.CompanyGiftLimitsUpsert] && <Button
						onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'adminPanel' } }))}
						name={t('addGiftLimit')}
						img={images.plus}
						color='#fff'
						backgroundColor='#1e74ad'
						height='38px'
					/>}
					{currentUser?.permissions[permissionTypes.CompanyGiftLimitsUpsert] && <Button
						onclick={() => dispatch(openModal({ open: true, type: { modalId: 4, modalStatus: 'adminPanel' } }))}
						name={t('createDefaultRecords')}
						img={images.plus}
						color='#fff'
						backgroundColor='#08a52b'
						height='38px'
					/>}
					{currentUser?.permissions[permissionTypes.CompanyGiftLimitsUpsert] && <Button
						onclick={() => dispatch(openModal({ open: true, type: { modalId: 5, modalStatus: 'adminPanel' } }))}
						name={t('transferRecordsfromPreviousYear')}
						img={images.plus}
						color='#fff'
						backgroundColor='#C1312F'
						height='38px'
					/>}
				</div>
			</div>

			{/* {loading ? (
				<div className='panel-table-loading'>
					<Loading width='45px' height='45px' borderWidth='5px' />
				</div>
			) :  */}

			<div className='tableCard'>
				<Table
					filterType={4}
					gridCSS='gift-limit'
					paginationAllFunctionsList={paginationAllFunctionsList}
					sortTable={sortTable}
					data={data}
					columns={COLUMNS}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					isLoading={loading}
				/>
			</div>

			{/* } */}

			{modalId == 3 && modalToggle && modalStatus == 'adminPanel' && (
				<Modal customModal='userModal userModalUpd'>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('addGiftLimit')} color='#242424' fontweight='bold' size='24px' />

						<div style={{ width: '100%', marginTop: '10px' }} className='updContainerUserMng'>
							<div style={{ marginBottom: '20px' }} className='userManagement-addingModal'>
								<SelectDr
									onChange={(value) => setGiftLimitCompanyId(value ? value : '')}
									type={0}
									//isLoading={loading}
									options={companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('companyName')}
								/>
								<SelectDr
									onChange={(value) => setTargetCompanyId(value ? value : '')}
									type={0}
									//isLoading={loading}
									options={companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('giftRelatedOrganizationName')}
								/>
							</div>
							<div style={{ marginBottom: '20px' }} className='userManagement-addingModal'>
								<SelectDr
									onChange={(value) => setGiftLimitYear(value ? value : '')}
									type={0}
									//isLoading={loading}
									defaultValue={{ value:today , label: today }}
									options={giftLimitYearOptions?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('year')}
								/>
								<SelectDr
									onChange={(value) => setGiftLimitCurrencyId(value ? value : '')}
									type={0}
									//isLoading={loading}
									options={currencySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('CurrencyUnit')}
								/>
							</div>
							<div className='userManagement-addingModal'>
								<Input value={giftLimitIncomingLimit} setValue={setGiftLimitIncomingLimit} labelColor='#292929' label={t('incomingLimit')} type='number' />
								<Input value={giftLimitOutgoingLimit} setValue={setGiftLimitOutgoingLimit} labelColor='#292929' label={t('outgoingLimit')} type='number' />
							</div>
						</div>

						{/* <div style={{marginTop: "0px"}} className='modal-type1-checkArea'>
							<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
							<div>Aktif</div>
						</div> */}
						{/* <div style={{ marginTop: '-16px' }} className='modal-type1-checkArea'>
							<input onChange={(e) => setIsGeneralManagerApprovalRequired(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
							<div>{t('generalManagerApproval')}</div>
						</div>   */}
						<div style={{ marginTop: '-18px' }} className='modal-type1-checkArea'>
							<input onChange={(e) => setIsManagerApprovalRequiredForIncoming(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
							<div>{t('isManagerApprovalRequiredForIncoming')}</div>
						</div>
						{/* <div className='modal-type1-checkArea'>
							<input onChange={(e) => setFixedChecked(e.target.checked)} type='checkbox' name='' id='' />
							<div>{t('fixed')}</div>
						</div> */}
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftLimitAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}

			{modalId == 4 && modalToggle && modalStatus == 'adminPanel' && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('createDefaultRecords')} color='#242424' fontweight='bold' size='24px' />

						<div className='location-group-addingModal giftType-group-addingModal'>
							<SelectDr
								onChange={(value) => setGiftLimitCompanyIdInitial(value ? value : '')}
								type={0}
								//isLoading={loading}
								options={companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
								placeholder={''}
								label={t('companyName')}
							/>
							<SelectDr
								onChange={(value) => setGiftLimitYearInitial(value ? value : '')}
								type={0}
								//isLoading={loading}
								defaultValue={{ value:today , label: today }}
								options={giftLimitYearOptions?.map((prev) => ({ value: prev.name, label: prev.value }))}
								placeholder={''}
								label={t('year')}
							/>
						</div>
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftLimitCreateInitialAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}

			{modalId == 5 && modalToggle && modalStatus == 'adminPanel' && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('transferRecordsfromPreviousYear')} color='#242424' fontweight='bold' size='24px' />

						<div className='location-group-addingModal giftType-group-addingModal'>
							<SelectDr
								onChange={(value) => setGiftLimitCompanyIdCopy(value ? value : '')}
								type={0}
								//isLoading={loading}
								options={companySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
								placeholder={''}
								label={t('companyName')}
							/>
							<SelectDr
								onChange={(value) => setGiftLimitSourceYearCopy(value ? value : '')}
								type={0}
								//isLoading={loading}
								options={giftLimitYearOptions?.map((prev) => ({ value: prev.name, label: prev.value }))}
								placeholder={''}
								label={t('sourceYear')}
							/>
							<SelectDr
								onChange={(value) => setGiftLimitTargetYearCopy(value ? value : '')}
								type={0}
								//isLoading={loading}
								options={giftLimitYearOptions?.map((prev) => ({ value: prev.name, label: prev.value }))}
								placeholder={''}
								label={t('targetYear')}
							/>
						</div>
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftLimitCopyExistingAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default GiftLimit
