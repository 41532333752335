/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CompactTable } from '@table-library/react-table-library/compact'
import Button from 'components/common/Button'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import Loading from 'components/common/Loading'
import moment from 'moment'
import { FiDownload } from 'react-icons/fi'
import Input from 'components/common/Input'
import images from 'assets/images/images'

const CompanyHospitalityDetail = () => {
	const { t } = useTranslation()
	const { id } = useParams()
	const [request, loading] = useRequest()
	const dispatch = useDispatch()
	const { modals } = useSelector((state) => state.general)
	const navigate = useNavigate()

	let substitutePage = window.location.pathname.split('/')[1] == "substitute-panel"


	const [details, setDetails] = useState()
	const [rejectReason, setRejectReason] = useState('')

	const { modalToggle, modalStatus, modalId } = modals

	useEffect(() => {
		if (id) {
			request(`/company-hospitality/${id}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setDetails(res.data.result)
				}
			})
		}
	}, [id])

	// "amount": Tutar
	// "eventDate": Etkinlik Tarihi
	// "hasPublicOfficial": Kamu Görevlisi Var Mı?, Kamu Görevlisi Mevcut
	// "currencyCode":
	// "companyName": Şirket
	// "eventPurposeName": Etkinlik Amacı
	// "eventRelatedPartyTypeName": Etkinlik İlişkili Taraf

	// "userFullName": Oluşturan Kullanıcı

	
	const approveStatus = {
		0 : t('AwaitingApproval'),
		1 : t('approved'),
		2 : t('rejected'),
	}

	const nodes = [
		{ name: t('company'), desc: `: ${details?.companyName}` },
		{ name: t('eventPurpose'), desc: `: ${details?.eventPurposeName}` },
		{ name: t('eventDate'), desc: `: ${details?.eventDate && moment(details.eventDate).format('YYYY-MM-DD')}` },
		{ name: t('CreatingUser'), desc: `: ${details?.userFullName}` },
		{ name: t('Amount'), desc: `: ${details?.amount}` },
		{ name: t('CurrencyUnit'), desc: `: ${details?.currencyCode}` },
		{ name: t('activityRelatedParty'), desc: `: ${details?.eventRelatedPartyTypeName}` },
		{ name: t('areThereAnyPublicOfficials'), desc: `: ${details?.hasPublicOfficial ? t('yes') : t('no')}` },

		{ name: t('descriptionCompanyDetail'), desc: `: ${details?.description ? details?.description : ''}` },
		{ name: t('customEventRejectReason'), desc: `: ${details?.customEventRejectReason ? details?.customEventRejectReason : ''}` },
		{ name: t('managerApprovalDate'), desc: `: ${details?.managerApprovalDate ? moment(details.managerApprovalDate).format('YYYY-MM-DD') : ''}` },
		{ name: t('approverManagerFullName'), desc: `: ${details?.approverManagerFullName ? details?.approverManagerFullName : ''}` },
		{ name: t('approvalStatus'), desc: `: ${details?.approvalStatus ? approveStatus[details?.approvalStatus] : ""}` },
	]
	const data = { nodes }

	// const theme = useTheme([
	// 	getTheme(),
	// 	{
	// 	  Table: `
	// 		--data-table-library_grid-template-columns: repeat(2, minmax(150px, 1fr)) ;
	// 	  `,
	// 	},
	//   ]);

	function loadScripts(urls) {
		return new Promise((resolve, reject) => {
			const promises = urls.map((url) => {
				return new Promise((resolve, reject) => {
					const script = document.createElement('script')
					script.src = url
					script.async = true
					script.onload = resolve
					script.onerror = reject
					document.head.appendChild(script)
				})
			})

			Promise.all(promises)
				.then(() => resolve())
				.catch((error) => reject(error))
		})
	}

	let htmlToCanvas = (element, fileName, options) => {
		var newContent = document.createElement('div')
		newContent.textContent = t('companyHospitality')
		newContent.className = 'detailPDFTitle'
		element.appendChild(newContent)
		loadScripts([
			'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js',
			'https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js',
		]).then(() => {
			const defaultOptions = {
				windowWidth: 1600,
			}
			let margin = options?.margin ?? 0
			html2canvas(element, { ...defaultOptions, ...options }).then((canvas) => {
				const imgData = canvas.toDataURL('image/png')
				const pdf = new jspdf.jsPDF('l', 'mm', [(canvas.height / canvas.width) * 297, 297], true)
				const imgProps = pdf.getImageProperties(imgData)
				const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin
				const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
				pdf.internal.pageSize.setHeight(pdfHeight + 2 * margin)
				pdf.addImage(imgData, 'PNG', margin, margin, pdfWidth, pdfHeight)
				pdf.save(`${fileName}.pdf`)

				element.removeChild(newContent)
			})
		})
	}

	const giftReject = () => {
		request(`/company-hospitality/approve`, {
			method: 'POST',
			data: {
				id: id,
				isApproved: false,
				customEventRejectReason: rejectReason,
			},
		}).then((res) => {
			if (res.data.ok) {
				request(`/company-hospitality/${id}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setDetails(res.data.result)
						dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
						toast.success('İşlem Başarılı')
					}
				})
			}
		})
	}

	const giftApprove = () => {
		request(`/company-hospitality/approve`, {
			method: 'POST',
			data: {
				id: id,
				isApproved: true,
			},
		}).then((res) => {
			if (res.data.ok) {
				request(`/company-hospitality/${id}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setDetails(res.data.result)
						dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
						toast.success('İşlem Başarılı')
					}
				})
			}
		})
	}

	return (
		<div className='detail'>
			<div className='addItemsPage-title detailPage'>
				<div onClick={() => navigate('/company-hospitality')} className='addItemsPage-title-icon'>
					<FaArrowLeft size={24} />
				</div>
				<div className='addItemsPage-title-text'>
					<Title name={t('details')} color='#1392e5' fontweight='bold' size='24px' />
				</div>
				<div
					onClick={() => htmlToCanvas(document.getElementById('detail-container-table-company'), 'agirlamadetay')}
					className='addItemsPage-title-pdf'
				>
					PDF
					<FiDownload />
				</div>
			</div>
			{loading ? (
				<div className='panel-table-loading'>
					<Loading width='45px' height='45px' borderWidth='5px' />
				</div>
			) : (
				<div className='detail-container'>
					<div style={{ display: 'flex', flexDirection: 'column-reverse' }} id='detail-container-table-company' className='detail-container-table'>
						<CompactTable
							columns={[
								{ key: 'name', header: 'Name', renderCell: (item) => <div className='cabin-bold'>{item.name}</div> },
								{ key: 'desc', header: 'Desc', renderCell: (item) => item.desc },
							]}
							data={data}
						/>
					</div>
					{details?.canApprove && (
						<div className='detail-container-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: true, type: { modalId: 1, modalStatus: 'reject' } }))}
								name={t('reject')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button
								onclick={() => dispatch(openModal({ open: true, type: { modalId: 1, modalStatus: 'approve' } }))}
								name={t('confirm')}
								color='#fff'
								backgroundColor='#08a52b'
								height='48px'
							/>
						</div>
					)}
				</div>
			)}

			{modalToggle && modalStatus == 'reject' && modalId == 1 && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('reasonforRefusal')} color='rgb(36, 36, 36)' fontweight='bold' size='24px' />
						{/* <textarea className='cabin-bold' name='' id='' cols='30' rows='10' placeholder={t('writebrieflyhere')} /> */}
						<div className='selectDr-modalReject'>
							<Input
								setValue={setRejectReason}
								value={rejectReason}
								labelColor='#292929'
								label={t('incomingLimitRemaining')}
								placeholder={t('write')}
								type='text'
							/>
						</div>
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('no')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftReject} name={t('yes')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}

			{modalToggle && modalStatus == 'approve' && modalId == 1 && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('areYouSureYouWantToConfirm')} color='rgb(36, 36, 36)' fontweight='' size='20px' />
						{/* <textarea className='cabin-bold' name='' id='' cols='30' rows='10' placeholder='Kısaca buraya yazın' /> */}
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('no')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftApprove} name={t('yes')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default CompanyHospitalityDetail
