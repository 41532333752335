/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePagination } from '@table-library/react-table-library/pagination'
import { useSort } from '@table-library/react-table-library/sort'
import * as XLSX from 'xlsx';
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useRequest } from 'hooks/useRequest'
import { getCompanyHospitality, getCompanyHospitalityFilterParametersFunc } from 'redux/features/dataManagement/companyHospitality/companyHospitalitySlice'
import { openModal } from 'redux/features/general/generalSlice'
import moment from 'moment';
import { downloadFileFromStream } from 'utils/ExcelOutput'
import { permissionTypes } from 'types/permissionTypes'
import { getDraft, getDraftFilterParametersFunc } from 'redux/features/dataManagement/draft/draftSlice'


const Draft = () => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { draftObj } = useSelector((state) => state.draft)

	const {draft, draftFilterParameters} = draftObj

	const {permissions} = useSelector(state => state.configuration)
	const {currentUser} = permissions.configurations

    //filter object 
	const [filters, setFilters] = useState({
		sortingOrderByVal: "desc",
		sortingColumnName: "",
		page: 1,
	})

	const handleRemove = (item) => {
		dispatch(openModal({ open: true, type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: {...item, deleteItem: "draft"} } }))
	}

	const draftRes = {
		1: t('incomingGifts'),
		2: t('outgoingGifts'),
		3: t('companyHospitality'),
	}
	// 1 = IncomingGift (Gelen Hediye)
	// 2 = OutgoingGift (Giden Hediye)
	// 3 = CompanyHospitality (Şirket Ağırlama)

	const COLUMNS = [
		{
			label: t('creationDate'),
			renderCell: (item) => new Date(item.createdDate).toLocaleDateString(i18n.language),
			name: "createdDate"
		},
		{
			label: t('draftTitle'),
			renderCell: (item) => draftRes[item.type],
			name: "type"
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
						<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
						<div onClick={() => handleRemove(item)} className=''>
						{t('delete')}
						</div>
					</div>
				</div>
			),
			name: '',
		},
	]
	// table =>  2 + 1 (300px)


	useEffect(() => {
		request(`/draft-form?Page=${filters?.page}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getDraftFilterParametersFunc(filterPrm))
				dispatch(getDraft(res.data.result.items))
			}
		})
	}, [filters])

	let nodes = draft;

	let data = { nodes }


	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	const goToPrevPage = () => {
		if (draftFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: draftFilterParameters?.pageIndex - 1}))
		
	}

	const goToNextPage = () => {
		if (draftFilterParameters?.pageIndex + 1 >= draftFilterParameters?.totalPages) return	
		setFilters(prev => ({...prev, page: draftFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: draftFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}


	return (
		<div style={{height: "100%"}} className='incoming-gift company-hospitality'>
			<div className='tableCard'>
			     <Table 
					filterType={"none"} 
					gridCSS = "draft" 
					paginationAllFunctionsList={paginationAllFunctionsList} 
					sortTable={sortTable} 
					data={data} 
					columns={COLUMNS}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					filters = {filters}
					isLoading={loading}
				/>
			</div>
		</div>
	)
}

export default Draft
