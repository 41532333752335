import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const giftLimitSlice = createSlice({
    name: 'giftLimit',
    initialState,
    reducers: {
      getGiftLimit: (state, action) => {      
          state.adminPanel.giftLimit = action.payload    
      },
      getGiftLimitFilterParametersFunc: (state, action) => {
          state.adminPanel.giftLimitFilterParameters = action.payload   
      }
    },
  })
  
  export const { getGiftLimit, getGiftLimitFilterParametersFunc } = giftLimitSlice.actions
  
  export default giftLimitSlice.reducer