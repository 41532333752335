import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  export const incomingGiftSlice = createSlice({
    name: 'incomingGift',
    initialState,
    reducers: {
      getIncomingGift: (state, action) => {      
          state.incomingGiftObj.incomingGift = action.payload    
      },
      getIncomingGiftFilterParametersFunc: (state, action) => {
          state.incomingGiftObj.incomingGiftFilterParameters = action.payload   
      }
    },
  })
  
  export const { getIncomingGift, getIncomingGiftFilterParametersFunc } = incomingGiftSlice.actions
  
  export default incomingGiftSlice.reducer