import Title from 'components/common/Title'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <div className='not-found'>
        <Title name={t('noSuchContentFound')} color="rgb(19, 146, 229)" fontweight="700" size="24px"/>
    </div>
  )
}

export default NotFound