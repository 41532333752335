/* eslint-disable */
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Loading from 'components/common/Loading'
import SelectDr from 'components/common/SelectDr'
import Title from 'components/common/Title'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const Profile = () => {
	const { t } = useTranslation()
	const [request, loading] = useRequest()
	const [accounts, setAccounts] = useState()

    console.log(accounts);


    const languageSelectList = [
        { value: 'TR', name: 'TR' },
		{ value: 'EN', name: 'EN' },
    ]

	useEffect(() => {
		request('/identity/profile', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setAccounts(res.data.result)
			}
		})
	}, [])

	const updateAccountsFunc = () => {
		request('/identity/profile', {
			method: 'POST',
			data: {
                name: accounts?.name,
                surname: accounts?.surname,
                language: accounts?.language?.value		
			},
		}).then((res) => {
			if (res.data.ok) {
				request('/identity/profile', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setAccounts(res.data.result)
						toast.success('Güncelleme İşlemi Başarılı')
					}
				})
			}
		})
	}

	let languageList = {
		value: accounts?.language,
		label: languageSelectList?.find((language) => language.value.toLowerCase() == accounts?.language)?.name,
	}

	return loading ? (
		<div className='settingPage-loading'>
			<Loading width='45px' height='45px' borderWidth='5px' />
		</div>
	) : (
		<div className='settings'>
			<div className='settings-content'>
                <div className='settings-content-title' style={{marginBottom: '24px'}}>
                <Title onclick={() => {}} size="24px" name={t('myAccount')} color="#1392e5" fontweight="bold"/>
                </div>
                <div className='settings-content-tab1'>
                    <Input
                        name='name'
                        value={accounts?.name}
                        multipleState={true}
                        setValue={setAccounts}
                        labelColor='#292929'
                        label={t('name')}
                        placeholder=''
                        type='text'
                    />
                      <Input
                        name='surname'
                        value={accounts?.surname}
                        multipleState={true}
                        setValue={setAccounts}
                        labelColor='#292929'
                        label={t('surname')}
                        placeholder=''
                        type='text'
                    />
                   <SelectDr
                        onChange={(value) => setAccounts((prev) => ({ ...prev, language: value ? value : '' }))}
                        type={0}
                        //isLoading={loading}
                        defaultValue={languageList}
                        options={languageSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
                        placeholder={t('select')}
                        label={t('language')}
						/>
                      <Input
                        value={accounts?.email}
                        readOnly={true}
                        labelColor='#292929'
                        label={'Email'}
                        placeholder=''
                        type='text'
                    />   
                     <Input
                        value={accounts?.companyName}
                        readOnly={true}
                        labelColor='#292929'
                        label={t('companyName')}
                        placeholder=''
                        type='text'
                    /> 
                    
                </div>
				<div className='buttonContainer'>
					<Button height={'48px'} backgroundColor='#1392E5' color='white' name={t('update')} onclick={updateAccountsFunc} />
				</div>
			</div>
		</div>
	)
}

export default Profile
