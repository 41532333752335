/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { FaRegEdit } from 'react-icons/fa'
import { getNotification, getNotificationFilterParametersFunc } from 'redux/features/dataManagement/notifications/notificationSlice'
import { IoCheckmarkDoneSharp, IoCheckmarkSharp } from 'react-icons/io5'
import { getMailTemplate, getMailTemplateFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailTemplateSlice'
import { permissionTypes } from 'types/permissionTypes'
import { getMailLog, getMailLogFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailLogSlice'
import moment from 'moment'
import { getAuditLog, getAuditLogFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/auditLogSlice'
import { downloadFileFromStream } from 'utils/ExcelOutput'

const AuditLog = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.auditLog)

	const { modalId, modalStatus, modalToggle } = modals
	const { auditLog, auditLogFilterParameters } = adminPanel

	//filter object
	const [filters, setFilters] = useState({
		search: '',
		startDate: null,
		endDate: null,
		sortingOrderByVal: 'desc',
		sortingColumnName: '',
		page: 1,
	})

	const navigate = useNavigate()

	const COLUMNS = [
		{
			label: t('eventType'), //işlem
			renderCell: (item) => item.eventType,
			name: 'eventType',
		},
		{
			label: t('emailUser'),
			renderCell: (item) => item.userEmail,
			name: 'userEmail',
		},
		{
			label: t('username'),
			renderCell: (item) => item.userFullName,
			name: 'userFullName',
		},
		{
			label: t('auditDate'),
			renderCell: (item) => new Date(item.createdDate).toLocaleDateString(i18n.language),
			name: 'createdDate',
		},
	]

	useEffect(() => {
		setFilters((prev) => ({ ...prev, page: 1 }))
	}, [filters.startDate, filters.endDate, filters.search])

	useEffect(() => {
		request(
			`/log/audit-log?Page=${filters?.page}&Search=${filters?.search}&StartDate=${
				filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
			}&EndDate=${filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''}&OrderBy=${
				filters?.sortingColumnName ? filters?.sortingColumnName + ' ' + filters?.sortingOrderByVal : ''
			}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getAuditLogFilterParametersFunc(filterPrm))
				dispatch(getAuditLog(res.data.result.items))
			}
		})
	}, [filters])

	let nodes = auditLog

	let data = { nodes }

	const goToPrevPage = () => {
		if (auditLogFilterParameters?.pageIndex == 0) return
		setFilters((prev) => ({ ...prev, page: auditLogFilterParameters?.pageIndex - 1 }))
	}

	const goToNextPage = () => {
		if (auditLogFilterParameters?.pageIndex + 1 >= auditLogFilterParameters?.totalPages) return
		setFilters((prev) => ({ ...prev, page: auditLogFilterParameters?.pageIndex + 2 }))
	}

	const goToPage = (index) => {
		setFilters((prev) => ({ ...prev, page: index + 1 }))
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters((prev) => ({ ...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == 'asc' ? 'desc' : 'asc' }))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: auditLogFilterParameters,
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	const handleDownloadXlsx = () => {
		request(`/log/export-audit-log-excel?Page=${filters?.page}&Search=${filters?.search}&StartDate=${
			filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
		}&EndDate=${filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''}&OrderBy=${
			filters?.sortingColumnName ? filters?.sortingColumnName + ' ' + filters?.sortingOrderByVal : ''
		}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('audit-log.xlsx', res.data.result)
			}
		})
	}

	return (
		<div className='panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
				</div>
			</div>
			<div className='tableCard'>
				<Table
					gridCSS='auditLog'
					deleteBtn={true}
					paginationAllFunctionsList={paginationAllFunctionsList}
					data={data}
					columns={COLUMNS}
					filterType={9}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					filters={filters}
					sortTable={sortTable}
					isLoading={loading}
				/>
			</div>
		</div>
	)
}

export default AuditLog
