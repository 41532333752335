/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePagination } from '@table-library/react-table-library/pagination'
import { useSort } from '@table-library/react-table-library/sort'
import * as XLSX from 'xlsx';
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useRequest } from 'hooks/useRequest'
import { getCompanyHospitality, getCompanyHospitalityFilterParametersFunc } from 'redux/features/dataManagement/companyHospitality/companyHospitalitySlice'
import { openModal } from 'redux/features/general/generalSlice'
import moment from 'moment';
import { downloadFileFromStream } from 'utils/ExcelOutput'
import { permissionTypes } from 'types/permissionTypes'
import { AiOutlineCheckSquare, AiOutlineCloseSquare  } from 'react-icons/ai'

const CustomSortIcon = () => {
	return <img src={images.filterico} alt='' style={{ margin: '0px' }} />
}

const CompanyHospitality = () => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { companyHospitalityObj } = useSelector((state) => state.companyHospitality)

	const {companyHospitality, companyHospitalityFilterParameters} = companyHospitalityObj

	const {permissions} = useSelector(state => state.configuration)
	const {currentUser} = permissions.configurations

	let substitutePage = window.location.pathname.split('/')[1] == "substitute-panel"

	const location = useLocation();
	const { filterParams } = location.state || {};


    //filter object 
	const [filters, setFilters] = useState({
		search: '',
		eventPurposeId: '',
	    approvalStatus: filterParams == 0 ? 1 : filterParams == 1 ? 2 : filterParams == 2 ? 0 : "",
	    hasPublicOfficial: '',
		startDate: null,	
		endDate: null,	
		sortingOrderByVal: "desc",
		sortingColumnName: "eventDate",
		page: 1,
		waitingForMyApproval: false,
		department: "",
		location: ""
	})

	const handleRemove = (item) => {
		dispatch(openModal({ open: true, type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: {...item, deleteItem: "company-hospitality"} } }))
	}

	const approveStatus = (status) => {
		switch (status) {
			case 0:
				return (
					<div style={{ color: '#DEC600', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.warningRounded} alt='' />
						<div>{t('AwaitingApproval')}</div>
					</div>
				)
			case 1:
				return (
					<div style={{ color: '#08A52B', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.successRounded} alt='' />
						<div>{t('approved')}</div>
					</div>
				)
			case 2:
				return (
					<div style={{ color: '#c1312f', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.errorRounded} alt='' />
						<div>{t('rejected')}</div>
					</div>
				)
			default:
				return (
					<div style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '5px' }}>
						<img src={images.successRounded} alt='' />
						<div>{t('approved')}</div>
					</div>
				)
		}
	}

	const COLUMNS = [
		{
			label: t('companyName'),
			renderCell: (item) => item.companyName,
			name: "companyName"
		},
		{
			label: t('Amount'),
			renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
			name: "amount"
		},
		// {
		// 	label: "Para Birimi",
		// 	renderCell: (item) => item.currencyCode,
		// 	name: "currencyCode"
		// },
		{
			label: t('eventPurpose'),
			renderCell: (item) => item.eventPurposeName,
			name: ""
		},
		
		{
			label: t('areThereAnyPublicOfficials'),
			renderCell: (item) => item.hasPublicOfficial ?  <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
			name: "hasPublicOfficial"
		},
		{
			label: t('activityRelatedParty'),
			renderCell: (item) => item.eventRelatedPartyTypeName,
			name: ""
		},
		{
			label: t('ApprovalStatus'),
			renderCell: (item) => approveStatus(item.approvalStatus),
			name: 'approvalStatus',
		},
		{
			label: t('eventDate'),
			renderCell: (item) => new Date(item.eventDate).toLocaleDateString(i18n.language),
			name: "eventDate"
		},
		{
			label: '',
			renderCell: (item) => (
				currentUser?.permissions[permissionTypes.CompanyHospitalitiesDelete] &&
				<div className='lastCellFlex'>
					<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
						<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
						<div onClick={() => handleRemove(item)} className=''>
						{t('delete')}
						</div>
					</div>
				</div>
			),
			name: '',
		},
	]
	// table =>  7 + 1 (300px)

	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.approvalStatus, filters.search, filters.eventPurposeId, filters.hasPublicOfficial, filters.startDate, filters.endDate, filters.sortingColumnName, filters.sortingOrderByVal, filters.waitingForMyApproval, filters.department, filters.location])


	useEffect(() => {
		request(`/company-hospitality?Page=${filters?.page}&OnlySubstitutedUsers=${substitutePage}&Location=${filters?.location}&Department=${filters?.department}&WaitingForMyApproval=${filters?.waitingForMyApproval == ""  ? false : filters?.waitingForMyApproval}&Search=${filters?.search}&ApprovalStatus=${filters?.approvalStatus}&StartDate=${filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ""}&EndDate=${filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ""}&HasPublicOfficial=${filters?.hasPublicOfficial}&EventPurposeId=${filters?.eventPurposeId}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					//buraya yeni parametreler eklenecek ...
					// search: res.data.result.search,
					// eventDate: res.data.result.eventDate,
					// hasPublicOfficial: res.data.result.hasPublicOfficial,
					// eventPurposeId: res.data.result.eventPurposeId,
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getCompanyHospitalityFilterParametersFunc(filterPrm))
				dispatch(getCompanyHospitality(res.data.result.items))
			}
		})
	}, [filters])

	let nodes = companyHospitality;

	let data = { nodes }


	const handleDownloadXlsx = () => {
		request(`/company-hospitality/export-excel?Page=${filters?.page}&OnlySubstitutedUsers=${substitutePage}&Location=${filters?.location}&Department=${filters?.department}&WaitingForMyApproval=${filters?.waitingForMyApproval == ""  ? false : filters?.waitingForMyApproval}&Search=${filters?.search}&ApprovalStatus=${filters?.approvalStatus}&StartDate=${filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ""}&EndDate=${filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ""}&HasPublicOfficial=${filters?.hasPublicOfficial}&EventPurposeId=${filters?.eventPurposeId}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if(res.data.ok){
				downloadFileFromStream('company-hospitality.xlsx', res.data.result)
			}
		})
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	const goToPrevPage = () => {
		if (companyHospitalityFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: companyHospitalityFilterParameters?.pageIndex - 1}))
		
	}

	const goToNextPage = () => {
		if (companyHospitalityFilterParameters?.pageIndex + 1 >= companyHospitalityFilterParameters?.totalPages) return	
		setFilters(prev => ({...prev, page: companyHospitalityFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: companyHospitalityFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}


	return (
		<div style={{height: "100%"}} className='incoming-gift company-hospitality'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.CompanyHospitalitiesCreate] && <Button
						onclick={() => substitutePage ? navigate('/substitute-panel/company-hospitality/add') : navigate('/company-hospitality/add')}
						name={t('hospitalityEntrance')}
						img={images.plus}
						color='#fff'
						backgroundColor='#1e74ad'
						height='38px'
					/>}
				</div>
			</div>
			<div className='tableCard'>
			     <Table 
					filterType={2} 
					gridCSS = "company-hospitality" 
					paginationAllFunctionsList={paginationAllFunctionsList} 
					sortTable={sortTable} 
					data={data} 
					columns={COLUMNS}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
					filters = {filters}
					isLoading={loading}
					approvedFilterParams = {filterParams}
				/>
			</div>
		</div>
	)
}

export default CompanyHospitality
