import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const giftPurposeSlice = createSlice({
    name: 'giftPurpose',
    initialState,
    reducers: {
      getGiftPurpose: (state, action) => {      
          state.adminPanel.giftPurpose = action.payload    
      },
      getGiftPurposeFilterParametersFunc: (state, action) => {
        state.adminPanel.giftPurposeFilterParameters = action.payload   
    }
      // addGiftPurpose : (state, action) => {      
      //   state.adminPanel.giftPurpose = [...state.adminPanel.giftPurpose, action.payload ]   
      // }
    },
  })
  
  export const { getGiftPurpose, getGiftPurposeFilterParametersFunc } = giftPurposeSlice.actions
  
  export default giftPurposeSlice.reducer