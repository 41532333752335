import Button from 'components/common/Button'
import Loading from 'components/common/Loading'
import Title from 'components/common/Title'
import { useRequest } from 'hooks/useRequest'
import React, { useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const SignInSso = () => {
    const [searchParams] = useSearchParams()
    const [request, loading] = useRequest()
    
    const error = searchParams.get('error')
    const key = searchParams.get('key');

    useEffect(() => {
     if(!key) return;
     request(`/auth/token`, {
        method: 'POST',
        data: {
            key: key
        }
    }).then((res) => {
        if (res.data.ok) {
            localStorage.setItem('auth', JSON.stringify(res.data.result.token)) 
            
            toast.success('Giriş İşlemi Başarılı')
            setTimeout(() => {
              window.location.href = "/" 
            }, 0);
        }
    })
    },[key])
  

  if(!error && !key) return <Navigate to={'/error/404'}/>  

  return (
    loading ? (
        <div>
            <Loading width='45px' height='45px' borderWidth='5px' wholePage={true}/>
        </div>
    ): (
        <div className='errorDesign'>
            {
                error &&  
                <div className='errorDesign-container'>
                 <Title name={error} color="rgb(19, 146, 229)" fontweight="700" size="24px"/>
                 <Button height={'48px'} backgroundColor="#1392E5" color="white" name="Ana Sayfa" onclick={() => window.location.href = "/"}/>                
                </div>
            }
        </div>
    )
  )
}

export default SignInSso