import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const reasonsForRefusalSlice = createSlice({
    name: 'reasonsForRefusal',
    initialState,
    reducers: {
      getReasonsForRefusal: (state, action) => {      
          state.adminPanel.reasonsForRefusal = action.payload    
      },
      getReasonsForRefusalFilterParametersFunc: (state, action) => {      
        state.adminPanel.reasonsForRefusalFilterParameters = action.payload    
    },
      // addReasonsForRefusal : (state, action) => {      
      //   state.adminPanel.reasonsForRefusal = [...state.adminPanel.reasonsForRefusal, action.payload ]   
      // }
    },
  })
  
  export const { getReasonsForRefusal, getReasonsForRefusalFilterParametersFunc } = reasonsForRefusalSlice.actions
  
  export default reasonsForRefusalSlice.reducer