import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const mailLogSlice = createSlice({
    name: 'mailLog',
    initialState,
    reducers: {
      getMailLog: (state, action) => {      
          state.adminPanel.mailLog = action.payload    
      },
      getMailLogFilterParametersFunc: (state, action) => {
          state.adminPanel.mailLogFilterParameters = action.payload   
      }
    },
  })
  
  export const { getMailLog, getMailLogFilterParametersFunc } = mailLogSlice.actions
  
  export default mailLogSlice.reducer