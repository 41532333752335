/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { FaRegEdit } from "react-icons/fa";
import { getNotification, getNotificationFilterParametersFunc } from 'redux/features/dataManagement/notifications/notificationSlice'
import { IoCheckmarkDoneSharp,IoCheckmarkSharp} from "react-icons/io5";



const Notifications = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
    const { notifications } = useSelector((state) => state.notification)


	const { modalId, modalStatus, modalToggle } = modals
    const { notificationList, notificationListFilterParameters } = notifications

	const navigate = useNavigate()

	const handleRemove = (item) => {
		dispatch(openModal({ open: true, type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: {...item, deleteItem: "notification"} } }))
	}
	
	const COLUMNS = [
		{
			label: t('notificationName'),
			renderCell: (item) => item.title,
			name: 'title',
		},
		{
			label: t('notificationContent'),
			renderCell: (item) => item.body,
			name: 'body',
		},
        {
			label: t('notificationDate'),
			renderCell: (item) => new Date(item.createdDate).toLocaleString(i18n.language),
			name: 'createdDate',
		},
        {
			label: t('hasitbeenread'),
			renderCell: (item) => item.isRead ? <div className='isRead' style={{color: "rgb(8, 165, 43)", display: "flex", alignItems: "center", gap: "3px", fontWeight: "bold"}}><IoCheckmarkDoneSharp size={25}/> {t('read')}</div> : <div className='isNotRead' style={{color: "rgb(193, 49, 47)", display: "flex", alignItems: "center", gap: "3px", fontWeight: "bold"}}><IoCheckmarkSharp size={25}/> {t('notread')}</div>,
			name: 'isRead',
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					{/* <div 
					className={`cabin-semibold ${
						item.status == 1
							? 'tableActiveStatus'
							: 'tablePassiveStatus'
					}`}
					>{item.status == 1 ? "Aktif" : "Pasif"}</div> */}
					<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
						<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
						<div onClick={() => handleRemove(item)} className=''>
						{t('delete')}
						</div>
					</div>
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		request('/notification', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getNotificationFilterParametersFunc(filterPrm))
				dispatch(getNotification(res.data.result.items))
			}
		})
	}, [])


	let nodes = notificationList

	let data = { nodes }


	const goToPrevPage = () => {
		if (notificationListFilterParameters?.pageIndex == 0) return
		request(`/notification?Page=${notificationListFilterParameters?.pageIndex - 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getNotificationFilterParametersFunc(filterPrm))
				dispatch(getNotification(res.data.result.items))
			}
		})
	}
	const goToNextPage = () => {
		if (notificationListFilterParameters?.pageIndex + 1 >= notificationListFilterParameters?.totalPages) return
		request(`/notification?Page=${notificationListFilterParameters?.pageIndex + 2}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getNotificationFilterParametersFunc(filterPrm))
				dispatch(getNotification(res.data.result.items))
			}
		})
	}

	const goToPage = (index) => {
		request(`/notification?Page=${index + 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getNotificationFilterParametersFunc(filterPrm))
				dispatch(getNotification(res.data.result.items))
			}
		})
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: notificationListFilterParameters,
	}

	return (
		<div className='panel-table location-group incoming-gift'>
		     	{/* <Title name={"Bildirimler"} color='#242424' fontweight='bold' size='24px' /> */}
				<div className='tableCard'>
					<Table
					    gridCSS="notifications"
						deleteBtn={true}
						paginationAllFunctionsList={paginationAllFunctionsList}
						data={data}
						columns={COLUMNS}
						filterType={'none'}
						isLoading = {loading}
					/>
				</div>

		</div>
	)
}

export default Notifications
