import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const mailTemplateSlice = createSlice({
    name: 'mailTemplate',
    initialState,
    reducers: {
      getMailTemplate: (state, action) => {      
          state.adminPanel.mailTemplate = action.payload    
      },
      getMailTemplateFilterParametersFunc: (state, action) => {
          state.adminPanel.mailTemplateFilterParameters = action.payload   
      }
    },
  })
  
  export const { getMailTemplate, getMailTemplateFilterParametersFunc } = mailTemplateSlice.actions
  
  export default mailTemplateSlice.reducer