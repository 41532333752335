import React from 'react'
import { Outlet } from 'react-router-dom';

const AdminPanel = () => {

    // bu function tam istedigim gibi degil.Güncelleme atacagım ...
    // const handleInputChange = (inputValue, { action }) => {
    //     console.log(inputValue, action, "DEEEE");
    //     if (action === 'input-change') {
    //       const newOption = { value: inputValue, label: inputValue };
    //       setOptionTest(prevOptions => prevOptions.includes(newOption) ? prevOptions : [newOption, ...prevOptions]);
    //     }
    //   };
	// const createOption = (inputValue) => ({
	// 	label: inputValue,
	// 	value: inputValue,
	// })

	return (
		<div className='adminPanel'>
			<Outlet />
		</div>
	)
}

export default AdminPanel
