import { createSlice } from "@reduxjs/toolkit"
import { initialState } from '../../../initialState';


  
  export const roleManagementSlice = createSlice({
    name: 'roleManagement',
    initialState,
    reducers: {
      getRoleManagement: (state, action) => {      
          state.adminPanel.roleManagement = action.payload    
      },
      getRoleManagementFilterParametersFunc: (state, action) => {
          state.adminPanel.roleManagementFilterParameters = action.payload   
      }
    },
  })
  
  export const { getRoleManagement, getRoleManagementFilterParametersFunc } = roleManagementSlice.actions
  
  export default roleManagementSlice.reducer