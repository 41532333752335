import logo from './logo.svg'
import comment from './comment.svg'
import downArrow from './downArrow.svg'
import errorRounded from './errorRounded.svg'
import exit from './exit.svg'
import mail from './mail.svg'
import phone from './phone.svg'
import plus from './plus.svg'
import rightArrow from './rightArrow.svg'
import sidebarLogo from './sidebarLogo.svg'
import successRounded from './successRounded.svg'
import textSearch from './textSearch.svg'
import upload from './upload.svg'
import warningRounded from './warningRounded.svg'
import wp from './wp.svg'
import notification from './notification.svg'
import user from './user.svg'
import filterico from './filterico.svg'
import paginationRight from './pagination-right.svg'
import paginationLeft from './pagination-left.svg'
import turkey from './turkey.png'
import england from './england.png'

export default {
    logo,
    comment,
    downArrow,
    errorRounded,
    exit,
    mail,
    phone,
    plus,
    rightArrow,
    sidebarLogo,
    successRounded,
    textSearch,
    upload,
    warningRounded,
    wp,
    notification,
    user,
    filterico,
    paginationRight,
    paginationLeft,
    turkey,
    england
} 
