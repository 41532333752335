import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';

  export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
      getConfigurations: (state, action) => {      
          state.permissions.configurations = action.payload    
      },
    }
  })
  
  export const { getConfigurations} = configurationSlice.actions
  
  export default configurationSlice.reducer