/*eslint-disable*/
import { useEffect, useState } from 'react'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import SelectDr from 'components/common/SelectDr'
import Title from 'components/common/Title'
import Modal from 'containers/modal/Modal'
import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { openModal } from 'redux/features/general/generalSlice'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Locale from 'utils/Locale'
import { useRequest } from 'hooks/useRequest'
import { getIncomingGift, getIncomingGiftFilterParametersFunc } from 'redux/features/dataManagement/incomingGift/incomingGiftSlice'
import { MdOutlineCloudUpload, MdOutlineDeleteSweep } from 'react-icons/md'
import Loading from 'components/common/Loading'

const AddIncomingGift = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation();
	const dispatch = useDispatch()
	const [request] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const [startDate, setStartDate] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	let substitutePage = window.location.pathname.split('/')[1] == "substitute-panel"


	const { modalToggle, modalStatus, modalId } = modals
	const { state } = location;
	const searchParams = new URLSearchParams(location.search);
	const draftParam = searchParams.get('draft');

	const [targetCompanyId, setTargetCompanyId] = useState() //Gönderen Kurum Adı
	const [giftTypeId, setGiftTypeId] = useState() // Hediye Tipi
	const [giftRelatedPartyTypeId, setGiftRelatedPartyTypeId] = useState() //İlişkili Taraf Türü
	const [giftPurposeId, setGiftPurposeId] = useState() //Hediyenin Amacı ++
	//const [senderName, setSenderName] = useState() //Hediyeyi Gönderen Kişi ++
	const [receiverName, setReceiverName] = useState() //Hediyeyi Alan Kişi ++
	const [description, setDescription] = useState() //Hediye Açıklaması ++
	const [giftPurposeDescription, setGiftPurposeDescription] = useState() //Hediye Gönderilme Nedenini Açıklayınız
	const [toPublicOfficial, setToPublicOfficial] = useState()
	const [currencyId, setCurrencyId] = useState() //Para Birimi ++
	const [amount, setAmount] = useState() //amount ++
	const [giftDate, setGiftDate] = useState() //Hediye Tarihi ++
	const [files, setFiles] = useState([]) //Hediye Dosyaları ++
	const [substitute, setSubstitute] = useState(null)

	// controller
	const [isWarningApproved, setIsWarningApproved] = useState(false)

	//adding options
	const [giftPurposeSelectList, setGiftPurposeSelectList] = useState()
	const [giftTypeSelectList, setGiftTypeSelectList] = useState()
	const [targetCompanySelectList, setTargetCompanySelectList] = useState()
	const [relatedPartyTypeSelectList, setRelatedPartyTypeSelectList] = useState()
	const [currencyIdSelectList, setCurrencyIdSelectList] = useState()
	const [substituteSelectList, setSubstituteSelectList] = useState()
	const [draftInfo, setDraftInfo] = useState(state);

	useEffect(() => {
         if(state && draftParam ){
			setDraftInfo(draftInfo) 
			setTargetCompanyId(targetCompanySelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.targetCompanyId))
			setGiftPurposeId(giftPurposeSelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.giftPurposeId))
			setGiftRelatedPartyTypeId(relatedPartyTypeSelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.giftRelatedPartyTypeId))
			setGiftTypeId(giftTypeSelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.giftTypeId))
			setCurrencyId(currencyIdSelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.currencyId))
			setToPublicOfficial(setToPublicOfficialOptions?.find((tc) => tc.value == state.toPublicOfficial))
			//setSenderName(state.senderName)
			setReceiverName(state.receiverName)
			setDescription(state.description)
			setGiftPurposeDescription(state.giftPurposeDescription)
			setFiles(state.files)
			setGiftDate(state.giftDate)
			setAmount(state.amount)
		}
	}, [state, draftParam, targetCompanySelectList, giftPurposeSelectList])


	useEffect(() => {
		request('/gift/purpose/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setGiftPurposeSelectList(res.data.result.items)
			}
		})

		request('/gift/related-party-type/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setRelatedPartyTypeSelectList(res.data.result.items)
			}
		})

		request('/currency/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setCurrencyIdSelectList(res.data.result.items)
			}
		})

		request('/gift/type/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setGiftTypeSelectList(res.data.result.items)
			}
		})

		request('/company/select-list?Export=true&IncludeCompanySpecific=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setTargetCompanySelectList(res.data.result.items)
			}
		})


		request('/identity/user/substitute-select-list', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setSubstituteSelectList(res.data.result.items)
				if(res.data.result.items.length == 1){
					setSubstitute(res?.data?.result?.items?.map((prev) => ({ value: prev.name, label: prev.value }))[0])
				}
			}
		})
	}, [])

	const setToPublicOfficialOptions = [
		{ value: true, label: t('yes') },
		{ value: false, label: t('no') },
	]

	const outgoingGiftAddBtn = () => {
		setIsLoading(true)
		if(substitutePage && !substitute?.value){
			setIsLoading(false)
			toast.error(t('proxyUserMessage'))
			return;
		}
	    if(substitutePage){
			request(`/gift-transaction/outgoing`, {
				method: 'POST',
				data: {
					targetCompanyId: targetCompanyId?.__isNew__ ? null : targetCompanyId?.value,
					customTargetCompanyName: targetCompanyId?.__isNew__ ? targetCompanyId?.value : null,
	
					//giftTypeId: giftTypeId?.value,
					giftTypeId: giftTypeId?.__isNew__ ? null : giftTypeId?.value,
					customGiftType: giftTypeId?.__isNew__ ? giftTypeId?.value : null,
	
					//giftPurposeId: giftPurposeId?.value,
					giftPurposeId: giftPurposeId?.__isNew__ ? null : giftPurposeId?.value,
					customGiftPurpose: giftPurposeId?.__isNew__ ? giftPurposeId?.value : null,
	
					//giftRelatedPartyTypeId: giftRelatedPartyTypeId?.value,
					giftRelatedPartyTypeId: giftRelatedPartyTypeId?.__isNew__ ? null : giftRelatedPartyTypeId?.value,
					customGiftRelatedPartyType: giftRelatedPartyTypeId?.__isNew__ ? giftRelatedPartyTypeId?.value : null,
					
					//senderName: senderName,
					receiverName: receiverName,
					description: description,
					giftPurposeDescription: giftPurposeDescription,
					currencyId: currencyId?.value,
					amount: amount,
					giftDate: giftDate,
					toPublicOfficial: toPublicOfficial?.value,
					isWarningApproved: isWarningApproved,
					files: files,
					substitutedUserId: substitute?.value
				},
			}).then((res) => {
				setIsLoading(false)
				if (res.data.result === false) {	
					// toast.error(res.data.metadata.message, {autoClose: 10000})
					dispatch(openModal({ open: true, type: { modalId: res.data.metadata.message, modalStatus: 'warning' } }))
					setIsWarningApproved(true)
				} else if (res.data.ok) {			
					toast.success('Ekleme işlemi başarılı')
					navigate('/outgoing-gift')
				}
			})
		}else{
			request(`/gift-transaction/outgoing`, {
				method: 'POST',
				data: {
					targetCompanyId: targetCompanyId?.__isNew__ ? null : targetCompanyId?.value,
					customTargetCompanyName: targetCompanyId?.__isNew__ ? targetCompanyId?.value : null,
	
					//giftTypeId: giftTypeId?.value,
					giftTypeId: giftTypeId?.__isNew__ ? null : giftTypeId?.value,
					customGiftType: giftTypeId?.__isNew__ ? giftTypeId?.value : null,
	
					//giftPurposeId: giftPurposeId?.value,
					giftPurposeId: giftPurposeId?.__isNew__ ? null : giftPurposeId?.value,
					customGiftPurpose: giftPurposeId?.__isNew__ ? giftPurposeId?.value : null,
	
					//giftRelatedPartyTypeId: giftRelatedPartyTypeId?.value,
					giftRelatedPartyTypeId: giftRelatedPartyTypeId?.__isNew__ ? null : giftRelatedPartyTypeId?.value,
					customGiftRelatedPartyType: giftRelatedPartyTypeId?.__isNew__ ? giftRelatedPartyTypeId?.value : null,
					
					//senderName: senderName,
					receiverName: receiverName,
					description: description,
					giftPurposeDescription: giftPurposeDescription,
					currencyId: currencyId?.value,
					amount: amount,
					giftDate: giftDate,
					toPublicOfficial: toPublicOfficial?.value,
					isWarningApproved: isWarningApproved,
					files: files,
					//substitutedUserId: substitute?.value
				},
			}).then((res) => {
				setIsLoading(false)
				if (res.data.result === false) {	
					// toast.error(res.data.metadata.message, {autoClose: 10000})
					dispatch(openModal({ open: true, type: { modalId: res.data.metadata.message, modalStatus: 'warning' } }))
					setIsWarningApproved(true)
				} else if (res.data.ok) {			
					toast.success('Ekleme işlemi başarılı')
					navigate('/outgoing-gift')
				}
			})
		}
	}

	const handleFileChange = (e) => {
		setIsLoading(true)
		const fileList = e.target.files
		const promises = []

		for (let i = 0; i < fileList.length; i++) {
			const file = fileList[i]
			const promise = new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onload = () => {
					var base64 = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''))
					const newFile = {
						content: base64,
						name: file.name,
					}
					resolve(newFile)
				}
				reader.onerror = (error) => {
					reject(error)
				}
				reader.readAsArrayBuffer(file)
			})
			promises.push(promise)
		}
		Promise.all(promises)
			.then((newFiles) => {
				setIsLoading(false)
				setFiles((prevFiles) => {
					const existingNames = prevFiles.map((file) => file.name)
					const uniqueNewFiles = newFiles.filter((file) => !existingNames.includes(file.name))
					return [...prevFiles, ...uniqueNewFiles]
				})
			})
			.catch((error) => {
				toast.error(`Dosya okunurken bir hata oluştu:`)
			})
	}

	const handleDeleteFile = (index) => {
		const updatedFiles = [...files]
		updatedFiles.splice(index, 1)
		setFiles(updatedFiles)
	}

	const createDraft = () => {
		setIsLoading(true)
		if(substitutePage && !substitute?.value){
			setIsLoading(false)
			toast.error(t('proxyUserMessage'))
			return;
		}
		if(substitutePage){
			request(`/draft-form`, {
				method: 'POST',
				data: {
					type: 2,
					formData : {
						targetCompanyId: targetCompanyId?.__isNew__ ? null : targetCompanyId?.value,
						customTargetCompanyName: targetCompanyId?.__isNew__ ? targetCompanyId?.value : null,
	
						//giftTypeId: giftTypeId?.value,
						giftTypeId: giftTypeId?.__isNew__ ? null : giftTypeId?.value,
						customGiftType: giftTypeId?.__isNew__ ? giftTypeId?.value : null,
	
						//giftPurposeId: giftPurposeId?.value,
						giftPurposeId: giftPurposeId?.__isNew__ ? null : giftPurposeId?.value,
						customGiftPurpose: giftPurposeId?.__isNew__ ? giftPurposeId?.value : null,
	
						//giftRelatedPartyTypeId: giftRelatedPartyTypeId?.value,
						giftRelatedPartyTypeId: giftRelatedPartyTypeId?.__isNew__ ? null : giftRelatedPartyTypeId?.value,
						customGiftRelatedPartyType: giftRelatedPartyTypeId?.__isNew__ ? giftRelatedPartyTypeId?.value : null,
	
						//senderName: senderName,
						receiverName: receiverName,
						description: description,
						giftPurposeDescription: giftPurposeDescription,
						currencyId: currencyId?.value,
						amount: amount,
						giftDate: giftDate,
						toPublicOfficial: toPublicOfficial?.value,
						isWarningApproved: isWarningApproved,
						files: files,
						substitutedUserId: substitute?.value
					}		
				},
			}).then((res) => {
				setIsLoading(false)
				if (!res.data.result) {
					//toast.error(res.data.metadata.message)
					dispatch(openModal({ open: true, type: { modalId: res.data.metadata.message, modalStatus: 'warning' } }))
					setIsWarningApproved(true)
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				} else if (res.data.ok) {
					toast.success('Ekleme işlemi başarılı')
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					navigate('/outgoing-gift')
				}
			})
		}else{
			request(`/draft-form`, {
				method: 'POST',
				data: {
					type: 2,
					formData : {
						targetCompanyId: targetCompanyId?.__isNew__ ? null : targetCompanyId?.value,
						customTargetCompanyName: targetCompanyId?.__isNew__ ? targetCompanyId?.value : null,
	
						//giftTypeId: giftTypeId?.value,
						giftTypeId: giftTypeId?.__isNew__ ? null : giftTypeId?.value,
						customGiftType: giftTypeId?.__isNew__ ? giftTypeId?.value : null,
	
						//giftPurposeId: giftPurposeId?.value,
						giftPurposeId: giftPurposeId?.__isNew__ ? null : giftPurposeId?.value,
						customGiftPurpose: giftPurposeId?.__isNew__ ? giftPurposeId?.value : null,
	
						//giftRelatedPartyTypeId: giftRelatedPartyTypeId?.value,
						giftRelatedPartyTypeId: giftRelatedPartyTypeId?.__isNew__ ? null : giftRelatedPartyTypeId?.value,
						customGiftRelatedPartyType: giftRelatedPartyTypeId?.__isNew__ ? giftRelatedPartyTypeId?.value : null,
	
						//senderName: senderName,
						receiverName: receiverName,
						description: description,
						giftPurposeDescription: giftPurposeDescription,
						currencyId: currencyId?.value,
						amount: amount,
						giftDate: giftDate,
						toPublicOfficial: toPublicOfficial?.value,
						isWarningApproved: isWarningApproved,
						files: files,
						//substitutedUserId: substitute?.value
					}		
				},
			}).then((res) => {
				setIsLoading(false)
				if (!res.data.result) {
					//toast.error(res.data.metadata.message)
					dispatch(openModal({ open: true, type: { modalId: res.data.metadata.message, modalStatus: 'warning' } }))
					setIsWarningApproved(true)
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				} else if (res.data.ok) {
					toast.success('Ekleme işlemi başarılı')
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					navigate('/outgoing-gift')
				}
			})
		}
		
	}


	return (
		<div className='addItemsPage'>
			{isLoading && <Loading width='45px' height='45px' borderWidth='5px' wholePage={true} />}
			<div className='addItemsPage-title'>
				<div onClick={() => navigate('/outgoing-gift')} className='addItemsPage-title-icon'>
					<FaArrowLeft size={24} />
				</div>
				<div className='addItemsPage-title-text'>
					<Title name={t('outgoingGiftDataEntry')} color='#1392e5' fontweight='bold' size='24px' />
				</div>
			</div>

			<div className='addItemsPage-container'>
				<SelectDr
					type={0}
					onChange={(value) => setTargetCompanyId(value ? value : '')}
					value={(targetCompanyId) ? targetCompanyId : ""}
					options={targetCompanySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
					placeholder={t('selectOrCreate')}
					label={t('sendingInstitution')}
					creatableSelect={true}
				/>
				<SelectDr
					type={0}
					onChange={(value) => setGiftRelatedPartyTypeId(value ? value : '')}
					value={(giftRelatedPartyTypeId) ? giftRelatedPartyTypeId : ""}
					options={relatedPartyTypeSelectList?.map((prev) => ({ value: prev.name, label: prev.value, description: prev.description  }))}
					placeholder={t('selectOrCreate')}
					label={t('partyToWhichTheGiftWasSent')}
					creatableSelect={true}
				/>
				<SelectDr
					type={0}
					onChange={(value) => setGiftPurposeId(value ? value : '')}
					value={(giftPurposeId) ? giftPurposeId : ""}
					options={giftPurposeSelectList?.map((prev) => ({ value: prev.name, label: prev.value, description: prev.description  }))}
					placeholder={t('selectOrCreate')}
					label={t('reasonforGift')}
					creatableSelect={true}
				/>
				{/* <Input labelColor='#292929' label={t('senderNameSurname')} placeholder={t('write')} type='text' value={senderName} setValue={setSenderName} /> */}
				<Input
					labelColor='#292929'
					label={t('recipientPersonNameSurname')}
					placeholder={t('write')}
					type='text'
					value={receiverName}
					setValue={setReceiverName}
				/>
				<Input labelColor='#292929' label={t('explainTheReasonForSendingGift')} placeholder={t('write')} type='text' value={giftPurposeDescription} setValue={setGiftPurposeDescription} />
				<Input labelColor='#292929' label={t('giftDetail')} placeholder={t('write')} type='text' value={description} setValue={setDescription} />
				
				<div className='addItemsPage-container-subContainer'>
					{/* <SelectDr type={0} options={options} placeholder="Seç" label="Hediye Gönderim Tarihi"/> */}
					<div className='addItemsPage-container-date'>
						<label htmlFor=''>{t('giftDeliveryDate')}</label>
						<DatePicker
							placeholderText={t('select')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={giftDate}
							onChange={(date) => setGiftDate(date)}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
					</div>
					{/* <SelectDr type={0} options={options} placeholder="Seç" label="Hediye Tutarı"/> */}
					<Input labelColor='#292929' label={t('giftAmount')} placeholder={t('write')} type='number' value={amount} setValue={setAmount} />
				</div>
				{/* <SelectDr type={0} options={options} placeholder={t('select')} label={t('explaintheReasonforReturn')}/> */}
				<SelectDr
					type={0}
					onChange={(value) => setCurrencyId(value ? value : '')}
					value={currencyId ? currencyId : ""}
					options={currencyIdSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
					placeholder={t('select')}
					label={t('CurrencyUnit')}
				/>
				<SelectDr
					type={0}
					onChange={(value) => setGiftTypeId(value ? value : '')}
					value={(giftTypeId) ? giftTypeId : ""}
					options={giftTypeSelectList?.map((prev) => ({ value: prev.name, label: prev.value, description: prev.description  }))}
					placeholder={t('selectOrCreate')}
					label={t('giftType')}
					creatableSelect={true}
				/>
				<SelectDr
					type={0}
					onChange={(value) => setToPublicOfficial(value ? value : '')}
					value={(toPublicOfficial) ? toPublicOfficial : ""}
					options={setToPublicOfficialOptions}
					placeholder={t('select')}
					label={t('publicservant')}
				/>

               

				<div className=''>
					<div className='uploader-container'>
						<label className='uploader-label'>{t('uploadFile')}</label>
						<div className='uploader-container-upl'>
							<label htmlFor='upload'>
								<MdOutlineCloudUpload />
								{t('selectFile')}
							</label>
							<input
								id='upload'
								accept='image/png, image/jpeg, image/gif, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .zip, .rar'
								type='file'
								multiple
								onChange={handleFileChange}
							/>
						</div>
					</div>
					<div className='uploader-result'>
						{files.length > 0 &&
							files.map((file, index) => (
								<di className='uploader-result-item' key={index}>
									<p className=''>{file.name}</p>
									<MdOutlineDeleteSweep style={{ cursor: 'pointer' }} size={20} onClick={() => handleDeleteFile(index)} />
									{/* <img src={file.content} alt={file.name} style={{ maxWidth: '100px', maxHeight: '100px' }} /> */}
								</di>
							))}
					</div>
				</div>

				{
					substitutePage && (
						<SelectDr
							type={0}
							onChange={(value) => setSubstitute(value ? value : '')}
							value={substitute}
							options={substituteSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
							placeholder={t('select')}
							label={t('proxyUser')}
				   />
					)
				}
			</div>

			<div className='addItemsPage-buttonContainer'>
				<Button
					onclick={() => dispatch(openModal({ open: true, type: { modalId: 2, modalStatus: 'reject' } }))}
					name={t('cancel')}
					color='#fff'
					backgroundColor='#c1312f'
					height='38px'
				/>
				<Button onclick={outgoingGiftAddBtn} name={t('save')} color='#fff' backgroundColor='#1392e5' height='38px' />
			</div>

			{modalToggle && modalStatus == 'reject' && modalId == 2 && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('saveFormtoDrafts')} color='#242424' fontweight='bold' size='16px' />
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={createDraft} name={t('send')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}

               {isWarningApproved && modalToggle && modalStatus == 'warning' && (
				<Modal customModal = {"sm-modal"}>
					<div className='modal-type1 modal-type1-changed' style={{gap: "5px"}}>
					<div style={{marginBottom: "10px"}}>
						{
							modalId?.split('\r\n')?.map((modalText,i) => (
								<Title name={modalText} color='#242424' fontweight='bold' size='16px' />
							))
						}
						</div>
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={outgoingGiftAddBtn} name={t('save')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default AddIncomingGift

{
	/* <div className='uploader-container'>
<label className='uploader-label'>{t('uploadFile')}</label>
<div className='uploader-container-upl'>
 <label htmlFor="upload">
	 <MdOutlineCloudUpload />
	 {t('selectFile')}
 </label>
 <input id='upload' type="file" />
</div>

</div> */
}
