import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  export const outgoingGiftSlice = createSlice({
    name: 'outgoingGift',
    initialState,
    reducers: {
      getOutgoingGift: (state, action) => {      
          state.outgoingGiftObj.outgoingGift = action.payload    
      },
      getOutgoingGiftFilterParametersFunc: (state, action) => {
          state.outgoingGiftObj.outgoingGiftFilterParameters = action.payload   
      }
    },
  })
  
  export const { getOutgoingGift, getOutgoingGiftFilterParametersFunc } = outgoingGiftSlice.actions
  
  export default outgoingGiftSlice.reducer