import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  export const auditLogSlice = createSlice({
    name: 'auditLog',
    initialState,
    reducers: {
      getAuditLog: (state, action) => {      
          state.adminPanel.auditLog = action.payload    
      },
      getAuditLogFilterParametersFunc: (state, action) => {
          state.adminPanel.auditLogFilterParameters = action.payload   
      }
    },
  })
  
  export const { getAuditLog, getAuditLogFilterParametersFunc } = auditLogSlice.actions
  
  export default auditLogSlice.reducer