import { useState, useEffect } from 'react'

const useWindowSize = () => {
	const [size, setSize] = useState({ width: null, height: null })

	useEffect(() => {
		const changeSize = () => {
			setSize({ width: window.innerWidth, height: window.innerHeight })
		}
		window.addEventListener('resize', changeSize)
		changeSize()
		return () => {
			window.removeEventListener('resize', changeSize)
		}
	}, [])
	return size
}

export default useWindowSize
