/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { FaRegEdit } from "react-icons/fa";
import { getNotification, getNotificationFilterParametersFunc } from 'redux/features/dataManagement/notifications/notificationSlice'
import { IoCheckmarkDoneSharp,IoCheckmarkSharp} from "react-icons/io5";
import { getMailTemplate, getMailTemplateFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailTemplateSlice'
import { permissionTypes } from 'types/permissionTypes'
import { getMailLog, getMailLogFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailLogSlice'
import moment from 'moment'
import { getEventLog, getEventLogFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/eventLogSlice'
import { downloadFileFromStream } from 'utils/ExcelOutput'



const EventLog = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
    const { adminPanel } = useSelector((state) => state.eventLog)


	const { modalId, modalStatus, modalToggle } = modals
    const { eventLog, eventLogFilterParameters } = adminPanel

	//filter object
	const [filters, setFilters] = useState({
		search: '',
        logLevel: null,
		startDate: null,
		endDate: null,
		sortingOrderByVal: "desc",
		sortingColumnName: "timestamp",
		page: 1,
	})

	const navigate = useNavigate()


    const eventLogEnums = {
        0 : t("verbose"), //(Ayıklama)
        1 : t("debug"), //(Hata Ayıklama)
        2 : t("information"), //(Bilgi)
        3 : t("warning"), //(Uyarı)
        4 : t("error"), //(Hata)
        5 : t("fatal") //(Ölümcül Hata)
    }

	const COLUMNS = [
        {
			label: t('application'),
			renderCell: (item) => item.fields.application,
			name: '',
		},
		{
			label: t('requestId'),
			renderCell: (item) => item.fields.requestId,
			name: '',
		},
		{
			label: t('requestAddress'),
			renderCell: (item) => item.fields.requestPath,
			name: '',
		},
		{
			label: t('username'),
			renderCell: (item) => item.fields.userEmail,
			name: '',
		},
        {
			label: t('eventLogs'),
			renderCell: (item) => eventLogEnums[item.level],
			name: '',
		},
        {
			label: "Mesaj",
			renderCell: (item) => item.message,
			name: 'message',
		},
		{
			label: t('logDate'),
			renderCell: (item) => new Date(item.timestamp).toLocaleDateString(i18n.language),
			name: "timestamp"
		},
		// {
		// 	label: '',
		// 	renderCell: (item) => (
		// 		<div className='lastCellFlex'>
		// 			<div 
		// 			className={`cabin-semibold ${
		// 				item.status == 1
		// 					? 'tableActiveStatus'
		// 					: 'tablePassiveStatus'
		// 			}`}
		// 			>{item.status == 1 ? t('active') : t('passive')}</div>
		// 		</div>
		// 	),
		// 	name: '',
		// },
	]

	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.startDate, filters.endDate, filters.search, filters.logLevel, filters.sortingColumnName, filters.sortingOrderByVal])

	useEffect(() => {
		request(`/log/event-log?Page=${filters?.page}&Search=${filters?.search}&LogLevel=${filters?.logLevel || ''}&StartDate=${
			filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
		}&EndDate=${
			filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''
		}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getEventLogFilterParametersFunc(filterPrm))
				dispatch(getEventLog(res.data.result.items))
			}
		})
	}, [filters])


	let nodes = eventLog

	let data = { nodes }


	const goToPrevPage = () => {
		if (eventLogFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: eventLogFilterParameters?.pageIndex - 1}))
		// request(`/log/event-log?Page=${eventLogFilterParameters?.pageIndex - 1}`, {
		// 	method: 'GET',
		// }).then((res) => {
		// 	if (res.data.ok) {
		// 		let filterPrm = {
		// 			pageIndex: res.data.result.pageIndex,
		// 			pageSize: res.data.result.pageSize,
		// 			totalCount: res.data.result.totalCount,
		// 			totalPages: res.data.result.totalPages,
		// 			indexFrom: res.data.result.indexFrom,
		// 		}
		// 		dispatch(getEventLogFilterParametersFunc(filterPrm))
		// 		dispatch(getEventLog(res.data.result.items))
		// 	}
		// })
	}
	const goToNextPage = () => {
		if (eventLogFilterParameters?.pageIndex + 1 >= eventLogFilterParameters?.totalPages) return
		setFilters(prev => ({...prev, page: eventLogFilterParameters?.pageIndex + 2}))
		// request(`/log/event-log?Page=${eventLogFilterParameters?.pageIndex + 2}`, {
		// 	method: 'GET',
		// }).then((res) => {
		// 	if (res.data.ok) {
		// 		let filterPrm = {
		// 			pageIndex: res.data.result.pageIndex,
		// 			pageSize: res.data.result.pageSize,
		// 			totalCount: res.data.result.totalCount,
		// 			totalPages: res.data.result.totalPages,
		// 			indexFrom: res.data.result.indexFrom,
		// 		}
		// 		dispatch(getEventLogFilterParametersFunc(filterPrm))
		// 		dispatch(getEventLog(res.data.result.items))
		// 	}
		// })
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
		// request(`/log/event-log?Page=${index + 1}`, {
		// 	method: 'GET',
		// }).then((res) => {
		// 	if (res.data.ok) {
		// 		let filterPrm = {
		// 			pageIndex: res.data.result.pageIndex,
		// 			pageSize: res.data.result.pageSize,
		// 			totalCount: res.data.result.totalCount,
		// 			totalPages: res.data.result.totalPages,
		// 			indexFrom: res.data.result.indexFrom,
		// 		}
		// 		dispatch(getEventLogFilterParametersFunc(filterPrm))
		// 		dispatch(getEventLog(res.data.result.items))
		// 	}
		// })
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: eventLogFilterParameters,
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	const handleDownloadXlsx = () => {
		request(`/log/export-event-log-excel?Page=${filters?.page}&Search=${filters?.search}&LogLevel=${filters?.logLevel || ''}&StartDate=${
			filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
		}&EndDate=${
			filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''
		}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('event-log.xlsx', res.data.result)
			}
		})
	}

	return (
		<div className='panel-table location-group incoming-gift'>
		    <div className='topButtonGroup'>
			<div className='topButtonGroup-container'>
				<Button
					onclick={handleDownloadXlsx}
					name={t('exportAsExcel')}
					img={images.exit}
					color='#008814'
					backgroundColor='transparent'
					height='38px'
				/>
			</div>
			</div>
				<div className='tableCard'>
					<Table
					    gridCSS="eventLog"
						deleteBtn={true}
						paginationAllFunctionsList={paginationAllFunctionsList}
						data={data}
						columns={COLUMNS}
						filterType={8}
						handleFilterChange={handleFilterChange}
						setFilters={setFilters}
						filters={filters}
						sortTable={sortTable}
						isLoading = {loading}
					/>
				</div>

		</div>
	)
}

export default EventLog
