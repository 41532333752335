import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../initialState';


  
  export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
      openSidebar: (state, action) => {      
          state.sidebarToggle = !state.sidebarToggle 
          if(action.payload === "sidebar"){
            state.sidebarToggle = false 
          }   
      },
      openAccordion: (state, action) => {
        Object.keys(state.accordions).filter(acc => acc != action.payload).forEach(acc => {
          state.accordions[acc] = false;
        });
        state.accordions[action.payload] = ! state.accordions[action.payload]
      },
      homePageCardFunc: (state, action) => {
        Object.keys(state.homePageCard).filter(acc => acc != action.payload.name).forEach(acc => {
          state.homePageCard[acc] = [];
        });
        state.homePageCard[action.payload.name] = action.payload.result
      },
      openModal: (state, action) => {      
        state.modals["modalToggle"] = action.payload.open  
        state.modals["modalStatus"] = action.payload.type.modalStatus  
        state.modals["modalId"] = action.payload.type.modalId   
        state.modals["editableData"] = action.payload.type.editableData   
        
      },
      errorInputBorder : (state, action) => {
        state.errorInput = action.payload 
      }
    },
  })
  
  export const { openSidebar,openAccordion, homePageCardFunc, openModal, errorInputBorder } = generalSlice.actions
  
  export default generalSlice.reducer