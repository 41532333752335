/* eslint-disable */
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import SelectDr from 'components/common/SelectDr'
import Title from 'components/common/Title'
import Modal from 'containers/modal/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { openModal } from 'redux/features/general/generalSlice'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import  DatePicker  from 'react-datepicker';
import Locale from 'utils/Locale'
import { getCompanyHospitality, getCompanyHospitalityFilterParametersFunc } from 'redux/features/dataManagement/companyHospitality/companyHospitalitySlice'
import Loading from 'components/common/Loading'

const AddCompanyHospitality = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation();
	const dispatch = useDispatch()
	const { modals } = useSelector((state) => state.general)
	const [request] = useRequest()

	let substitutePage = window.location.pathname.split('/')[1] == "substitute-panel"


	const { modalToggle, modalStatus, modalId } = modals
	const { state } = location;
	const searchParams = new URLSearchParams(location.search);
	const draftParam = searchParams.get('draft');
	const [isLoading, setIsLoading] = useState(false)


	const [eventPurposeId, setEventPurposeId] = useState();
	const [eventRelatedPartyTypeId, setEventRelatedPartyTypeId] = useState();
	const [currencyId, setCurrencyId] = useState();
	const [amount, setAmount] = useState();
	const [description, setDescription] = useState("");
	const [eventDate, setEventDate] = useState();
	const [hasPublicOfficial, setHasPublicOfficial] = useState();
	const [substitute, setSubstitute] = useState(null) 


	//adding options
	const [eventPurposeSelectList, setEventPurposeSelectList] = useState();
	const [eventRelatedPartyTypeSelectList, setEventRelatedPartyTypeSelectList] = useState();
	const [substituteSelectList, setSubstituteSelectList] = useState()
	const [currencyIdSelectList, setCurrencyIdSelectList] = useState();	
	const [draftInfo, setDraftInfo] = useState(state);

	useEffect(() => {
		if(state && draftParam){
		   setDraftInfo(draftInfo) 
		   setEventPurposeId(eventPurposeSelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.eventPurposeId))
		   setEventRelatedPartyTypeId(eventRelatedPartyTypeSelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.eventRelatedPartyTypeId))	   
		   setCurrencyId(currencyIdSelectList?.map(tcs => ({ value: tcs.name, label: tcs.value }))?.find((tc) => tc.value == state.currencyId))		   
		   setHasPublicOfficial(hasPublicOfficialOptions?.find((tc) => tc.value == state.hasPublicOfficial))
		   setEventDate(state.eventDate)
		   setAmount(state.amount)
	   }
   }, [state,draftParam, eventRelatedPartyTypeSelectList, eventPurposeSelectList])
	
	const hasPublicOfficialOptions = [
		{ value: true, label: t('yes') },
		{ value: false, label: t('no') }
	]

	useEffect(() => {
		request('/gift/purpose/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setEventPurposeSelectList(res.data.result.items)
			}
		})

		request('/gift/related-party-type/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setEventRelatedPartyTypeSelectList(res.data.result.items)
			}
		})

		request('/currency/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setCurrencyIdSelectList(res.data.result.items)
			}
		})

		request('/identity/user/substitute-select-list', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setSubstituteSelectList(res.data.result.items)
				if(res.data.result.items.length == 1){
					setSubstitute(res?.data?.result?.items?.map((prev) => ({ value: prev.name, label: prev.value }))[0])
				}
			}
		})
	},[])


	const companyHospitalityAddBtn = () => {
		setIsLoading(true)
		if(substitutePage && !substitute?.value){
			setIsLoading(false)
			toast.error(t('proxyUserMessage'))
			return;
		}
	   if(substitutePage){
		request(`/company-hospitality`, {
			method: 'POST',
			data: {
				eventPurposeId: eventPurposeId?.__isNew__ ? null : eventPurposeId?.value,
				customEventPurpose: eventPurposeId?.__isNew__ ? eventPurposeId?.value : null,

				eventRelatedPartyTypeId: eventRelatedPartyTypeId?.__isNew__ ? null : eventRelatedPartyTypeId?.value,
				customEventRelatedPartyType: eventRelatedPartyTypeId?.__isNew__ ? eventRelatedPartyTypeId?.value : null,

				//eventPurposeId: eventPurposeId?.value,
				//eventRelatedPartyTypeId: eventRelatedPartyTypeId?.value,

				currencyId: currencyId?.value,
				amount: amount,
				eventDate: eventDate,
				description: description,
				hasPublicOfficial: hasPublicOfficial?.value,
				substitutedUserId: substitute?.value
			},
		}).then((res) => {
			setIsLoading(false)
			if (res.data.ok) {
				setIsLoading(true)
				request('/company-hospitality', {
					method: 'GET',
				}).then((res) => {
					setIsLoading(false)
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getCompanyHospitalityFilterParametersFunc(filterPrm))
						dispatch(getCompanyHospitality(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			navigate('/company-hospitality');
			setTimeout(() => {
				 setAmount()
				 setDescription()
				 setEventDate()
				 setCurrencyId()
				 setHasPublicOfficial(false)
				 setEventPurposeId()
				 setEventRelatedPartyTypeId()
			}, 100)
		})
	   }else{
		request(`/company-hospitality`, {
			method: 'POST',
			data: {
				eventPurposeId: eventPurposeId?.__isNew__ ? null : eventPurposeId?.value,
				customEventPurpose: eventPurposeId?.__isNew__ ? eventPurposeId?.value : null,

				eventRelatedPartyTypeId: eventRelatedPartyTypeId?.__isNew__ ? null : eventRelatedPartyTypeId?.value,
				customEventRelatedPartyType: eventRelatedPartyTypeId?.__isNew__ ? eventRelatedPartyTypeId?.value : null,

				//eventPurposeId: eventPurposeId?.value,
				//eventRelatedPartyTypeId: eventRelatedPartyTypeId?.value,

				currencyId: currencyId?.value,
				amount: amount,
				eventDate: eventDate,
				description: description,
				hasPublicOfficial: hasPublicOfficial?.value,
				//substitutedUserId: substitute?.value
			},
		}).then((res) => {
			setIsLoading(false)
			if (res.data.ok) {
				setIsLoading(true)
				request('/company-hospitality', {
					method: 'GET',
				}).then((res) => {
					setIsLoading(false)
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getCompanyHospitalityFilterParametersFunc(filterPrm))
						dispatch(getCompanyHospitality(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			navigate('/company-hospitality');
			setTimeout(() => {
				 setAmount()
				 setDescription()
				 setEventDate()
				 setCurrencyId()
				 setHasPublicOfficial(false)
				 setEventPurposeId()
				 setEventRelatedPartyTypeId()
			}, 100)
		})
	   }
}


const backBtn = () => {
	setAmount()
	setEventDate()
	setCurrencyId()
	setHasPublicOfficial(false)
	setEventPurposeId()
	setEventRelatedPartyTypeId()
	navigate('/company-hospitality')
}

const createDraft = () => {
	setIsLoading(true)
	if(substitutePage && !substitute?.value){
		setIsLoading(false)
		toast.error(t('proxyUserMessage'))
		return;
	}
	if(substitutePage){
		request(`/draft-form`, {
			method: 'POST',
			data: {
				type: 3,
				formData: {
					//eventPurposeId: eventPurposeId?.value,
					//eventRelatedPartyTypeId: eventRelatedPartyTypeId?.value,
	
					eventPurposeId: eventPurposeId?.__isNew__ ? null : eventPurposeId?.value,
					customEventPurpose: eventPurposeId?.__isNew__ ? eventPurposeId?.value : null,
	
					eventRelatedPartyTypeId: eventRelatedPartyTypeId?.__isNew__ ? null : eventRelatedPartyTypeId?.value,
					customEventRelatedPartyType: eventRelatedPartyTypeId?.__isNew__ ? eventRelatedPartyTypeId?.value : null,
	
					currencyId: currencyId?.value,
					amount: amount,
					eventDate: eventDate,
					hasPublicOfficial: hasPublicOfficial?.value,
					substitutedUserId: substitute?.value
				}
			},
		}).then((res) => {
			setIsLoading(false)
			if (res.data.ok) {
				setIsLoading(true)
				request('/draft-form', {
					method: 'GET',
				}).then((res) => {
					setIsLoading(false)
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getCompanyHospitalityFilterParametersFunc(filterPrm))
						dispatch(getCompanyHospitality(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
			navigate('/company-hospitality');
		})
	}else{
		request(`/draft-form`, {
			method: 'POST',
			data: {
				type: 3,
				formData: {
					//eventPurposeId: eventPurposeId?.value,
					//eventRelatedPartyTypeId: eventRelatedPartyTypeId?.value,
	
					eventPurposeId: eventPurposeId?.__isNew__ ? null : eventPurposeId?.value,
					customEventPurpose: eventPurposeId?.__isNew__ ? eventPurposeId?.value : null,
	
					eventRelatedPartyTypeId: eventRelatedPartyTypeId?.__isNew__ ? null : eventRelatedPartyTypeId?.value,
					customEventRelatedPartyType: eventRelatedPartyTypeId?.__isNew__ ? eventRelatedPartyTypeId?.value : null,
	
					currencyId: currencyId?.value,
					amount: amount,
					eventDate: eventDate,
					hasPublicOfficial: hasPublicOfficial?.value,
					//substitutedUserId: substitute?.value
				}
			},
		}).then((res) => {
			setIsLoading(false)
			if (res.data.ok) {
				setIsLoading(true)
				request('/draft-form', {
					method: 'GET',
				}).then((res) => {
					setIsLoading(false)
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getCompanyHospitalityFilterParametersFunc(filterPrm))
						dispatch(getCompanyHospitality(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
			navigate('/company-hospitality');
		})
	}
	
}

	return (
		<div className='addItemsPage'>
			{isLoading && <Loading width='45px' height='45px' borderWidth='5px' wholePage={true} />}
			<div className='addItemsPage-title'>
				<div onClick={backBtn} className='addItemsPage-title-icon'>
					<FaArrowLeft size={24} />
				</div>
				<div className='addItemsPage-title-text'>
					<Title name={t('companyHospitalityDataEntry')} color='#1392e5' fontweight='bold' size='24px' />
				</div>
			</div>

			<div className='addItemsPage-container'>
				<SelectDr
					onChange={(value) => setEventPurposeId(value ? value : '')}
					type={0}
					//isLoading={loading}
					options={eventPurposeSelectList?.map((prev) => ({ value: prev.name, label: prev.value, description: prev.description  }))}
					value={(eventPurposeId) ? eventPurposeId : ""}
					placeholder={t('selectOrCreate')}
					label={t('eventPurpose')}
					creatableSelect={true}
				/>
				<SelectDr
					onChange={(value) => setEventRelatedPartyTypeId(value ? value : '')}
					type={0}
					//isLoading={loading}
					options={eventRelatedPartyTypeSelectList?.map((prev) => ({ value: prev.name, label: prev.value, description: prev.description  }))}
					value={(eventRelatedPartyTypeId) ? eventRelatedPartyTypeId : ""}
					placeholder={t('selectOrCreate')}
					label={t('eventRelatedPartyType')}
					creatableSelect={true}
				/>
				<SelectDr
					onChange={(value) => setCurrencyId(value ? value : '')}
					type={0}
					//isLoading={loading}
					options={currencyIdSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
					value={(currencyId) ? currencyId : ""}
					placeholder={t('select')}
					label={t('CurrencyUnit')}
				/>
				<Input 
				    value={amount} 
					placeholder={t('write')}
					setValue={setAmount} 
					labelColor='#292929' 
					label={t('eventAmount')} 
					type='number' 
				/>
				<div className='addItemsPage-container-picker'>
				<label htmlFor=''>{t('eventDate')}</label>
						<DatePicker
							placeholderText={t('select')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={eventDate}
							onChange={(date) => setEventDate(date)}
							showPopperArrow={false}
							locale={Locale()}
						/>
				</div>
				<SelectDr
					onChange={(value) => setHasPublicOfficial(value ? value : '')}
					type={0}
					//isLoading={loading}
					options={hasPublicOfficialOptions}
					value={(hasPublicOfficial) ? hasPublicOfficial : ""}
					placeholder={t('select')}
					label={t('isTherePublicOfficial')}
				/>
				
                <Input 
				    value={description} 
					placeholder={t('write')}
					setValue={setDescription} 
					labelColor='#292929' 
					label={t('descriptionCompanyDetail') + " (" + t('ifYouNeedToDifferentiateBetweenInternalAndExternalInstitutions') + ")" } 
					type='text' 
				/>
				{
					substitutePage && (
						<SelectDr
							type={0}
							onChange={(value) => setSubstitute(value ? value : '')}
							value={substitute}
							options={substituteSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
							placeholder={t('select')}
							label={t('proxyUser')}
				   />
					)
				}
			</div>

			<div className='addItemsPage-buttonContainer'>
				<Button
					onclick={() => dispatch(openModal({ open: true, type: { modalId: 2, modalStatus: 'reject' } }))}
					name={t('cancel')}
					color='#fff'
					backgroundColor='#c1312f'
					height='38px'
				/>
				<Button onclick={companyHospitalityAddBtn} name={t('save')} color='#fff' backgroundColor='#1392e5' height='38px' />
			</div>

			{modalToggle && modalStatus == 'reject' && modalId == 2 && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('saveFormtoDrafts')} color='#242424' fontweight='bold' size='16px' />
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={createDraft} name={t('send')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default AddCompanyHospitality
