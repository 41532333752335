import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Layout from 'containers/layout/Layout'
import Home from 'pages/home/Home'
import IncomingGift from 'pages/incomingGift/IncomingGift'
import 'react-datepicker/dist/react-datepicker.css'
import i18n from 'components/i18n'
import { I18nextProvider } from 'react-i18next'
import OutcomingGift from 'pages/outgoingGift/OutgoingGift'
import CompanyHospitality from 'pages/companyHospitality/CompanyHospitality'
import NotFound from 'pages/notFound/NotFound'
import Draft from 'pages/draft/Draft'
import AdminPanel from 'pages/adminPanel/AdminPanel'
import LocationGroup from 'pages/adminPanel/adminPanelComp/LocationGroup'
import GiftType from 'pages/adminPanel/adminPanelComp/GiftType'
import GiftPurpose from 'pages/adminPanel/adminPanelComp/GiftPurpose'
import RelationPartyType from 'pages/adminPanel/adminPanelComp/RelationPartyType'
import ReasonsForRefusal from 'pages/adminPanel/adminPanelComp/ReasonsForRefusal'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import InternalLogin from 'pages/login/InternalLogin'
import ProtectedRoutes from 'containers/protectedRoute/ProtectedRoute'
import Error from 'pages/errorPage/Error'
import { permissionTypes } from 'types/permissionTypes'
import Currency from 'pages/adminPanel/adminPanelComp/Currency'
import UserManagement from 'pages/adminPanel/adminPanelComp/UserManagement'
import RoleManagement from 'pages/adminPanel/adminPanelComp/RoleManagement'
import SignInSso from 'pages/SignInSso'
import GiftLimit from 'pages/adminPanel/adminPanelComp/GiftLimit'
import IncomingGiftDetail from 'pages/incomingGift/IncomingGiftDetail'
import OutgoingGiftDetail from 'pages/outgoingGift/OutgoingGiftDetail'
import CompanyHospitalityDetail from 'pages/companyHospitality/CompanyHospitalityDetail'
import AddIncomingGift from 'pages/addItemsPage/addItemsPageComp/AddIncomingGift'
import AddOutgoingGift from 'pages/addItemsPage/addItemsPageComp/AddOutgoingGift'
import AddCompanyHospitality from 'pages/addItemsPage/addItemsPageComp/AddCompanyHospitality'
import Settings from 'pages/adminPanel/adminPanelComp/Settings'
import Notifications from 'pages/notification/Notifications'
import Profile from 'pages/profile/Profile'
import MailTemplate from 'pages/adminPanel/adminPanelComp/MailTemplate'
import MailLog from 'pages/adminPanel/adminPanelComp/MailLog.'
import EventLog from 'pages/adminPanel/adminPanelComp/EventLog'
import AuditLog from 'pages/adminPanel/adminPanelComp/AuditLog'
import 'react-tooltip/dist/react-tooltip.css'

// eslint-disable-next-line
const isInternalLoginEnabled = process.env.REACT_APP_INTERNAL_LOGIN_ENABLED === "true";

function App() {
	
	return (
		<I18nextProvider i18n={i18n}>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<ProtectedRoutes />}>
						<Route path='/' element={<Layout />}>
							<Route index element={<Home />} />
							<Route path='incoming-gift' index element={<IncomingGift />} />
							<Route path='incoming-gift/:id' index element={<IncomingGiftDetail />} />
							<Route path='incoming-gift/add' index element={<AddIncomingGift />} />

							<Route path='outgoing-gift' index element={<OutcomingGift />} />
							<Route path='outgoing-gift/:id' index element={<OutgoingGiftDetail />} />
							<Route path='outgoing-gift/add' index element={<AddOutgoingGift />} />

							<Route path='company-hospitality' index element={<CompanyHospitality />} />
							<Route path='company-hospitality/:id' index element={<CompanyHospitalityDetail />} />
							<Route path='company-hospitality/add' index element={<AddCompanyHospitality />} />

							<Route path='draft' index element={<Draft />} />
							<Route path='notification' index element={<Notifications />} />
							<Route path='profile' index element={<Profile />} />

							{/* <Route path=':baseUrl/add' index element={<AddItemsPage />} /> */}
							{/* <Route path=':activeUrl/:id' index element={<Detail />} /> */}

							<Route path='substitute-panel' element={<AdminPanel />}>
								<Route path='incoming-gift' element={<IncomingGift />} />
								<Route path='incoming-gift/:id' index element={<IncomingGiftDetail />} />
								<Route path='incoming-gift/add' index element={<AddIncomingGift />} />

								<Route path='outgoing-gift' index element={<OutcomingGift />} />
								<Route path='outgoing-gift/:id' index element={<OutgoingGiftDetail />} />
								<Route path='outgoing-gift/add' index element={<AddOutgoingGift />} />

								<Route path='company-hospitality' index element={<CompanyHospitality />} />
								<Route path='company-hospitality/:id' index element={<CompanyHospitalityDetail />} />
								<Route path='company-hospitality/add' index element={<AddCompanyHospitality />} />
							</Route>

							<Route path='admin-panel' element={<AdminPanel />}>
								<Route path='location-group' element={<ProtectedRoutes requiredPermission={permissionTypes.Companies} />}>
									<Route index element={<LocationGroup />} />
								</Route>

								<Route path='gift-type' element={<ProtectedRoutes requiredPermission={permissionTypes.GiftTypes} />}>
									<Route index element={<GiftType />} />
								</Route>

								<Route path='gift-limit' element={<ProtectedRoutes requiredPermission={permissionTypes.CompanyGiftLimits} />}>
									<Route index element={<GiftLimit />} />
								</Route>

								<Route path='gift-purpose' element={<ProtectedRoutes requiredPermission={permissionTypes.GiftPurposes} />}>
									<Route index element={<GiftPurpose />} />
								</Route>

								<Route path='related-partyType' element={<ProtectedRoutes requiredPermission={permissionTypes.GiftRelatedPartyTypes} />}>
									<Route index element={<RelationPartyType />} />
								</Route>

								<Route path='reasons-refusal' element={<ProtectedRoutes requiredPermission={permissionTypes.GiftRejectReasons} />}>
									<Route index element={<ReasonsForRefusal />} />
								</Route>

								<Route path='currency' element={<ProtectedRoutes requiredPermission={permissionTypes.Currencies} />}>
									<Route index element={<Currency />} />
								</Route>

								<Route path='user-management' element={<ProtectedRoutes requiredPermission={permissionTypes.Users} />}>
									<Route index element={<UserManagement />} />
								</Route>

								<Route path='role-management' element={<ProtectedRoutes requiredPermission={permissionTypes.Roles} />}>
									<Route index element={<RoleManagement />} />
								</Route>

								<Route path='settings' element={<ProtectedRoutes requiredPermission={permissionTypes.Settings} />}>
									<Route index element={<Settings />} />
								</Route>

								<Route path='mail-template' element={<ProtectedRoutes requiredPermission={permissionTypes.EmailTemplates} />}>
									<Route index element={<MailTemplate />} />
								</Route>

								<Route path='mail-log' element={<ProtectedRoutes requiredPermission={permissionTypes.EmailTemplates} />}>
									<Route index element={<MailLog />} />
								</Route>

								<Route path='event-log' element={<ProtectedRoutes requiredPermission={permissionTypes.EventLogs} />}>
									<Route index element={<EventLog />} />
								</Route>

								<Route path='audit-log' element={<ProtectedRoutes requiredPermission={permissionTypes.AuditLogs} />}>
									<Route index element={<AuditLog />} />
								</Route>
							</Route>
							<Route path='*' index element={<NotFound />} />
						</Route>
					</Route>
					<Route path='internal-login' element={isInternalLoginEnabled ? <InternalLogin /> : <Navigate to='/error/404' />} />
					<Route path='signin-saml' element={<SignInSso />} />
					<Route path='error/:statusCode' element={<Error />} />
					<Route path='*' index element={<NotFound />} />
				</Routes>
				<ToastContainer />
			</BrowserRouter>
		</I18nextProvider>
	)
}

export default App
