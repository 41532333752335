import { Outlet } from 'react-router-dom'
import Footer from 'components/footer/Footer'
import Navbar from 'components/navbar/Navbar'
import Sidebar from './../../components/sidebar/Sidebar'
import { useRequest } from 'hooks/useRequest'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getConfigurations } from 'redux/features/permissions/configuration/configurationSlice'
import Loading from 'components/common/Loading'

const Layout = () => {
	const [request] = useRequest()
	const dispatch = useDispatch()
	const [isLoaded, setIsLoaded] = useState()

	useEffect(() => {
		setIsLoaded(false)
		request(`/application/configuration`, {
			method: 'GET',
		}).then((res) => {
			if(res.data.ok){
				dispatch(getConfigurations(res.data.result))
				setIsLoaded(true)
			}
		})
	},[])

	if (!isLoaded) return <Loading wholePage width="45px" height="45px" borderWidth="5px"/>

	return (
		<div className='layout'>
			<Sidebar />
			<div className='layout-main-container'>
				<Navbar />
				<div className='layout-main-container-outlet'>
					<Outlet />
				</div>
				<Footer />
			</div>
		</div>
	)
}

export default Layout
