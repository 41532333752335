export const permissionTypes = {
    AuditLogs: "AuditLogs",
    Companies: "Companies",
    CompaniesDelete: "Companies.Delete",
    CompaniesLookup: "Companies.Lookup",
    CompaniesUpsert: "Companies.Upsert",
    CompanyGiftLimits: "CompanyGiftLimits",
    CompanyGiftLimitsDelete: "CompanyGiftLimits.Delete",
    CompanyGiftLimitsUpsert: "CompanyGiftLimits.Upsert",
    CompanyHospitalities: "CompanyHospitalities",
    CompanyHospitalitiesCreate: "CompanyHospitalities.Create",
    CompanyHospitalitiesDelete: "CompanyHospitalities.Delete",
    CompanyHospitalitiesViewAllCompanies: "CompanyHospitalities.ViewAllCompanies",
    CompanyHospitalitiesViewOwnCompany: "CompanyHospitalities.ViewOwnCompany",
    Currencies: "Currencies",
    CurrenciesDelete: "Currencies.Delete",
    CurrenciesLookup: "Currencies.Lookup",
    CurrenciesUpsert: "Currencies.Upsert",
    Dashboard: "Dashboard",
    EmailTemplates: "EmailTemplates",
    EmailTemplatesUpdate: "EmailTemplates.Update",
    EventLogs: "EventLogs",
    Files: "Files",
    FilesDelete: "Files.Delete",
    FilesSave: "Files.Save",
    GiftPurposes: "GiftPurposes",
    GiftPurposesDelete: "GiftPurposes.Delete",
    GiftPurposesLookup: "GiftPurposes.Lookup",
    GiftPurposesUpsert: "GiftPurposes.Upsert",
    GiftRejectReasons: "GiftRejectReasons",
    GiftRejectReasonsDelete: "GiftRejectReasons.Delete",
    GiftRejectReasonsLookup: "GiftRejectReasons.Lookup",
    GiftRejectReasonsUpsert: "GiftRejectReasons.Upsert",
    GiftRelatedPartyTypes: "GiftRelatedPartyTypes",
    GiftRelatedPartyTypesDelete: "GiftRelatedPartyTypes.Delete",
    GiftRelatedPartyTypesLookup: "GiftRelatedPartyTypes.Lookup",
    GiftRelatedPartyTypesUpsert: "GiftRelatedPartyTypes.Upsert",
    GiftTypes: "GiftTypes",
    GiftTypesDelete: "GiftTypes.Delete",
    GiftTypesLookup: "GiftTypes.Lookup",
    GiftTypesUpsert: "GiftTypes.Upsert",
    Gifts: "Gifts",
    GiftsComplianceManagerApprove: "Gifts.ComplianceManagerApprove",
    GiftsCreate: "Gifts.Create",
    GiftsDelete: "Gifts.Delete",
    GiftsGeneralManagerApprove: "Gifts.GeneralManagerApprove",
    GiftsManagerApprove: "Gifts.ManagerApprove",
    GiftsViewAllCompanies: "Gifts.ViewAllCompanies",
    GiftsViewOwnCompany: "Gifts.ViewOwnCompany",
    HangfireDashboard: "HangfireDashboard",
    Roles: "Roles",
    RolesDelete: "Roles.Delete",
    RolesLookup: "Roles.Lookup",
    RolesManagePermissions: "Roles.ManagePermissions",
    RolesUpsert: "Roles.Upsert",
    Settings: "Settings",
    Users: "Users",
    UsersDelete: "Users.Delete",
    UsersLookup: "Users.Lookup",
    UsersUpsert: "Users.Upsert",
    ComplianceManagerApprovalDate: "Gifts.ComplianceManagerApprove",
    ManagerApprove: "Gifts.ManagerApprove",
    GeneralManagerApprove: "Gifts.GeneralManagerApprove"
}