// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Dil dosyalarını yükleyin
import enTranslation from '../locales/en.json';
import trTranslation from '../locales/tr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      tr: {
        translation: trTranslation,
      },
    },
    lng: localStorage.getItem('language') || 'tr', // Varsayılan dil
    interpolation: {
      escapeValue: false, // React bileşenlerinde HTML kullanımına izin ver
    },
  });

export default i18n;
