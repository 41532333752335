import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { PropTypes } from 'prop-types'

// eslint-disable-next-line no-undef
const loginUrl = process.env.REACT_APP_LOGIN_ENDPOINT;

const ProtectedRoutes = ({requiredPermission}) => {
  const token = JSON.parse(localStorage.getItem('auth'));
  
  const {permissions} = useSelector(state => state.configuration)
  const {configurations} = permissions

  if(!token || (configurations?.currentUser && configurations?.currentUser?.isAuthenticated != true))
  {
    window.location.href = loginUrl
    return
  }

  if (requiredPermission && !configurations?.currentUser?.permissions[requiredPermission]){
    return <Navigate to={`/error/403`} replace />
  }

  return <Outlet />;
};

ProtectedRoutes.propTypes = {
	requiredPermission: PropTypes.string
}

export default ProtectedRoutes;