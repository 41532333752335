import React, { useEffect } from 'react'
import SidebarTop from './sidebarComp/SidebarTop'
import SidebarBottom from './sidebarComp/SidebarBottom'
import { useDispatch, useSelector } from 'react-redux'
import useWindowSize from 'hooks/useWindowSize'
import { openSidebar } from 'redux/features/general/generalSlice'

const Sidebar = () => {
  const {sidebarToggle} = useSelector(state => state.general);
  const {width} = useWindowSize();
  const dispatch = useDispatch();


  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
 }, [width])

 const handleClickOutside = (e) => {
      if(width <= 1000){
        if(!e.target.closest('.hamburgerMenu') && !e.target.closest('.sidebar')){
          dispatch(openSidebar("sidebar"))
       }
      }
      
 }

  return (
    sidebarToggle && (
      <div className='sidebar'>
      <SidebarTop/>
      <SidebarBottom/>
  </div>
    )
  )
}

export default Sidebar