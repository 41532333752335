import React, { useEffect } from 'react'
import Card from './Card'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useRequest } from 'hooks/useRequest'
import Loading from 'components/common/Loading'
import { PropTypes } from 'prop-types'

const Cards = ({ switchOpen, setReportCommonStats, reportCommonStats, pieceAndAmountFilter }) => {
	const { t } = useTranslation()
	const { homePageCard } = useSelector((state) => state.general)
	const { gelenhediyeler, gidenhediyeler, sirketagirlama, onaydabekleyenler } = homePageCard
	const [request, loading] = useRequest()

	useEffect(() => {
		request(`/report/common-stats?OnlyOwnRecords=${switchOpen}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setReportCommonStats(res.data.result)
			}
		})
	}, [switchOpen])

	const cards = [
		{
			name: t('incomingGift'),
			id: 'gelenhediyeler',
			open: gelenhediyeler,
			content:
				pieceAndAmountFilter == 0
					? [
							reportCommonStats?.incomingGiftApprovedCount !== undefined &&
								reportCommonStats?.incomingGiftApprovedCount !== null && {
									name: t('approved'),
									value: reportCommonStats.incomingGiftApprovedCount,
									color: '#08a52b',
								},
							reportCommonStats?.incomingGiftRejectedCount !== undefined &&
								reportCommonStats?.incomingGiftRejectedCount !== null && {
									name: t('rejected'),
									value: reportCommonStats.incomingGiftRejectedCount,
									color: '#c1312f',
								},
							reportCommonStats?.incomingGiftWaitingForApprovalCount !== undefined &&
								reportCommonStats?.incomingGiftWaitingForApprovalCount !== null && {
									name: t('pendingApproval'),
									value: reportCommonStats.incomingGiftWaitingForApprovalCount,
									color: '#e09d00',
								},
							reportCommonStats?.incomingGiftTotalCount !== undefined &&
								reportCommonStats?.incomingGiftTotalCount !== null && {
									name: t('total'),
									value: reportCommonStats.incomingGiftTotalCount,
									color: '#1392e5',
								},
					].filter(Boolean)
					: [
							reportCommonStats?.incomingGiftApprovedAmount !== undefined &&
								reportCommonStats?.incomingGiftApprovedAmount !== null && {
									name: t('approved'),
									value: '$' + reportCommonStats.incomingGiftApprovedAmount,
									color: '#08a52b',
								},
							reportCommonStats?.incomingGiftRejectedAmount !== undefined &&
								reportCommonStats?.incomingGiftRejectedAmount !== null && {
									name: t('rejected'),
									value: '$' + reportCommonStats.incomingGiftRejectedAmount,
									color: '#c1312f',
								},
							reportCommonStats?.incomingGiftWaitingForApprovalAmount !== undefined &&
								reportCommonStats?.incomingGiftWaitingForApprovalAmount !== null && {
									name: t('pendingApproval'),
									value: '$' + reportCommonStats.incomingGiftWaitingForApprovalAmount,
									color: '#e09d00',
								},
							reportCommonStats?.incomingGiftTotalAmount !== undefined &&
								reportCommonStats?.incomingGiftTotalAmount !== null && {
									name: t('total'),
									value: '$' + reportCommonStats.incomingGiftTotalAmount,
									color: '#1392e5',
								},
					].filter(Boolean),
		},
		{
			name: t('outgoingGift'),
			id: 'gidenhediyeler',
			open: gidenhediyeler,
			content:
				pieceAndAmountFilter == 0
					? [
							reportCommonStats?.outgoingGiftApprovedCount !== undefined &&
								reportCommonStats?.outgoingGiftApprovedCount !== null && {
									name: t('approved'),
									value: reportCommonStats.outgoingGiftApprovedCount,
									color: '#08a52b',
								},
							reportCommonStats?.outgoingGiftRejectedCount !== undefined &&
								reportCommonStats?.outgoingGiftRejectedCount !== null && {
									name: t('rejected'),
									value: reportCommonStats.outgoingGiftRejectedCount,
									color: '#c1312f',
								},
							reportCommonStats?.outgoingGiftWaitingForApprovalCount !== undefined &&
								reportCommonStats?.outgoingGiftWaitingForApprovalCount !== null && {
									name: t('pendingApproval'),
									value: reportCommonStats.outgoingGiftWaitingForApprovalCount,
									color: '#e09d00',
								},
							reportCommonStats?.outgoingGiftTotalCount !== undefined &&
								reportCommonStats?.outgoingGiftTotalCount !== null && {
									name: t('total'),
									value: reportCommonStats.outgoingGiftTotalCount,
									color: '#1392e5',
								},
					].filter(Boolean)
					: [
							reportCommonStats?.outgoingGiftApprovedAmount !== undefined &&
								reportCommonStats?.outgoingGiftApprovedAmount !== null && {
									name: t('approved'),
									value: '$' + reportCommonStats.outgoingGiftApprovedAmount,
									color: '#08a52b',
								},
							reportCommonStats?.outgoingGiftRejectedAmount !== undefined &&
								reportCommonStats?.outgoingGiftRejectedAmount !== null && {
									name: t('rejected'),
									value: '$' + reportCommonStats.outgoingGiftRejectedAmount,
									color: '#c1312f',
								},
							reportCommonStats?.outgoingGiftWaitingForApprovalAmount !== undefined &&
								reportCommonStats?.outgoingGiftWaitingForApprovalAmount !== null && {
									name: t('pendingApproval'),
									value: '$' + reportCommonStats.outgoingGiftWaitingForApprovalAmount,
									color: '#e09d00',
								},
							reportCommonStats?.outgoingGiftTotalAmount !== undefined &&
								reportCommonStats?.outgoingGiftTotalAmount !== null && {
									name: t('total'),
									value: '$' + reportCommonStats.outgoingGiftTotalAmount,
									color: '#1392e5',
								},
					].filter(Boolean),
		},
		{
			name: t('companyHospitality'),
			id: 'sirketagirlama',
			open: sirketagirlama,
			content:
				pieceAndAmountFilter == 0
					? [
							reportCommonStats?.companyHospitalityApprovedCount !== undefined &&
								reportCommonStats?.companyHospitalityApprovedCount !== null && {
									name: t('approved'),
									value: reportCommonStats.companyHospitalityApprovedCount,
									color: '#08a52b',
								},
							reportCommonStats?.companyHospitalityRejectedCount !== undefined &&
								reportCommonStats?.companyHospitalityRejectedCount !== null && {
									name: t('rejected'),
									value: reportCommonStats.companyHospitalityRejectedCount,
									color: '#c1312f',
								},
							reportCommonStats?.companyHospitalityWaitingForApprovalCount !== undefined &&
								reportCommonStats?.companyHospitalityWaitingForApprovalCount !== null && {
									name: t('pendingApproval'),
									value: reportCommonStats.companyHospitalityWaitingForApprovalCount,
									color: '#e09d00',
								},
							reportCommonStats?.companyHospitalityTotalCount !== undefined &&
								reportCommonStats?.companyHospitalityTotalCount !== null && {
									name: t('total'),
									value: reportCommonStats.companyHospitalityTotalCount,
									color: '#1392e5',
								},
					].filter(Boolean)
					: [
							reportCommonStats?.companyHospitalityApprovedAmount !== undefined &&
								reportCommonStats?.companyHospitalityApprovedAmount !== null && {
									name: t('approved'),
									value: '$' + reportCommonStats.companyHospitalityApprovedAmount,
									color: '#08a52b',
								},
							reportCommonStats?.companyHospitalityRejectedAmount !== undefined &&
								reportCommonStats?.companyHospitalityRejectedAmount !== null && {
									name: t('rejected'),
									value: '$' + reportCommonStats.companyHospitalityRejectedAmount,
									color: '#c1312f',
								},
							reportCommonStats?.companyHospitalityWaitingForApprovalAmount !== undefined &&
								reportCommonStats?.companyHospitalityWaitingForApprovalAmount !== null && {
									name: t('pendingApproval'),
									value: '$' + reportCommonStats.companyHospitalityWaitingForApprovalAmount,
									color: '#e09d00',
								},
							reportCommonStats?.companyHospitalityTotalAmount !== undefined &&
								reportCommonStats?.companyHospitalityTotalAmount !== null && {
									name: t('total'),
									value: '$' + reportCommonStats.companyHospitalityTotalAmount,
									color: '#1392e5',
								},
					].filter(Boolean),
		},
		{
			name: t('todaysStats'),
			id: 'onaydabekleyenler',
			open: onaydabekleyenler,
			content:
				pieceAndAmountFilter == 0
					? [
							reportCommonStats?.todayIncomingGiftCount !== undefined &&
								reportCommonStats?.todayIncomingGiftCount !== null && {
									name: t('incomingGift'),
									id: 0,
									value: reportCommonStats.todayIncomingGiftCount,
									color: '#08a52b',
								},
							reportCommonStats?.todayOutgoingGiftCount !== undefined &&
								reportCommonStats?.todayOutgoingGiftCount !== null && {
									name: t('outgoingGift'),
									id: 1,
									value: reportCommonStats.todayOutgoingGiftCount,
									color: '#c1312f',
								},
							reportCommonStats?.todayCompanyHospitalityCount !== undefined &&
								reportCommonStats?.todayCompanyHospitalityCount !== null && {
									name: t('hospitality'),
									id: 2,
									value: reportCommonStats.todayCompanyHospitalityCount,
									color: '#e09d00',
								},
							// {
							//   name: t('total'),
							//   value: reportCommonStats?.todayCompanyHospitalityCount,
							//   color: '#1392e5'
							// },
					].filter(Boolean)
					: [
							reportCommonStats?.todayIncomingGiftAmount !== undefined &&
								reportCommonStats?.todayIncomingGiftAmount !== null && {
									name: t('incomingGift'),
									id: 0,
									value: '$' + reportCommonStats.todayIncomingGiftAmount,
									color: '#08a52b',
								},
							reportCommonStats?.todayOutgoingGiftAmount !== undefined &&
								reportCommonStats?.todayOutgoingGiftAmount !== null && {
									name: t('outgoingGift'),
									id: 1,
									value: '$' + reportCommonStats.todayOutgoingGiftAmount,
									color: '#c1312f',
								},
							reportCommonStats?.todayCompanyHospitalityAmount !== undefined &&
								reportCommonStats?.todayCompanyHospitalityAmount !== null && {
									name: t('hospitality'),
									id: 2,
									value: '$' + reportCommonStats.todayCompanyHospitalityAmount,
									color: '#e09d00',
								},
							// {
							//   name: t('total'),
							//   value: reportCommonStats?.todayCompanyHospitalityCount,
							//   color: '#1392e5'
							// },
					].filter(Boolean),
		},
	]
	return (
		<div className='home-cards'>
			{loading ? (
				<div className='home-cards-loading'>
					<Loading width='45px' height='45px' borderWidth='5px' />
				</div>
			) : (
				<div className='home-cards-container'>
					{cards.map((card, i) => (
						<Card key={i} switchOpen={switchOpen} card={{ ...card, index: i }} />
					))}
				</div>
			)}
		</div>
	)
}

Cards.propTypes = {
	switchOpen: PropTypes.boolean,
	setReportCommonStats: PropTypes.func,
	reportCommonStats: PropTypes.boolean,
	pieceAndAmountFilter: PropTypes.boolean,
}

export default Cards
