import Button from 'components/common/Button';
import Title from 'components/common/Title';
import React from 'react'
import { useParams } from 'react-router-dom'

const Error = () => {
  const {statusCode} = useParams();

  const errors = {
    401: "Uygulamaya erişebilmek için lütfen giriş yapınız.",
    403: "Erişmeye çalıştığınız sayfa için yetkiniz bulunmamaktadır.",
    404: "Belirtilen sayfa bulunamadı.",
    500: "Beklenmedik bir hata oluştu."
  }
  return (
    <div className='errorDesign'>
    {
        <div className='errorDesign-container'>
         <Title name={errors[statusCode] || errors[500]} color="rgb(19, 146, 229)" fontweight="700" size="24px"/>
         <Button height={'48px'} backgroundColor="#1392E5" color="white" name="Ana Sayfa" onclick={() => window.location.href = "/"}/>                
        </div>
    }
</div>
  )
}

export default Error