import images from 'assets/images/images'
import React, { useEffect, useState, useRef } from 'react'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { useTranslation } from 'react-i18next';
 
const Language = () => {
    const [openLanguage, setOpenLanguage] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState(localStorage.getItem('language') || 'tr');
    const { i18n } = useTranslation();
    const languages = [
        {
            id: 'tr',
            name: 'TR',
            img: images.turkey,
        },
        {
            id: 'en',
            name: 'EN',
            img: images.england,
        },
    ];
 
    const dropdownRef = useRef(null);
 
    useEffect(() => {
        localStorage.setItem('language', activeLanguage);
        i18n.changeLanguage(activeLanguage);
    }, [activeLanguage, i18n]);
 
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenLanguage(false);
            }
        };
 
        document.addEventListener('mousedown', handleClickOutside);
 
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
 
    const toggleLanguage = () => {
        setOpenLanguage(!openLanguage);
    };
 
    const languageChoice = (id) => {
        setActiveLanguage(id);
        setOpenLanguage(false);
        window.location.reload();
    };
 
    return (
        <div className='language'>
            <div onClick={toggleLanguage} className='language-activeChoise'>
                <img src={activeLanguage === 'tr' ? images.turkey : images.england} alt='' />
                <div className='cabin-bold'>{activeLanguage === 'tr' ? 'TR' : 'EN'}</div>
                {openLanguage ? <IoIosArrowUp style={{ cursor: 'pointer' }} /> : <IoIosArrowDown style={{ cursor: 'pointer' }} />}
            </div>
            {openLanguage && (
                <div ref={dropdownRef} className='language-content'>
                    {languages.map((language, i) => (
                        <div onClick={() => languageChoice(language.id)} className='language-content-cell' key={i}>
                            <img src={language.img} alt='' />
                            <div className='cabin-bold'>{language.name}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
 
export default Language;