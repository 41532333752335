/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import {
	getLocationGroup,
	getLocationGroupFilterParametersFunc,
	getLocationGroupSelectListFunc,
} from 'redux/features/dataManagement/adminPanel/locationGroupSlice'
import { FaRegEdit } from 'react-icons/fa'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import SelectDr from 'components/common/SelectDr'
import { permissionTypes } from 'types/permissionTypes'
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai'

const CustomSortIcon = () => {
	return <img src={images.filterico} alt='' style={{ margin: '0px' }} />
}

const LocationGroup = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.locationGroup)

	const { modalId, modalStatus, modalToggle } = modals
	const { locationGroup, locationGroupFilterParameters, getLocationGroupSelectList } = adminPanel

	const [locationGroupAddingCode, setLocationGroupAddingCode] = useState()
	const [locationGroupAddingName, setLocationGroupAddingName] = useState()
	const [locationGroupAddingParentName, setLocationGroupAddingParentName] = useState()
	const [forCompanyId, setForCompanyId] = useState()
	const [statusChecked, setStatusChecked] = useState(0)
	const [isManagerApprovalRequiredForHospitalityChecked, setIsManagerApprovalRequiredForHospitalityChecked] = useState(false)
	const [includeSubCompaniesChecked, setIncludeSubCompaniesChecked] = useState(false)
	const [canManagersApproveChecked, setCanManagersApproveChecked] = useState(false)
	const [includeSubCompaniesToLimitChecked, setIncludeSubCompaniesToLimitChecked] = useState(false)

	const [filters, setFilters] = useState({
		search: '',
		includeCompanySpecific: false,
		sortingOrderByVal: 'desc',
		sortingColumnName: '',
		page: 1,
	})

	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations

	const navigate = useNavigate()

	const handleRemove = (item) => {
		dispatch(
			openModal({
				open: true,
				type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'location-group' } },
			})
		)
	}
	const handleEdit = (item) => {
		request(`/company/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res?.data?.result,
								parentName: getLocationGroupSelectList?.find((getLocGroup) => getLocGroup?.name == res?.data?.result?.parentId)?.value,
								forCompanyName: getLocationGroupSelectList?.find((getLocGroup) => getLocGroup?.name == res?.data?.result?.forCompanyId)?.value,
								editItem: 'location-group',
							},
						},
					})
				)
			}
		})
	}

	console.log(locationGroup, "?Export=true'")

	const COLUMNS = [
		{
			label: t('companyCode'),
			renderCell: (item) => item.code,
			name: 'code',
		},
		{
			label: t('companyName'),
			renderCell: (item) => item.name,
			name: 'name',
		},
		{
			label: t('topInstitutionCode'),
			renderCell: (item) => item?.parentCode,
			name: 'parentCode',
		},
		{
			label: t('topInstitution'),
			renderCell: (item) => item?.parentName,
			name: 'parentName',
		},
		{
			label: t('forCompanyId'),
			renderCell: (item) => item?.forCompanyName,
			name: 'forCompanyName',
		},
		{
			label: t('external'),
			renderCell: (item) => (item.isExternal ? <AiOutlineCheckSquare color='green' size={27} /> : <AiOutlineCloseSquare size={27} color='#c1312f' />),
			name: 'isExternal',
		},
		{
			label: t('isManagerApprovalRequiredForHospitality'),
			renderCell: (item) =>
				item.isManagerApprovalRequiredForHospitality ? (
					<AiOutlineCheckSquare color='green' size={27} />
				) : (
					<AiOutlineCloseSquare size={27} color='#c1312f' />
				),
			name: 'isManagerApprovalRequiredForHospitality',
		},
		{
			label: t('includeSubCompanies'),
			renderCell: (item) =>
				item.includeSubCompanies ? <AiOutlineCheckSquare color='green' size={27} /> : <AiOutlineCloseSquare size={27} color='#c1312f' />,
			name: 'includeSubCompanies',
		},
		{
			label: t('includeSubCompaniesToLimit'),
			renderCell: (item) =>
				item.includeSubCompaniesToLimit ? <AiOutlineCheckSquare color='green' size={27} /> : <AiOutlineCloseSquare size={27} color='#c1312f' />,
			name: 'includeSubCompaniesToLimit',
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div className={`cabin-semibold ${item.status == 1 ? 'tableActiveStatus' : 'tablePassiveStatus'}`}>
						{item.status == 1 ? t('active') : t('passive')}
					</div>
					{currentUser?.permissions[permissionTypes.CompaniesUpsert] && (
						<div className='editBtn' onClick={(e) => e.stopPropagation()}>
							<FaRegEdit size={15} onClick={() => handleEdit(item)} />
							<div onClick={() => handleEdit(item)} className=''>
								{t('edit')}
							</div>
						</div>
					)}
					{currentUser?.permissions[permissionTypes.CompaniesDelete] && (
						<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
							<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
							<div onClick={() => handleRemove(item)} className=''>
								{t('delete')}
							</div>
						</div>
					)}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		setFilters((prev) => ({ ...prev, page: 1 }))
	}, [filters.search, filters.sortingColumnName, filters.sortingOrderByVal, filters.includeCompanySpecific])

	useEffect(() => {
		request(
			`/company?Page=${filters?.page}&IncludeCompanySpecific=${filters?.includeCompanySpecific == ""  ? false : filters?.includeCompanySpecific}&Search=${filters?.search}&OrderBy=${
				filters.sortingColumnName ? filters.sortingColumnName + ' ' + filters.sortingOrderByVal : ''
			}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getLocationGroupFilterParametersFunc(filterPrm))
				dispatch(getLocationGroup(res.data.result.items))
			}
		})
	}, [filters])

	useEffect(() => {
		request('/company/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(getLocationGroupSelectListFunc(res.data.result.items))
			}
		})
	}, [])

	let nodes = locationGroup

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request(
			`/company/export-excel?Page=${filters?.page}&IncludeCompanySpecific=${filters?.includeCompanySpecific == ""  ? false : filters?.includeCompanySpecific}&Search=${filters?.search}&OrderBy=${
				filters.sortingColumnName ? filters.sortingColumnName + ' ' + filters.sortingOrderByVal : ''
			}`,
			{
				method: 'GET',
			}
		).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('location-group.xlsx', res.data.result)
			}
		})
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	const locationGroupAddBtn = () => {
		request(`/company`, {
			method: 'POST',
			data: {
				name: locationGroupAddingName,
				code: locationGroupAddingCode,
				parentId: locationGroupAddingParentName?.value,
				forCompanyId: forCompanyId?.value,
				status: statusChecked,
				isManagerApprovalRequiredForHospitality: isManagerApprovalRequiredForHospitalityChecked,
				includeSubCompanies: includeSubCompaniesChecked,
				canManagersApprove: canManagersApproveChecked,
				includeSubCompaniesToLimit: includeSubCompaniesToLimitChecked,
			},
		}).then((res) => {
			if (res.data.ok) {
				request('/company', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getLocationGroupFilterParametersFunc(filterPrm))
						dispatch(getLocationGroup(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				setLocationGroupAddingCode()
				setLocationGroupAddingName()
				setLocationGroupAddingParentName()
				setForCompanyId()
				setIncludeSubCompanies(false)
				setIncludeSubCompaniesToLimit(false)
			}, 100)
		})
	}

	const goToPrevPage = () => {
		if (locationGroupFilterParameters?.pageIndex == 0) return
		setFilters((prev) => ({ ...prev, page: locationGroupFilterParameters?.pageIndex - 1 }))
	}

	const goToNextPage = () => {
		if (locationGroupFilterParameters?.pageIndex + 1 >= locationGroupFilterParameters?.totalPages) return
		setFilters((prev) => ({ ...prev, page: locationGroupFilterParameters?.pageIndex + 2 }))
	}

	const goToPage = (index) => {
		setFilters((prev) => ({ ...prev, page: index + 1 }))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: locationGroupFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters((prev) => ({ ...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == 'asc' ? 'desc' : 'asc' }))
	}

	console.log(locationGroupAddingParentName, 'locationGroupAddingParentName')

	return (
		<div className='panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.CompaniesUpsert] && (
						<Button
							onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'adminPanel' } }))}
							name={t('addCompanies')}
							img={images.plus}
							color='#fff'
							backgroundColor='#1e74ad'
							height='38px'
						/>
					)}
				</div>
			</div>

			<div className='tableCard'>
				<Table
					isLoading={loading}
					gridCSS='location-group'
					paginationAllFunctionsList={paginationAllFunctionsList}
					sortTable={sortTable}
					data={data}
					columns={COLUMNS}
					filterType={6}
					handleFilterChange={handleFilterChange}
					setFilters={setFilters}
				/>
			</div>

			{modalId == 3 && modalToggle && modalStatus == 'adminPanel' && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('addCompanies')} color='#242424' fontweight='bold' size='24px' />
						<div className='location-group-addingModal'>
							<Input
								value={locationGroupAddingCode}
								setValue={setLocationGroupAddingCode}
								labelColor='#292929'
								label={t('institutionCode')}
								placeholder=''
								type='text'
							/>
							<Input
								value={locationGroupAddingName}
								setValue={setLocationGroupAddingName}
								labelColor='#292929'
								label={t('companyName')}
								placeholder=''
								type='text'
							/>
							<div className='location-group-addingModal-select' style={{ marginTop: '20px' }}>
								{/* <Input labelColor='#292929' label={'Üst Kurum'} placeholder="" type='text' />  */}
								{/* { value: 'Onaylanmış', label: 'Onaylanmış' }, */}
								<SelectDr
									onChange={(value) => setLocationGroupAddingParentName(value ? value : '')}
									type={0}
									isLoading={loading}
									options={getLocationGroupSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('topInstitution')}
								/>
							</div>
							<div className='location-group-addingModal-select' style={{ marginTop: '20px' }}>
								{/* <Input labelColor='#292929' label={'Üst Kurum'} placeholder="" type='text' />  */}
								{/* { value: 'Onaylanmış', label: 'Onaylanmış' }, */}
								<SelectDr
									onChange={(value) => setForCompanyId(value ? value : '')}
									type={0}
									isLoading={loading}
									options={getLocationGroupSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={''}
									label={t('forCompanyId')}
								/>
							</div>
						</div>
						<div style={{ marginTop: '-5px' }} className='modal-type1-checkArea'>
							<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
							<div>{t('active')}</div>
						</div>
						<div style={{ marginTop: '-18px' }} className='modal-type1-checkArea'>
							<input onChange={(e) => setIsManagerApprovalRequiredForHospitalityChecked(e.target.checked)} type='checkbox' name='' id='' />
							<div>{t('isManagerApprovalRequiredForHospitality')}</div>
						</div>
						{!forCompanyId?.value && (
							<div style={{ marginTop: '-18px' }} className='modal-type1-checkArea'>
								<input onChange={(e) => setCanManagersApproveChecked(e.target.checked)} type='checkbox' name='' id='' />
								<div>{t('newPartiesApprovedByAdministrators')}</div>
							</div>
						)}

						{!locationGroupAddingParentName?.value && (
							<>
								<div style={{ marginTop: '-18px' }} className='modal-type1-checkArea'>
									<input onChange={(e) => setIncludeSubCompaniesChecked(e.target.checked)} type='checkbox' name='' id='' />
									<div>{t('includeSubCompanies')}</div>
								</div>
								<div style={{ marginTop: '-18px' }} className='modal-type1-checkArea'>
									<input onChange={(e) => setIncludeSubCompaniesToLimitChecked(e.target.checked)} type='checkbox' name='' id='' />
									<div>{t('includeSubCompaniesToLimit')}</div>
								</div>
							</>
						)}

						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={locationGroupAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default LocationGroup
