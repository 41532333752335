import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
      getNotification: (state, action) => {      
          state.notifications.notificationList = action.payload    
      },
      getNotificationFilterParametersFunc: (state, action) => {
          state.notifications.notificationListFilterParameters = action.payload   
      }
    },
  })
  
  export const { getNotification, getNotificationFilterParametersFunc } = notificationSlice.actions
  
  export default notificationSlice.reducer