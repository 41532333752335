/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import Title from 'components/common/Title'
import ColorContent from 'components/common/ColorContent'
// import CardResult from './CardResult'
import { useDispatch } from 'react-redux'
import { homePageCardFunc } from 'redux/features/general/generalSlice'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { CompactTable } from '@table-library/react-table-library/compact'
import { IoIosArrowUp } from 'react-icons/io'
import Button from 'components/common/Button'
import { useRequest } from 'hooks/useRequest'
import { useNavigate } from 'react-router-dom'

const Card = ({ card, switchOpen }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const { t, i18n } = useTranslation()
	const [request, loading] = useRequest()
	const [tableData, setTableData] = useState()
	const [usageIndex, setUsageIndex] = useState() // sayfada index^2 gereken yerlerde kullanılacak...

	const colorContentFunc = (childItem, i) => {
		let newIndex = i == 0 ? 1 : i == 1 ? 2 : i == 2 ? 0 : i
		setUsageIndex(i)
		if (card.index == 0 || card.index == 1) {
			request(`/report/latest-gift-transactions?Type=${card.index + 1}&${i < 3 && `ApprovalStatus=${newIndex}`}&OnlyOwnRecords=${switchOpen}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setTableData(res.data.result)
				}
			})
		} else if (card.index == 2) {
			request(`/report/latest-company-hospitalities?OnlyOwnRecords=${switchOpen}`, {
				method: 'GET',
			}).then((res) => {
				if (res.data.ok) {
					setTableData(res.data.result)
				}
			})
		} else {
			if (i == 0) {
				request(`/report/latest-gift-transactions?Type=1&OnlyOwnRecords=${switchOpen}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setTableData(res.data.result.sort((a, b) => b.createdDate - a.createdDate).slice(0, card.content[i].value))
					}
				})
			} else if (i == 1) {
				request(`/report/latest-gift-transactions?Type=2&OnlyOwnRecords=${switchOpen}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setTableData(res.data.result.sort((a, b) => b.createdDate - a.createdDate).slice(0, card.content[i].value))
					}
				})
			} else {
				request(`/report/latest-company-hospitalities?OnlyOwnRecords=${switchOpen}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setTableData(res.data.result.sort((a, b) => b.createdDate - a.createdDate).slice(0, card.content[i].value))
					}
				})
			}
		}
	}

	useEffect(() => {
		// let obj = {
		// 	name: card.id,
		// 	result: [
		// 		{
		// 			date: '12.04.2023',
		// 			sendingInstitution: 'Arçelik',
		// 			giftRecipient: 'Merve Şahin',
		// 			giftType: 'Evet',
		// 			giftReason: 'Çalışanlara verilen hediye ve ödül',
		// 		},
		// 		{
		// 			date: '12.04.2023',
		// 			sendingInstitution: 'Arçelik',
		// 			giftRecipient: 'Merve Şahin',
		// 			giftType: 'Evet',
		// 			giftReason: 'Çalışanlara verilen hediye ve ödül',
		// 		},
		// 		{
		// 			date: '12.04.2023',
		// 			sendingInstitution: 'Arçelik',
		// 			giftRecipient: 'Merve Şahin',
		// 			giftType: 'Evet',
		// 			giftReason: 'Çalışanlara verilen hediye ve ödül',
		// 		},
		// 		{
		// 			date: '12.04.2023',
		// 			sendingInstitution: 'Arçelik',
		// 			giftRecipient: 'Merve Şahin',
		// 			giftType: 'Evet',
		// 			giftReason: 'Çalışanlara verilen hediye ve ödül',
		// 		},
		// 		{
		// 			date: '12.04.2023',
		// 			sendingInstitution: 'Arçelik',
		// 			giftRecipient: 'Merve Şahin',
		// 			giftType: 'Evet',
		// 			giftReason: 'Çalışanlara verilen hediye ve ödül',
		// 		},
		// 	], //şimdilik statik veri basıyorum ....
		// }
		dispatch(
			homePageCardFunc({
				name: card.id,
				result: tableData,
			})
		)
	}, [tableData])

	let cardStyle = {
		height: card?.open?.length < 1 && '231px',
		// height: card?.open?.length < 1 && tableData?.length < 1 && '231px',
	}

	const theme = useTheme(getTheme())

	const columnSet = () => {
		switch (card.id) {
			case 'gelenhediyeler':
				return [
					{
						label: t('companyName'),
						renderCell: (item) => item.companyName,
						name: 'companyName',
					},
					{
						label: t('SendingInstitutionName'),
						renderCell: (item) => item.targetCompanyName,
						name: 'targetCompanyName',
					},
					{
						label: t('GiftRecipient'),
						renderCell: (item) => item.receiverName,
						name: 'receiverName',
					},
					{
						label: t('Amount'),
						renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
						name: 'eventPurposeName',
					},
				]
			case 'gidenhediyeler':
				return [
					{
						label: t('companyName'),
						renderCell: (item) => item.companyName,
						name: 'companyName',
					},
					{
						label: t('submittedInstitutionName'),
						renderCell: (item) => item.targetCompanyName,
						name: 'targetCompanyName',
					},
					{
						label: t('GiftRecipient'),
						renderCell: (item) => item.receiverName,
						name: 'receiverName',
					},
					{
						label: t('Amount'),
						renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
						name: 'eventPurposeName',
					},
				]
			case 'sirketagirlama':
				return [
					{
						label: t('companyName'),
						renderCell: (item) => item.companyName,
						name: 'companyName',
					},
					{
						label: t('Amount'),
						renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
						name: 'amount',
					},							
					{
						label: t('eventDate'),
						renderCell: (item) => new Date(item.eventDate).toLocaleDateString(i18n.language) ,
						name: 'eventDate',
					},
					{
						label: t('eventPurpose'),
						renderCell: (item) => item.eventPurposeName,
						name: 'eventPurposeName',
					},
				]
			case 'onaydabekleyenler': //onaydabekleyenler dedik ama sonradan yapısı degisti. (Güncelleme gelecek ...)
				switch (usageIndex) {
					case 0:
						return [
							{
								label: t('companyName'),
								renderCell: (item) => item.companyName,
								name: 'companyName',
							},
							{
								label: 'Gönderen Kurum Adı',
								renderCell: (item) => item.targetCompanyName,
								name: 'targetCompanyName',
							},
							{
								label: t('GiftRecipient'),
								renderCell: (item) => item.receiverName,
								name: 'receiverName',
							},
							{
								label: t('Amount'),
								renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
								name: 'eventPurposeName',
							},
						]
					case 1:
						return [
							{
								label: t('companyName'),
								renderCell: (item) => item.companyName,
								name: 'companyName',
							},
							{
								label: t('submittedInstitutionName'),
								renderCell: (item) => item.targetCompanyName,
								name: 'targetCompanyName',
							},
							{
								label: t('GiftRecipient'),
								renderCell: (item) => item.receiverName,
								name: 'receiverName',
							},
							{
								label: t('Amount'),
								renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
								name: 'eventPurposeName',
							},
						]
					case 2:
						return [
							{
								label: t('companyName'),
								renderCell: (item) => item.companyName,
								name: 'companyName',
							},
							{
								label: t('Amount'),
								renderCell: (item) => new Intl.NumberFormat(i18n.language, { style: 'currency', currency: item.currencyCode }).format(item.amount),
								name: 'amount',
							},							
							{
								label: t('eventDate'),
								renderCell: (item) => new Date(item.eventDate).toLocaleDateString(i18n.language) ,
								name: 'eventDate',
							},
							{
								label: t('eventPurpose'),
								renderCell: (item) => item.eventPurposeName,
								name: 'eventPurposeName',
							},
						]
					default:
						return []
				}
			default:
				return []
		}
	}

	let COLUMNS = columnSet()

	let nodes = card?.open
	let data = { nodes }


	const seeAllFunc = () => {
		if(card.id == "gelenhediyeler"){
			//window.location.href = "/incoming-gift"
			navigate('/incoming-gift', { state: { filterParams: usageIndex } });
		}else if(card.id == "gidenhediyeler"){
			//window.location.href = "/outgoing-gift"
			navigate('/outgoing-gift', { state: { filterParams: usageIndex } });
		}else if(card.id == "sirketagirlama"){
			//window.location.href = "/company-hospitality"
			navigate('/company-hospitality', { state: { filterParams: usageIndex } });
		}
	}

	return (
		<div style={cardStyle} className='home-card'>
			<Title name={card.name} color='#292929' fontweight='bold' size='24px' />
			<div className={`${card.content.length > 1 ? 'home-card-bottom-between' : 'home-card-bottom-center'}`}>
				{card?.content?.map((cd, i) => (
					<ColorContent onclick={() => colorContentFunc(cd, i)} key={i} name={cd?.name} value={cd.value} color={cd.color} />
				))}
			</div>
			{card?.open?.length > 0 && (
				<div className='home-card-openBottom'>
					<CompactTable layout={{ horizontalScroll: true }} columns={COLUMNS} data={data} theme={theme} />
					<div className='home-card-openBottom-buttonGroup'>
						<div className='home-card-openBottom-buttonGroup-icon'>
							<div
								onClick={() => dispatch(homePageCardFunc({ name: card.id, result: [] }))}
								className='home-card-openBottom-buttonGroup-icon-clicked'
							>
								<IoIosArrowUp size={24} />
								<div className='cabin-bold'>{t('closeDetails')}</div>
							</div>
						</div>
						{card.id !== "onaydabekleyenler" && <Button onclick={seeAllFunc} name={t('seeAll')} color='#fff' backgroundColor='#1392e5' height='38px' />}
					</div>
				</div>
				// <CardResult key={i} cd={cd}/>
			)}
		</div>
	)
}

Card.propTypes = {
	card: PropTypes.object,
	switchOpen: PropTypes.boolean
}

export default Card
