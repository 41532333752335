/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { FaRegEdit } from "react-icons/fa";
import { getNotification, getNotificationFilterParametersFunc } from 'redux/features/dataManagement/notifications/notificationSlice'
import { IoCheckmarkDoneSharp,IoCheckmarkSharp} from "react-icons/io5";
import { getMailTemplate, getMailTemplateFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailTemplateSlice'
import { permissionTypes } from 'types/permissionTypes'



const MailTemplate = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
  const { adminPanel } = useSelector((state) => state.mailTemplate)


	const { modalId, modalStatus, modalToggle } = modals
  const { mailTemplate, mailTemplateFilterParameters } = adminPanel


  const {permissions} = useSelector(state => state.configuration)
	const {currentUser} = permissions.configurations

	const [sortingOrderByVal, setSortingOrderByVal] = useState('asc')

	const navigate = useNavigate()

	const handleEdit = (item) => {
		request(`/email-template/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
        console.log(res.data.result, "res");
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res.data.result,
								editItem: 'mailTemplate',
							},
						},
					})
				)
			}
		})
	}
	
	const COLUMNS = [
		{
			label: t('name'),
			renderCell: (item) => item.name,
			name: 'name',
		},
		{
			label: t('subject'),
			renderCell: (item) => item.subject,
			name: 'subject',
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div 
					className={`cabin-semibold ${
						item.status == 1
							? 'tableActiveStatus'
							: 'tablePassiveStatus'
					}`}
					>{item.status == 1 ? t('active') : t('passive')}</div>
					{currentUser?.permissions[permissionTypes.EmailTemplatesUpdate] && (
						<div className='editBtn' onClick={(e) => e.stopPropagation()}>
							<FaRegEdit size={15} onClick={() => handleEdit(item)} />
							<div onClick={() => handleEdit(item)} className=''>
							{t('edit')}
							</div>
						</div>
					)}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		request('/email-template', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getMailTemplateFilterParametersFunc(filterPrm))
				dispatch(getMailTemplate(res.data.result.items))
			}
		})
	}, [])


	let nodes = mailTemplate

	let data = { nodes }


	const goToPrevPage = () => {
		if (mailTemplateFilterParameters?.pageIndex == 0) return
		request(`/email-template?Page=${mailTemplateFilterParameters?.pageIndex - 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getMailTemplateFilterParametersFunc(filterPrm))
				dispatch(getMailTemplate(res.data.result.items))
			}
		})
	}
	const goToNextPage = () => {
		if (mailTemplateFilterParameters?.pageIndex + 1 >= mailTemplateFilterParameters?.totalPages) return
		request(`/email-template?Page=${mailTemplateFilterParameters?.pageIndex + 2}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getMailTemplateFilterParametersFunc(filterPrm))
				dispatch(getMailTemplate(res.data.result.items))
			}
		})
	}

	const goToPage = (index) => {
		request(`/email-template?Page=${index + 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getMailTemplateFilterParametersFunc(filterPrm))
				dispatch(getMailTemplate(res.data.result.items))
			}
		})
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setSortingOrderByVal(sortingOrderByVal == 'asc' ? 'desc' : 'asc')
		request(`/email-template?OrderBy=${column.name} ${sortingOrderByVal}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getMailTemplateFilterParametersFunc(filterPrm))
				dispatch(getMailTemplate(res.data.result.items))
			}
		})
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: mailTemplateFilterParameters,
	}

	return (
		<div className='panel-table location-group incoming-gift'>
		     	{/* <Title name={"Bildirimler"} color='#242424' fontweight='bold' size='24px' /> */}
				<div className='tableCard'>
					<Table
					  gridCSS="mailTemplate"
						deleteBtn={true}
						paginationAllFunctionsList={paginationAllFunctionsList}
						data={data}
						columns={COLUMNS}
						filterType={'none'}
						sortTable={sortTable}
						isLoading = {loading}
					/>
				</div>

		</div>
	)
}

export default MailTemplate
