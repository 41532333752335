import Button from "components/common/Button"
import Input from "components/common/Input"
import Title from "components/common/Title"
import { useRequest } from "hooks/useRequest"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

const InternalLogin = () => {
  const { t } = useTranslation()
  const [request] = useRequest()
  const [loginData, setLoginData] = useState({username: "", password: ""})
 
  // const {permissions} = useSelector(state => state.configuration)
  // const {configurations} = permissions
  

  const loginFunc = () => {
    request(`/auth/internal-login`, {
      method: 'POST',
      data: {
        ...loginData
      },
    }).then((res) => {
         if(res.data.ok){
            localStorage.setItem('auth', JSON.stringify(res.data.result.token)) 
            
            toast.success('Giriş İşlemi Başarılı')
            setTimeout(() => {
              window.location.href = "/" 
            }, 0);
         }
    })
  }
  

  return (
    <div className="internal-login">
       <div className="internal-login-container">
          <Title name={t('giftCardEntryScreen')} color='#139ce7' size='30px' fontweight="bold" />
          <Input name="username" multipleState={true} value={loginData.username} setValue={setLoginData} labelColor="#292929" label={""} placeholder={t('username')} type="text"/>
          <Input name="password" multipleState={true} value={loginData.password} setValue={setLoginData} labelColor="#292929" label={""} placeholder={t('password')} type="text"/>
          <Button onclick={loginFunc} name={t('signin')}  color='#fff' backgroundColor='#1392e5' height='52px' />
       </div>
    </div>
  )
}

export default InternalLogin