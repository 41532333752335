/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import {
	getLocationGroup,
	getLocationGroupFilterParametersFunc,
	getLocationGroupSelectListFunc,
} from 'redux/features/dataManagement/adminPanel/locationGroupSlice'
import { FaChevronDown, FaChevronRight, FaRegEdit } from 'react-icons/fa'
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdOutlineDeleteSweep } from 'react-icons/md'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import SelectDr from 'components/common/SelectDr'
import { getRoleManagement, getRoleManagementFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/roleManagementSlice'
import { getPermissions } from 'redux/features/permissions/permission/permissionSlice'
import CheckboxTree from 'react-checkbox-tree'
import { RiCheckboxMultipleFill } from 'react-icons/ri'
import { permissionTypes } from 'types/permissionTypes'
import { AiOutlineCheckSquare, AiOutlineCloseSquare  } from 'react-icons/ai'

const RoleManagement = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.roleManagement)
	const { permissions } = useSelector((state) => state.permission)

	const { configurations } = useSelector((state) => state.configuration.permissions)
	const { currentUser } = configurations

	const { modalId, modalStatus, modalToggle } = modals
	const { roleManagement, roleManagementFilterParameters } = adminPanel
	const { permissionList } = permissions

	const [roleManagementAddingName, setRoleManagementAddingName] = useState()
	const [roleManagementPermissions, setRoleManagementPermissions] = useState([])
	const [isDefaultChecked, setIsDefaultChecked] = useState(false)
	const [isDefaultForManagerChecked, setIsDefaultForManagerChecked] = useState(false)
	// const [isStaticChecked, setIsStaticChecked] = useState(false)

	const [sortingOrderByVal, setSortingOrderByVal] = useState('asc')

	const [tabModal, setTabModal] = useState(0)

	const [treeChecked, setTreeChecked] = useState([])
	const [treeExpanded, setTreeExpanded] = useState([])

	const navigate = useNavigate()

	const mapTreeNodes = (items) => {
		return items?.map((prev) => ({
			value: prev.name,
			label: prev.displayName,
			children: prev?.children?.length > 0 ? mapTreeNodes(prev.children) : null,
		}))
	}

	let treeNodes = mapTreeNodes(permissionList)


	const handleRemove = (item) => {
		dispatch(
			openModal({
				open: true,
				type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'roleManagement' } },
			})
		)
	}
	const handleEdit = (item) => {
		request(`/identity/role/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res?.data?.result,
								treeNodes: treeNodes,
								treeChecked: treeChecked,
								treeExpanded: treeExpanded,
								editItem: 'roleManagement',
							},
						},
					})
				)
			}
		})
	}

	const COLUMNS = [
		{
			label: t('roleName'),
			renderCell: (item) => item.name,
			name: 'name',
		},
		{
			label: t('default'),
			renderCell: (item) =>
				item.isDefault ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
			name: 'isDefault',
		},
		{
			label: t('defaultAdministratorRole'),
			renderCell: (item) =>
				item.isDefaultForManager ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
			name: 'isDefaultForManager',
		},
		{
			label: t('systemRole'),
			renderCell: (item) =>
				item?.isStatic ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
			name: 'isStatic',
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					{/* <div 
					className={`cabin-semibold ${
						item.status == 1
							? 'tableActiveStatus'
							: 'tablePassiveStatus'
					}`}
					>{item.status == 1 ? "Aktif" : "Pasif"}</div> */}
					{currentUser?.permissions[permissionTypes.RolesUpsert] && (
						<div className='editBtn' onClick={(e) => e.stopPropagation()}>
							<FaRegEdit size={15} onClick={() => handleEdit(item)} />
							<div onClick={() => handleEdit(item)} className=''>
							{t('edit')}
							</div>
						</div>
					)}
					{currentUser?.permissions[permissionTypes.RolesDelete] && (
						<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
							<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
							<div onClick={() => handleRemove(item)} className=''>
							{t('delete')}
							</div>
						</div>
					)}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		setRoleManagementPermissions(treeChecked)
	}, [treeChecked])

	useEffect(() => {
		request('/identity/role', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getRoleManagementFilterParametersFunc(filterPrm))
				dispatch(getRoleManagement(res.data.result.items))
			}
		})

		request('/identity/role/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(getLocationGroupSelectListFunc(res.data.result.items))
			}
		})

		request('/permission', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(getPermissions(res.data.result))
			}
		})
	}, [])

	let nodes = roleManagement

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request('/identity/role/export-excel', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('role-management.xlsx', res.data.result)
			}
		})
	}

	const roleManagementAddBtn = () => {
		request(`/identity/role`, {
			method: 'POST',
			data: {
				name: roleManagementAddingName,
				//isStatic: isStaticChecked,
				isDefault: isDefaultChecked,
				isDefaultForManager: isDefaultForManagerChecked,
				permissions: roleManagementPermissions,
			},
		}).then((res) => {
			if (res.data.ok) {
				request('/identity/role', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getRoleManagementFilterParametersFunc(filterPrm))
						dispatch(getRoleManagement(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				setTabModal(0)
				setIsDefaultChecked(false)
				setIsDefaultForManagerChecked(false)
				//setIsStaticChecked(false)
				setRoleManagementAddingName()
				setRoleManagementPermissions([])
				setTreeChecked([])
				setTreeExpanded([])
			}, 100)
		})
	}

	const goToPrevPage = () => {
		if (roleManagementFilterParameters?.pageIndex == 0) return
		request(`/identity/role?Page=${roleManagementFilterParameters?.pageIndex - 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getRoleManagementFilterParametersFunc(filterPrm))
				dispatch(getRoleManagement(res.data.result.items))
			}
		})
	}

	const goToNextPage = () => {
		if (roleManagementFilterParameters?.pageIndex + 1 >= roleManagementFilterParameters?.totalPages) return
		request(`/identity/role?Page=${roleManagementFilterParameters?.pageIndex + 2}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getRoleManagementFilterParametersFunc(filterPrm))
				dispatch(getRoleManagement(res.data.result.items))
			}
		})
	}

	const goToPage = (index) => {
		request(`/identity/role?Page=${index + 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getRoleManagementFilterParametersFunc(filterPrm))
				dispatch(getRoleManagement(res.data.result.items))
			}
		})
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: roleManagementFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setSortingOrderByVal(sortingOrderByVal == 'asc' ? 'desc' : 'asc')
		request(`/identity/role/?OrderBy=${column.name} ${sortingOrderByVal}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getRoleManagementFilterParametersFunc(filterPrm))
				dispatch(getRoleManagement(res.data.result.items))
			}
		})
	}

	const roleManagementCancelBtn = () => {
		setTabModal(0)
		setIsDefaultChecked(false)
		setIsDefaultForManagerChecked(false)
		//setIsStaticChecked(false)
		setRoleManagementAddingName()
		setRoleManagementPermissions([])
		setTreeChecked([])
		setTreeExpanded([])
		dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
	}

	return (
		<div className='panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.RolesUpsert] && (
						<Button
							onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'roleManagement' } }))}
							name={t('addRoleManagement')}
							img={images.plus}
							color='#fff'
							backgroundColor='#1e74ad'
							height='38px'
						/>
					)}
				</div>
			</div>

			<div className='tableCard'>
				<Table
					isLoading={loading}
					gridCSS='roleManagement'
					paginationAllFunctionsList={paginationAllFunctionsList}
					sortTable={sortTable}
					data={data}
					columns={COLUMNS}
					filterType={'none'}
				/>
			</div>

			{modalId == 3 && modalToggle && modalStatus == 'roleManagement' && (
				<Modal>
					<div className='tab-modal'>
						<div className='tab-modal-lgTitle'>
							<Title name={t('addRole')} color='#242424' fontweight='bold' size='24px' />
						</div>
						<div className='tab-modal-titles'>
							<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
							{t('role')}
							</div>
							<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
							{t('permits')}
							</div>
						</div>
						{tabModal == 0 ? (
							<div className='tab-modal-tab1'>
								<div className='tab-modal-tab1-inputArea'>
									<Input value={roleManagementAddingName} setValue={setRoleManagementAddingName} labelColor='#292929' label={t('roleName')} type='text' />
								</div>

								<div style={{ marginTop: '20px' }} className='tab-modal-tab1-checkArea'>
									<input onChange={(e) => setIsDefaultChecked(e.target.checked ? true : false)} type='checkbox' name='' id='' />
									<div>{t('default')}</div>
								</div>
								<div style={{ marginTop: '10px' }} className='tab-modal-tab1-checkArea'>
									<input onChange={(e) => setIsDefaultForManagerChecked(e.target.checked ? true : false)} type='checkbox' name='' id='' />
									<div>{t('defaultAdministratorRole')}</div>
								</div>
								{/* <div className='tab-modal-tab1-checkArea'>
									<input onChange={(e) => setIsStaticChecked(e.target.checked ? true : false)} type='checkbox' name='' id='' />
									<div>{t('fixed')}</div>
								</div> */}
							</div>
						) : (
							<div className='tab-modal-tab2'>
								<CheckboxTree
									nodes={treeNodes}
									noCascade={true}
									checked={treeChecked}
									expanded={treeExpanded}
									onCheck={(checked) => setTreeChecked(checked)}
									onExpand={(expanded) => setTreeExpanded(expanded)}
									//iconsClass="fa5"
									icons={{
										check: <MdOutlineCheckBox size={24} />,
										uncheck: <MdOutlineCheckBoxOutlineBlank size={24} />,
										leaf: <RiCheckboxMultipleFill />,
										expandClose: <FaChevronRight />,
										expandOpen: <FaChevronDown />,
									}}
									//expandIconRenderer={renderExpandIcon}
								/>
							</div>
						)}
						<div className='tab-modal-modalGroup'>
							<Button onclick={roleManagementCancelBtn} name={t('cancel')} color='#fff' backgroundColor='#c1312f' height='48px' />
							<Button onclick={roleManagementAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default RoleManagement
