import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  export const draftSlice = createSlice({
    name: 'draft',
    initialState,
    reducers: {
      getDraft: (state, action) => {      
          state.draftObj.draft = action.payload    
      },
      getDraftFilterParametersFunc: (state, action) => {
          state.draftObj.draftFilterParameters = action.payload   
      }
    },
  })
  
  export const { getDraft, getDraftFilterParametersFunc } = draftSlice.actions
  
  export default draftSlice.reducer