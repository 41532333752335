import { PropTypes } from 'prop-types'
const Modal = ({ children, customModal }) => {
	return (
		<div className={`modal ${customModal ? customModal : ""}`}>
			<div className={`modal-container`}>
				{children}
			</div>
		</div>
	)
}

Modal.propTypes = {
	children: PropTypes.node,
	customModal: PropTypes.string
}

export default Modal
