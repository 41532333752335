import React from 'react'
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const styleFooter = {
    fontSize: "12px",
    display: 'flex',
    justifyContent: 'center',
    color: '#1392e5',
    alignItems: "center", 
    //marginTop : "16px",
    height: "40px",
    backgroundColor: "#eff0f3"
  }
  return (
    <div style={styleFooter} className='cabin-bold'>{t('giftandHospitalitySystem')}</div>
  )
}

export default Footer