import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  export const eventLogSlice = createSlice({
    name: 'eventLog',
    initialState,
    reducers: {
      getEventLog: (state, action) => {      
          state.adminPanel.eventLog = action.payload    
      },
      getEventLogFilterParametersFunc: (state, action) => {
          state.adminPanel.eventLogFilterParameters = action.payload   
      }
    },
  })
  
  export const { getEventLog, getEventLogFilterParametersFunc } = eventLogSlice.actions
  
  export default eventLogSlice.reducer