import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const locationGroupSlice = createSlice({
    name: 'locationGroup',
    initialState,
    reducers: {
      getLocationGroup: (state, action) => {      
          state.adminPanel.locationGroup = action.payload    
      },
      getLocationGroupFilterParametersFunc: (state, action) => {
          state.adminPanel.locationGroupFilterParameters = action.payload   
      },
      getLocationGroupSelectListFunc: (state, action) => {
        state.adminPanel.getLocationGroupSelectList = action.payload   
    }
    },
  })
  
  export const { getLocationGroup, getLocationGroupFilterParametersFunc, getLocationGroupSelectListFunc } = locationGroupSlice.actions
  
  export default locationGroupSlice.reducer