import { configureStore } from '@reduxjs/toolkit'
import  generalSlice  from './features/general/generalSlice';
import  giftTypeSlice  from './features/dataManagement/adminPanel/giftTypeSlice';
import giftPurposeSlice from './features/dataManagement/adminPanel/giftPurposeSlice';
import relationPartyTypeSlice from './features/dataManagement/adminPanel/relationPartyTypeSlice';
import reasonsForRefusalSlice from './features/dataManagement/adminPanel/reasonsForRefusalSlice';
import locationGroupSlice from './features/dataManagement/adminPanel/locationGroupSlice';
import configurationSlice from './features/permissions/configuration/configurationSlice';
import currencySlice from './features/dataManagement/adminPanel/currencySlice';
import userManagementSlice from './features/dataManagement/adminPanel/userManagementSlice';
import roleManagementSlice from './features/dataManagement/adminPanel/roleManagementSlice';
import permissionSlice from './features/permissions/permission/permissionSlice';
import giftLimitSlice from './features/dataManagement/adminPanel/giftLimitSlice';
import companyHospitalitySlice from './features/dataManagement/companyHospitality/companyHospitalitySlice';
import incomingGiftSlice from './features/dataManagement/incomingGift/incomingGiftSlice';
import outgoingGiftSlice from './features/dataManagement/outgoingGift/outgoingGiftSlice';
import notificationSlice from './features/dataManagement/notifications/notificationSlice';
import mailTemplateSlice from './features/dataManagement/adminPanel/mailTemplateSlice';
import mailLogSlice from './features/dataManagement/adminPanel/mailLogSlice';
import eventLogSlice from './features/dataManagement/adminPanel/eventLogSlice';
import auditLogSlice from './features/dataManagement/adminPanel/auditLogSlice';
import draftSlice from './features/dataManagement/draft/draftSlice';

export const store = configureStore({
  reducer: {
    general : generalSlice,
    giftType: giftTypeSlice,
    giftLimit: giftLimitSlice,
    giftPurpose: giftPurposeSlice,
    relationPartyType: relationPartyTypeSlice,
    reasonsForRefusal: reasonsForRefusalSlice,
    locationGroup: locationGroupSlice,
    configuration: configurationSlice,
    permission: permissionSlice,
    currency: currencySlice,
    userManagement: userManagementSlice,
    roleManagement: roleManagementSlice,
    companyHospitality: companyHospitalitySlice,
    draft: draftSlice,
    incomingGift: incomingGiftSlice,
    outgoingGift: outgoingGiftSlice,
    notification: notificationSlice,
    mailTemplate: mailTemplateSlice,
    mailLog: mailLogSlice,
    eventLog: eventLogSlice,
    auditLog: auditLogSlice
  },
})