/*eslint-disable*/
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Loading from 'components/common/Loading'
import Title from 'components/common/Title'
import Table from 'components/common/table/Table'
import Modal from 'containers/modal/Modal'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCheckSquare, AiOutlineCloseSquare  } from 'react-icons/ai'
import { FaRegEdit } from 'react-icons/fa'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCurrencies, getCurrenyFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/currencySlice'
import { openModal } from 'redux/features/general/generalSlice'
import { permissionTypes } from 'types/permissionTypes'
import { downloadFileFromStream } from 'utils/ExcelOutput'

const Currency = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.currency)

	const { modalId, modalStatus, modalToggle } = modals
	const { currencies, currencyFilterParameters } = adminPanel

	const [currencyAddingName, setCurrencyAddingName] = useState()
	const [currencyAddingCode, setCurrencyAddingCode] = useState()
	const [currencyAddingUnit, setCurrencyAddingUnit] = useState()
	const [statusChecked, setStatusChecked] = useState(0)
	// const [fixedChecked, setFixedChecked] = useState(0)

	const [sortingOrderByVal, setSortingOrderByVal] = useState('asc')

	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations

	const navigate = useNavigate()

	const handleRemove = (item) => {
		dispatch(
			openModal({ open: true, type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'currency' } } })
		)
	}
	const handleEdit = (item) => {
		request(`/currency/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res.data.result,
								editItem: 'currency',
							},
						},
					})
				)
			}
		})
	}

	const COLUMNS = [
		{
			label: t('name'),
			renderCell: (item) => item.name,
			name: 'name',
		},
		{
			label: t('code'),
			renderCell: (item) => item.code,
			name: 'code',
		},
		{
			label: t('symbol'),
			renderCell: (item) => item.symbol,
			name: 'symbol',
		},
		{
			label: t('unit'),
			renderCell: (item) => item.unit,
			name: 'unit',
		},
		{
			label: t('default'),
			renderCell: (item) => (item.isDefault ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>),
			name: 'isDefault',
		},
		// {
		// 	label: t('fixed'),
		// 	renderCell: (item) => (item.isFixed ? <input type="checkbox" checked  className='tableDisCheckbox'/>: <input type="checkbox"  className='tableDisCheckbox'/>),
		// 	name: 'isFixed',
		// },
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div className={`cabin-semibold ${item.status == 1 ? 'tableActiveStatus' : 'tablePassiveStatus'}`}>
						{item.status == 1 ? t('active') : t('passive')}
					</div>
					{currentUser?.permissions[permissionTypes.CurrenciesUpsert] && <div className='editBtn' onClick={(e) => e.stopPropagation()}>
						<FaRegEdit size={15} onClick={() => handleEdit(item)} />
						<div onClick={() => handleEdit(item)} className=''>
						{t('edit')}
						</div>
					</div>}
					{currentUser?.permissions[permissionTypes.CurrenciesDelete] && <div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
						<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
						<div onClick={() => handleRemove(item)} className=''>
						{t('delete')}
						</div>
					</div>}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		// ?PageSize=15
		request('/currency', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getCurrenyFilterParametersFunc(filterPrm))
				dispatch(getCurrencies(res.data.result.items))
			}
		})
	}, [])

	console.log(currencies, "currencies");

	let nodes = currencies

	let data = { nodes }

	const handleDownloadXlsx = () => {
		request('/currency/export-excel', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('currency.xlsx', res.data.result)
			}
		})
	}

	const currencyAddBtn = () => {
			request(`/currency`, {
				method: 'POST',
				data: {
					name: currencyAddingName,
					code: currencyAddingCode,
					unit: currencyAddingUnit,
					// symbol: null,
                    // isDefault: true,
					status: statusChecked,
                    //isFixed: fixedChecked
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/currency', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getCurrenyFilterParametersFunc(filterPrm))
							dispatch(getCurrencies(res.data.result.items))
						}
					})
				}
				toast.success('Ekleme işlemi başarılı')
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
                    setCurrencyAddingName()
                    setCurrencyAddingCode()
                    setCurrencyAddingUnit()
				}, 100)
			})
	}

	const goToPrevPage = () => {
		if (currencyFilterParameters?.pageIndex == 0) return
		request(`/currency?Page=${currencyFilterParameters?.pageIndex - 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getCurrenyFilterParametersFunc(filterPrm))
				dispatch(getCurrencies(res.data.result.items))
			}
		})
	}
	const goToNextPage = () => {
		if (currencyFilterParameters?.pageIndex + 1 >= currencyFilterParameters?.totalPages) return
		request(`/currency?Page=${currencyFilterParameters?.pageIndex + 2}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getCurrenyFilterParametersFunc(filterPrm))
				dispatch(getCurrencies(res.data.result.items))
			}
		})
	}

	const goToPage = (index) => {
		request(`/currency?Page=${index + 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getCurrenyFilterParametersFunc(filterPrm))
				dispatch(getCurrencies(res.data.result.items))
			}
		})
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: currencyFilterParameters,
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setSortingOrderByVal(sortingOrderByVal == 'asc' ? 'desc' : 'asc')
		request(`/currency?OrderBy=${column.name} ${sortingOrderByVal}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getCurrenyFilterParametersFunc(filterPrm))
				dispatch(getCurrencies(res.data.result.items))
			}
		})
	}

	return (
		<div className='currency panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.CurrenciesUpsert] && <Button
						onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'currency' } }))}
						name={t('addCurrency')}
						img={images.plus}
						color='#fff'
						backgroundColor='#1e74ad'
						height='38px'
					/>}
				</div>
			</div>
			 
				<div className='tableCard'>
					<Table
						gridCSS='currency'
						deleteBtn={true}
						sortTable={sortTable}
						paginationAllFunctionsList={paginationAllFunctionsList}
						data={data}
						columns={COLUMNS}
						filterType={'none'}
						isLoading = {loading}
					/>
				</div>
			

			{modalId == 3 && modalToggle && modalStatus == 'currency' && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('addCurrency')} color='#242424' fontweight='bold' size='24px' />
						{/* {
                            "id": 0, + 
                            "status": 0, +
                            "name": "string",
                            "code": "string",
                            "symbol": "string",
                            "rate": 0,
                            "isDefault": true,
                            "isFixed": true
                        } */}
                        {/* Code = Id > 0 ise readonly
                        Symbol = readonly
                        IsDefault = readonly
                        IsFixed = IsDefault ise readonly
                        Rate = Id > 0 ise ve (isFixed değilse veya IsDefault ise) readonly */}
						<div className='location-group-addingModal'>
							<Input
								value={currencyAddingName}
								setValue={setCurrencyAddingName}
								labelColor='#292929'
								label={t('name')}
								type='text'
							/>
						</div>
						<div style={{ marginTop: '-25px' }} className='location-group-addingModal'>
							<Input
								value={currencyAddingCode}
								setValue={setCurrencyAddingCode}
								labelColor='#292929'
								label={t('code')}
								type='text'
							/>
							<Input
								//value={giftTypeAddingDescription}
								//setValue={setGiftTypeAddingDescription}
								labelColor='#292929'
								label={t('symbol')}
                                readOnly
								type='text'
							/>
						</div>
                        <div style={{ marginTop: '-8px' }} className='location-group-addingModal'>
							<Input
								value={currencyAddingUnit}
								setValue={setCurrencyAddingUnit}
								labelColor='#292929'
								label={t('unit')}
								type='number'
							/>
						</div>

						<div className='modal-type1-checkArea'>
							<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
							<div>{t('active')}</div>
						</div>
                        <div className='modal-type1-checkArea'>
							<input disabled type='checkbox' name='' id='' />
							<div>{t('default')}</div>
						</div>
                        {/* <div className='modal-type1-checkArea'>
							<input onChange={(e) => setFixedChecked(e.target.checked)} type='checkbox' name='' id='' />
							<div>{t('fixed')}</div>
						</div> */}
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={currencyAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default Currency
