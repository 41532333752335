import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';

  export const permissionSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
      getPermissions: (state, action) => {      
          state.permissions.permissionList = action.payload    
      },
    }
  })
  
  export const { getPermissions} = permissionSlice.actions
  
  export default permissionSlice.reducer