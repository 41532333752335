import React from 'react'
import { PropTypes } from 'prop-types'

const ColorContent = ({onclick, name, value, color}) => {
  const styleColorContent = {
     color: color
  }
  return (
    <div style={styleColorContent} className='colorContent'>
          <div className='cabin-bold colorContent-name'>{name}</div>
          <a onClick={onclick} className='cabin-bold colorContent-value'>{value}</a>
    </div>
  )
}


ColorContent.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	color: PropTypes.string,
	onclick: PropTypes.func,
}

export default ColorContent