import images from 'assets/images/images';
import React, { useEffect, useState } from 'react'
import NotificationCard from './NotificationCard';
import Button from 'components/common/Button';
import { useRequest } from 'hooks/useRequest';
import { useTranslation } from 'react-i18next';

const Notification = () => {
  const { t } = useTranslation()
    const [openNotification, setOpenNotification] = useState(false);
    const [request] = useRequest();
    const [notifications, setNotifications] = useState();
    const [notificationStatus, setNotificationStatus] = useState();
    const [openUrlStatus, setOpenUrlStatus] = useState({
      index: null,
      open: false,
      url: null
    });

    useEffect(() => {
       document.addEventListener('click', handleClickOutside)

       return () => {
         document.removeEventListener('click', handleClickOutside)
       }
    }, [])

    const handleClickOutside = (e) => {
         if(!e.target.closest('.notification')){
            setOpenNotification(false)
         }
    }

    const getNotificationFunc = () => {
      request(`/notification`, {
        method: 'GET',
      }).then((res) => {
        if (res.data.ok) {      
          setNotificationStatus(res.data.result.unreadCount > 0)
          setNotifications(res.data.result.items.slice(0,5))
        }
      })
    }

    useEffect(() => {
      getNotificationFunc()
    }, [])

    useEffect(() => {
      if(openNotification) {
        getNotificationFunc()
      }
    }, [openNotification])

    const allIsReadFunc = () => {
      request(`/notification/mark-all-as-read`, {
        method: 'POST',
      }).then((res) => {
        if (res.data.ok) {
          getNotificationFunc()
        }
      })
    }

    const allDeleteNotificationFunc = () => {
      request(`/notification/delete-all`, {
        method: 'DELETE',
      }).then((res) => {
        if (res.data.ok) {
          getNotificationFunc()
        }
      })
    }

    const singleIsReadFunc = (notification) => {
        if(notification.isRead){
           if(notification.url){
              setOpenUrlStatus({
                index: notification.id,
                url: notification.url,
                open: !openUrlStatus.open
              })
            } 
            return;
        }
        request(`/notification/mark-as-read?id=${notification.id}`, {
          method: 'POST',
        }).then((res) => {
          if (res.data.ok) {
            getNotificationFunc()
            if(notification.url){
              setOpenUrlStatus({
                index: notification.id,
                url: notification.url,
                open: true
              })
            } 
          }
        })
    }

    console.log(openUrlStatus, "openUrlStatus");
    
  return (
    <div className='notification'>
         <div onClick={() => setOpenNotification(!openNotification)} className='notification-title'>
             <img src={images.notification} alt="" />
             {notificationStatus && <div className='point'> </div>}
         </div>
         {
            openNotification && (
                <div className='notification-content'>
                      <div className='clipPathAbsolute'>aaaaa</div>
                      {
                        notifications.length > 0 ? (
                          notifications.map((notification,i) => (
                            <NotificationCard openUrlStatus={openUrlStatus} onClick={() => singleIsReadFunc(notification)} key={i} isRead={notification.isRead} id={notification.id} title={notification.title} description={notification.body} date={notification.createdDate}/>
                          ))
                        ) : (
                          <div className='notification-content-non'>
                             {t('therearenonewnotifications')}
                          </div>
                        )
                      }
                      {/* <NotificationCard borderType="green" title={t('thereisagifttobesentwaitingforyourapproval')} date="03.01.2024"/> */}
                      {notifications.length > 0 && <div className='notification-content-btnGroup'>
                        <Button onclick={allIsReadFunc} name={t('makeAllRead')} color='#fff' backgroundColor='#1E74AD' height='40px' />
                        <Button onclick={allDeleteNotificationFunc} name={t('deleteAll')} color='#fff' backgroundColor='#C1312F' height='40px' />
                      </div>}
                </div>
            )
         }
    </div>
  )
}

export default Notification