import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const giftTypeSlice = createSlice({
    name: 'giftType',
    initialState,
    reducers: {
      getGiftType: (state, action) => {      
          state.adminPanel.giftType = action.payload    
      },
      getGiftTypeFilterParametersFunc: (state, action) => {
          state.adminPanel.giftTypeFilterParameters = action.payload   
      }
      // addGiftType : (state, action) => {      
      //   state.adminPanel.giftType = [...state.adminPanel.giftType, action.payload ]   
      // }
    },
  })
  
  export const { getGiftType, getGiftTypeFilterParametersFunc } = giftTypeSlice.actions
  
  export default giftTypeSlice.reducer