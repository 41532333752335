/* eslint-disable */
import { PropTypes } from 'prop-types'
import ReactApexChart from 'react-apexcharts';

const Chart = ({type, data}) => {
  return (
    <div className='chartComp'>
        <ReactApexChart options={data.options} series={data.series} type={type} height={350} />
    </div>
  )
}

Chart.propTypes = {
	type: PropTypes.string,
}
export default Chart