import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  export const companyHospitalitySlice = createSlice({
    name: 'companyHospitality',
    initialState,
    reducers: {
      getCompanyHospitality: (state, action) => {      
          state.companyHospitalityObj.companyHospitality = action.payload    
      },
      getCompanyHospitalityFilterParametersFunc: (state, action) => {
          state.companyHospitalityObj.companyHospitalityFilterParameters = action.payload   
      }
    },
  })
  
  export const { getCompanyHospitality, getCompanyHospitalityFilterParametersFunc } = companyHospitalitySlice.actions
  
  export default companyHospitalitySlice.reducer