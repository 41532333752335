import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const Input = ({ 
  labelColor, 
  type, 
  value, 
  setValue, 
  placeholder, 
  label, 
  errorBorder, 
  multipleState, 
  name, 
  readOnly, 
  style, 
  addDebounce 
}) => {
  const inputLabelStyle = {
    color: labelColor,
    fontSize: "13px",
    marginBottom: "8px",
    ...style
  };

  const debounceRef = useRef();

  const inputChangeFunc = (e) => {
    const inputValue = e.target.value;
    if (multipleState) {
      setValue(prev => ({
        ...prev, 
        [e.target.name]: inputValue
      }));
    } else {
      setValue(inputValue);
    }
  };

  const handleChange = useCallback((e) => {
    if (addDebounce) {
      if (debounceRef.current) debounceRef.current.cancel();
      debounceRef.current = debounce(inputChangeFunc, 400);
      debounceRef.current(e);
    } else {
      inputChangeFunc(e);
    }
  }, [addDebounce, inputChangeFunc]);

  useEffect(() => {
    return () => {
      if (debounceRef.current) debounceRef.current.cancel();
    };
  }, []);

  return (
    <div className='searchInputContainer'>
      <label style={inputLabelStyle} htmlFor="">{label}</label>
      <input 
        readOnly={readOnly}
        name={name}
        className={`searchInput ${errorBorder ? "errorBorder" : ""}`} 
        placeholder={placeholder} 
        autoComplete="off" 
        type={type} 
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  labelColor: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  errorBorder: PropTypes.bool,
  multipleState: PropTypes.bool,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  style: PropTypes.object,
  addDebounce: PropTypes.bool
};

export default Input;
