import React from 'react'
import { PropTypes } from 'prop-types'

const Loading = ({width, height, wholePage, borderWidth}) => {
  const loadingStyle = {
     width: width,
     height: height,
     borderWidth: borderWidth
    //  border: `${borderWidth} solid #f3f3f3`,
    //  borderTop: `${borderWidth} solid ${borderColor}`,
    //  borderLeft: `${borderWidth} solid ${borderColor}`,
    //  borderBottom: `${borderWidth} solid ${borderColor}`
  }
  return (
    <div className={`${wholePage ? "loader-fixed" : ""}`}>
      <div style={loadingStyle} className="loader"> </div>
    </div>
  )
}


Loading.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
    wholePage: PropTypes.bool,
    borderWidth: PropTypes.string,
}

export default Loading