import { createSlice } from "@reduxjs/toolkit"
import { initialState } from '../../../initialState';


  
  export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
      getCurrencies: (state, action) => {      
          state.adminPanel.currencies = action.payload    
      },
      getCurrenyFilterParametersFunc: (state, action) => {
          state.adminPanel.currencyFilterParameters = action.payload   
      }
    },
  })
  
  export const { getCurrencies, getCurrenyFilterParametersFunc } = currencySlice.actions
  
  export default currencySlice.reducer