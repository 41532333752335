/* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import images from 'assets/images/images'

const Accordion = ({ title, open, onclick, content, type }) => {

	const startLiveChat = () => {
		const script = document.createElement('script')
		script.src = 'https://cdn.mindbehind.com/sdk/mindbehind-sdk.js?auto=true&key=626799ba3bc90263bb695ed6'
		script.async = true
		document.head.appendChild(script)

		script.onload = () => {
			const MB_TOKEN_LiveChat = 'vtPJmjEXT_SlQSSXAMTS3hvKiba22r2Xd82GtrXdX-M'
			window.MB && window.MB.startChat(MB_TOKEN_LiveChat)
		}
	}

	//helpLiveChat daha sonra kullanılacaktır..
	const helpLiveChat = () => {
		const script = document.createElement('script')
		script.src = 'https://cdn.mindbehind.com/sdk/mindbehind-sdk.js?auto=true&key=626799a93bc90263bb695ed5'
		script.async = true
		document.head.appendChild(script)

		script.onload = () => {
			const MB_TOKEN_LiveChat = 'VYNsH3dzCx4xzkeCi1nk7MCm-Ahm92eAibWdYZgO5Gg'
			window.MB && window.MB.startChat(MB_TOKEN_LiveChat)
		}
	}

	return (
		<div className='accordion'>
			<div onClick={onclick} className='accordion-container'>
				<div className='accordion-container-title'>{title}</div>
				<img src={open ? images.downArrow : images.rightArrow} alt='' />
			</div>
			{open &&
				(type == 0 ? (
					<div className='accordion-content'>
						{content.map((cont, i) => (
							<a rel='noreferrer' target='_blank' className='cabin-bold' key={i} href={cont.url}>
								{cont.name}
							</a>
						))}
					</div>
				) : (
					<div className='accordion-content'>
						{content.map((cont, i) =>
							cont.noClick ? ( // telefon tıklanamama durumu icin ...
								<a
								    style={cont.noClick ? {pointerEvents: "none"} : {}}
									onClick={(e) => {
										e.stopPropagation()
										e.preventDefault()
									}}
									href={'#'}
									className='accordion-content-div'
									key={i}
								>
									<img src={cont.img} alt='' />
									<div>{cont.name}</div>
								</a>
							) : (
								<a
									rel='noreferrer'
									target='_blank'
									style={{ cursor: 'pointer' }}
									onClick={cont.clickFunc && (localStorage.getItem("language") == "tr" ? helpLiveChat : startLiveChat)}
									href={!cont.clickFunc && cont.url}
									className='accordion-content-div'
									key={i}
								>
									<img src={cont.img} alt='' />
									<div>{cont.name}</div>
								</a>
							)
						)}
					</div>
				))}
		</div>
	)
}

Accordion.propTypes = {
	title: PropTypes.string,
	open: PropTypes.bool,
	onclick: PropTypes.func,
	content: PropTypes.array,
	type: PropTypes.number,
}

export default Accordion
