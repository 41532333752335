import React from 'react'
import { PropTypes } from 'prop-types'
import useWindowSize from './../../hooks/useWindowSize';

const Title = ({name, color, fontweight, size, onclick}) => {
  const {width} = useWindowSize();

  const styleProps = {
    color: color,
    fontWeight: fontweight,
    fontSize: width <= 480 ? "20px" :  size
  }
  return (
    <div onClick={onclick} style={styleProps}>{name}</div>
  )
}

Title.propTypes = {
	name: PropTypes.string,
	color: PropTypes.string,
	fontweight: PropTypes.string,
	size: PropTypes.string,
	onclick: PropTypes.func,
}

export default Title