/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import { usePagination } from '@table-library/react-table-library/pagination'
import { useSort } from '@table-library/react-table-library/sort'
import * as XLSX from 'xlsx'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { getReasonsForRefusal, getReasonsForRefusalFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/reasonsForRefusalSlice'
import { useRequest } from 'hooks/useRequest'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { FaRegEdit } from 'react-icons/fa'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import { permissionTypes } from 'types/permissionTypes'

const CustomSortIcon = () => {
	return <img src={images.filterico} alt='' style={{ margin: '0px' }} />
}

const ReasonForRefusal = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.reasonsForRefusal)

	const { modalId, modalStatus, modalToggle } = modals
	const { reasonsForRefusal, reasonsForRefusalFilterParameters } = adminPanel

	const [reasonsForRefusalAddingText, setReasonsForRefusalAddingText] = useState()
	const [reasonsForRefusalAddingDescription, setReasonsForRefusalAddingDescription] = useState()
	const [tReasonsForRefusalAddingText, setTReasonsForRefusalAddingText] = useState()
	const [tReasonsForRefusalAddingDescription, setTReasonsForRefusalAddingDescription] = useState()
	const [statusChecked, setStatusChecked] = useState(0)

	const [tabModal, setTabModal] = useState(0)

	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations

	const navigate = useNavigate()

	const handleRemove = (item) => {
		console.log('Edit icon clicked for item:', item)
		dispatch(
			openModal({
				open: true,
				type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: { ...item, deleteItem: 'reasons-refusal' } },
			})
		)
	}

	const handleEdit = (item) => {
		request(`/gift/reject-reason/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(
					openModal({
						open: true,
						type: {
							modalId: null,
							modalStatus: 'adminPanel-editModal',
							editableData: {
								...res.data.result,
								editItem: 'reasons-refusal',
							},
						},
					})
				)
			}
		})
	}

	const COLUMNS = [
		{
			label: t('reasonsforRefusal'),
			renderCell: (item) => item.name,
			name: '',
			//sort: { sortKey: 'reasonsForRefusal' },
		},
		{
			label: t('description'),
			renderCell: (item) => item.description,
			name: '',
			//sort: { sortKey: 'type' },
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div className={`cabin-semibold ${item.status == 1 ? 'tableActiveStatus' : 'tablePassiveStatus'}`}>
						{item.status == 1 ? t('active') : t('passive')}
					</div>
					{currentUser?.permissions[permissionTypes.GiftRejectReasonsUpsert] && (
						<div className='editBtn' onClick={(e) => e.stopPropagation()}>
							<FaRegEdit size={15} onClick={() => handleEdit(item)} />
							<div onClick={() => handleEdit(item)} className=''>
								{t('edit')}
							</div>
						</div>
					)}
					{currentUser?.permissions[permissionTypes.GiftRejectReasonsDelete] && (
						<div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
							<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
							<div onClick={() => handleRemove(item)} className=''>
								{t('delete')}
							</div>
						</div>
					)}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		request('/gift/reject-reason', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getReasonsForRefusalFilterParametersFunc(filterPrm))
				dispatch(getReasonsForRefusal(res.data.result.items))
			}
		})
	}, [])

	let nodes = reasonsForRefusal

	let data = { nodes }

	// const sort = useSort(
	// 	data.nodes,
	// 	{
	// 		onChange: onSortChange,
	// 	},
	// 	{
	// 		sortIcon: {
	// 			margin: '0px',
	// 			iconDefault: <CustomSortIcon />,
	// 			iconUp: <CustomSortIcon />,
	// 			iconDown: <CustomSortIcon />,
	// 			size: '10px',
	// 		},
	// 		sortFns: {
	// 			name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
	// 		},
	// 	}
	// )

	// function onSortChange(action, state) {
	// 	console.log(action, state)
	// }

	// const pagination = usePagination(data, {
	// 	state: {
	// 		page: 0,
	// 		size: 10,
	// 	},
	// 	onChange: onPaginationChange,
	// })

	// function onPaginationChange(action, state) {
	// 	console.log(action, state)
	// }

	const handleDownloadXlsx = () => {
		request('/gift/reject-reason/export-excel', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('gift-reasons-refusal.xlsx', res.data.result)
			}
		})
	}

	const reasonsForRefusalAddBtn = () => {
		request(`/gift/reject-reason`, {
			method: 'POST',
			data: {
				name: reasonsForRefusalAddingText,
				description: reasonsForRefusalAddingDescription,
				status: statusChecked,
				translations:
					tReasonsForRefusalAddingText || tReasonsForRefusalAddingDescription
						? [
								{
									language: 'en',
									name: tReasonsForRefusalAddingText,
									description: tReasonsForRefusalAddingDescription,
								},
						  ]
						: [],
			},
		}).then((res) => {
			if (res.data.ok) {
				//dispatch(addReasonsForRefusal(res.data.result))
				request('/gift/reject-reason', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						let filterPrm = {
							pageIndex: res.data.result.pageIndex,
							pageSize: res.data.result.pageSize,
							totalCount: res.data.result.totalCount,
							totalPages: res.data.result.totalPages,
							indexFrom: res.data.result.indexFrom,
						}
						dispatch(getReasonsForRefusalFilterParametersFunc(filterPrm))
						dispatch(getReasonsForRefusal(res.data.result.items))
					}
				})
			}
			toast.success('Ekleme işlemi başarılı')
			setTimeout(() => {
				dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
				setReasonsForRefusalAddingText()
				setReasonsForRefusalAddingDescription()
				setTReasonsForRefusalAddingText()
				setTReasonsForRefusalAddingDescription()
			}, 100)
		})
	}

	const goToPrevPage = () => {
		if (reasonsForRefusalFilterParameters?.pageIndex == 0) return
		request(`/gift/reject-reason?Page=${reasonsForRefusalFilterParameters?.pageIndex - 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getReasonsForRefusalFilterParametersFunc(filterPrm))
				dispatch(getReasonsForRefusal(res.data.result.items))
			}
		})
	}
	const goToNextPage = () => {
		if (reasonsForRefusalFilterParameters?.pageIndex + 1 >= reasonsForRefusalFilterParameters?.totalPages) return
		request(`/gift/reject-reason?Page=${reasonsForRefusalFilterParameters?.pageIndex + 2}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getReasonsForRefusalFilterParametersFunc(filterPrm))
				dispatch(getReasonsForRefusal(res.data.result.items))
			}
		})
	}

	const goToPage = (index) => {
		request(`/gift/reject-reason?Page=${index + 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getReasonsForRefusalFilterParametersFunc(filterPrm))
				dispatch(getReasonsForRefusal(res.data.result.items))
			}
		})
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: reasonsForRefusalFilterParameters,
	}


	return (
		<div className='panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.GiftRejectReasonsUpsert] && (
						<Button
							onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'adminPanel' } }))}
							name={t('addReasonforRejection')}
							img={images.plus}
							color='#fff'
							backgroundColor='#1e74ad'
							height='38px'
						/>
					)}
				</div>
			</div>

			<div className='tableCard'>
				<Table
					isLoading={loading}
					gridCSS='reason-for-refusal'
					paginationAllFunctionsList={paginationAllFunctionsList}
					data={data}
					columns={COLUMNS}
					filterType={'none'}
				/>
			</div>

			{modalId == 3 && modalToggle && modalStatus == 'adminPanel' && (
				<Modal>
					{/* <div className='modal-type1 modal-type1-changed'>
						<Title name={t('addReasonforRejection')} color='#242424' fontweight='bold' size='24px' />
						<div className='location-group-addingModal'>
							<Input
								value={reasonsForRefusalAddingText}
								setValue={setReasonsForRefusalAddingText}
								labelColor='#292929'
								label={t('reasonsforRefusal')}
								type='text'
							/>
						</div>
						<div  style={{marginTop: '-25px'}}  className='location-group-addingModal'>
					        <Input 
							   value={reasonsForRefusalAddingDescription} 
							   setValue={setReasonsForRefusalAddingDescription} 
							   labelColor='#292929' 
							   label={t('description')} 
							   type='text' />
                         </div>
						 <div className='modal-type1-checkArea'>
                              <input onChange={e => setStatusChecked(e.target.checked ? 1 : 0)} type="checkbox" name="" id="" />
							  <div>{t('active')}</div>
						 </div>
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={reasonsForRefusalAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div> */}

					<div className='tab-modal'>
						<div className='tab-modal-lgTitle'>
							<Title name={t('addReasonforRejection')} color='#242424' fontweight='bold' size='24px' />
						</div>
						<div className='tab-modal-titles'>
							<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
								{t('default')}
							</div>
							<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
								{t('english')}
							</div>
						</div>
						{tabModal == 0 ? (
							<div className='tab-modal-tab1'>
								<div key='0' className='tab-modal-tab1-inputArea'>
									<Input
										value={reasonsForRefusalAddingText}
										setValue={setReasonsForRefusalAddingText}
										labelColor='#292929'
										label={t('reasonsforRefusal')}
										type='text'
									/>
									<Input
										style={{ marginTop: '20px' }}
										value={reasonsForRefusalAddingDescription}
										setValue={setReasonsForRefusalAddingDescription}
										labelColor='#292929'
										label={t('description')}
										type='text'
									/>
								</div>

								<div style={{ marginTop: '20px' }} className='modal-type1-checkArea'>
									<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
									<div>{t('active')}</div>
								</div>
							</div>
						) : (
							<div className='tab-modal-tab1'>
								<div key='1' className='tab-modal-tab1-inputArea'>
									<Input
										value={tReasonsForRefusalAddingText}
										setValue={setTReasonsForRefusalAddingText}
										labelColor='#292929'
										label={t('reasonsforRefusal')}
										type='text'
									/>
									<Input
										style={{ marginTop: '20px' }}
										value={tReasonsForRefusalAddingDescription}
										setValue={setTReasonsForRefusalAddingDescription}
										labelColor='#292929'
										label={t('description')}
										type='text'
									/>
								</div>

								<div style={{ marginTop: '20px' }} className='modal-type1-checkArea'>
									<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
									<div>{t('active')}</div>
								</div>
							</div>
						)}
						<div className='tab-modal-modalGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={reasonsForRefusalAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default ReasonForRefusal
