
/* eslint-disable */
import React from 'react'
import Select, {components} from 'react-select';
import { PropTypes } from 'prop-types'
import  CreatableSelect  from 'react-select/creatable';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';



const Option = (props) => {
  const { data, innerRef, innerProps } = props;
  const { label } = data;

  return (
    <>
       <a data-tooltip-id={data?.description} data-tooltip-content={data?.description}>
         <components.Option {...props} /> 
       </a>
       <Tooltip style={{maxWidth: "100%"}} id={data?.description} />
    </>
  );
};

const OptionList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div style={style}>
          {children[index]}
        </div>
      )}
    </List>
  );
};

const SelectDr = ({options, placeholder, type, label, onChange, createOption, onInputChange, isLoading,nonClearable, defaultValue, readOnly, creatableSelect, fixedMenuPosition, value, windowParams}) => {
  const inputLabelStyle = {
      color : "#292929",
      fontSize: "13px",
      marginBottom: "8px",   
  }

  const { t } = useTranslation()

  const customFormatCreateLabel = (inputValue) => `${t('create')}: "${inputValue}"`;

  return (
    <div className='selectDr'>
      <label style={inputLabelStyle} htmlFor="">{label}</label>
      {
        creatableSelect ? (
          <CreatableSelect
          components={{ Option }}
          className="basic-single"
          classNamePrefix="select"
          defaultValue={defaultValue}
          formatCreateLabel={customFormatCreateLabel}
          value={value}
          //defaultValue={colourOptions[0]}
          isDisabled = {readOnly}
          createOption = {createOption}
          onInputChange = {onInputChange}
          onChange={onChange}
          placeholder={placeholder}
          //isLoading
          isLoading={isLoading}
          isClearable={nonClearable ? false : true }
          isSearchable
          name="color"
          options={options}
          id=''
          menuPosition={fixedMenuPosition ? "fixed" : "absolute"}
        />
        ) : (      
            type == 0 ? (
              <Select
              components={{ Option }}
              className="basic-single"
              classNamePrefix="select"
              defaultValue={defaultValue}
              value={value}
              //defaultValue={colourOptions[0]}
              isDisabled = {readOnly}
              createOption = {createOption}
              onInputChange = {onInputChange}
              onChange={onChange}
              placeholder={placeholder}
              //isLoading
              isLoading={isLoading}
              isClearable={nonClearable ? false : true }
              isSearchable
              name="color"
              options={options}
              id=''
              menuPosition={fixedMenuPosition ? "fixed" : "absolute"}
            />
            ) : (
              <Select
              components={windowParams ? {OptionList}: { Option }}
              className="basic-single"
              classNamePrefix="select"
              defaultValue={defaultValue}
              isDisabled = {readOnly}
              value={value}
              //defaultValue={colourOptions[0]}
              //isDisabled
              createOption = {createOption}
              onInputChange = {onInputChange}
              onChange={onChange}
              placeholder={placeholder}
              isLoading={isLoading}
              isClearable={nonClearable ? false : true }
              isSearchable
              name="color"
              isMulti
              options={options}
              menuPosition={fixedMenuPosition ? "fixed" : "absolute"}
            />
            )
        )
      }
    </div>
  )
}

SelectDr.propTypes = {
	options: PropTypes.array,
	placeholder: PropTypes.string,
	label: PropTypes.string,
  type: PropTypes.number,
  onChange: PropTypes.func,
  createOption: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  creatableSelect: PropTypes.bool,
  fixedMenuPosition: PropTypes.bool
}

export default SelectDr