/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
// import { usePagination } from '@table-library/react-table-library/pagination'
// import { useSort } from '@table-library/react-table-library/sort'
import * as XLSX from 'xlsx'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { FaRegEdit } from "react-icons/fa";
import { downloadFileFromStream } from 'utils/ExcelOutput'
import { permissionTypes } from 'types/permissionTypes'


const CustomSortIcon = () => {
	return <img src={images.filterico} alt='' style={{ margin: '0px' }} />
}

const GiftType = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
	const { adminPanel } = useSelector((state) => state.giftType)

	const { modalId, modalStatus, modalToggle } = modals
	const { giftType, giftTypeFilterParameters } = adminPanel

	const [giftTypeAddingText, setGiftTypeAddingText] = useState()
	const [giftTypeAddingDescription, setGiftTypeAddingDescription] = useState()
	const [tGiftTypeAddingText, setTGiftTypeAddingText] = useState()
	const [tGiftTypeAddingDescription, setTGiftTypeAddingDescription] = useState()
	const [statusChecked, setStatusChecked] = useState(0)
	const [incomingApprovalRequiredChecked, setIncomingApprovalRequiredChecked] = useState(false);
	const [outgoingApprovalRequiredChecked, setOutgoingApprovalRequiredChecked] = useState(false);
	const [excludeFromIncomingLimitChecked, setExcludeFromIncomingLimitChecked] = useState(false);
	const [excludeFromOutgoingLimitChecked, setExcludeFromOutgoingLimitChecked] = useState(false);

	const [tabModal, setTabModal] = useState(0)

	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations

	const navigate = useNavigate()

	const handleRemove = (item) => {
		dispatch(openModal({ open: true, type: { modalId: null, modalStatus: 'adminPanel-deleteModal', editableData: {...item, deleteItem: "gift-type"} } }))
	}
	const handleEdit = (item) => {
		request(`/gift/type/${item.id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				dispatch(openModal({
					open: true, 
					type: { 
						modalId: null, 
						modalStatus: 'adminPanel-editModal', 
					editableData: {
						...res.data.result, 
						editItem: "gift-type",
					 }, 
					
				} }))
			}
		})

		
	}

	const COLUMNS = [
		{
			label: t('giftType'),
			renderCell: (item) => item.name,
			name: "",
			//sort: { sortKey: 'type' },
		},
		{
			label: t('description'),
			renderCell: (item) => item.description,
			name: "",
			//sort: { sortKey: 'type' },
		},
		{
			label: '',
			renderCell: (item) => (
				<div className='lastCellFlex'>
					<div 
					className={`cabin-semibold ${
						item.status == 1
							? 'tableActiveStatus'
							: 'tablePassiveStatus'
					}`}
					>{item.status == 1 ? t('active') : t('passive')}</div>
					{currentUser?.permissions[permissionTypes.GiftTypesUpsert] && <div className='editBtn' onClick={(e) => e.stopPropagation()}>
						<FaRegEdit size={15} onClick={() => handleEdit(item)} />
						<div onClick={() => handleEdit(item)} className=''>
						{t('edit')}
						</div>
					</div>}
					{currentUser?.permissions[permissionTypes.GiftTypesDelete] && <div className='deleteBtn' onClick={(e) => e.stopPropagation()}>
						<MdOutlineDeleteSweep size={18} onClick={() => handleRemove(item)} />
						<div onClick={() => handleRemove(item)} className=''>
						{t('delete')}
						</div>
					</div>}
				</div>
			),
			name: '',
		},
	]

	useEffect(() => {
		// ?PageSize=15
		request('/gift/type', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getGiftTypeFilterParametersFunc(filterPrm))
				dispatch(getGiftType(res.data.result.items))
			}
		})
	}, [])


	let nodes = giftType

	let data = { nodes }


	const handleDownloadXlsx = () => {
		request('/gift/type/export-excel', {
			method: 'GET',
		}).then((res) => {
			if(res.data.ok){
				downloadFileFromStream('gift-type.xlsx', res.data.result)
			}
			
		})
	}

	const giftTypeAddBtn = () => {
			request(`/gift/type`, {
				method: 'POST',
				data: {
					name: giftTypeAddingText,
					description: giftTypeAddingDescription,
					status: statusChecked,
					incomingApprovalRequired: incomingApprovalRequiredChecked, 
					outgoingApprovalRequired: outgoingApprovalRequiredChecked,
					excludeFromIncomingLimit: excludeFromIncomingLimitChecked,
					excludeFromOutgoingLimit: excludeFromOutgoingLimitChecked,
					translations: (tGiftTypeAddingText || tGiftTypeAddingDescription) ? [{
						language: "en",
						name: tGiftTypeAddingText,
						description: tGiftTypeAddingDescription
					}] : []
				},
			}).then((res) => {
				if (res.data.ok) {
					//dispatch(addGiftType(res.data.result))
					request('/gift/type', {
						method: 'GET',
					}).then((res) => {
						if (res.data.ok) {
							let filterPrm = {
								pageIndex: res.data.result.pageIndex,
								pageSize: res.data.result.pageSize,
								totalCount: res.data.result.totalCount,
								totalPages: res.data.result.totalPages,
								indexFrom: res.data.result.indexFrom,
							}
							dispatch(getGiftTypeFilterParametersFunc(filterPrm))
							dispatch(getGiftType(res.data.result.items))
						}
					})
				}
				toast.success('Ekleme işlemi başarılı')
				setTimeout(() => {
					dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
					setGiftTypeAddingText()
					setTGiftTypeAddingText()
					setGiftTypeAddingDescription()
					setTGiftTypeAddingDescription()
				}, 100)
			})
	}

	const goToPrevPage = () => {
		if (giftTypeFilterParameters?.pageIndex == 0) return
		request(`/gift/type?Page=${giftTypeFilterParameters?.pageIndex - 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getGiftTypeFilterParametersFunc(filterPrm))
				dispatch(getGiftType(res.data.result.items))
			}
		})
	}
	const goToNextPage = () => {
		if (giftTypeFilterParameters?.pageIndex + 1 >= giftTypeFilterParameters?.totalPages) return
		request(`/gift/type?Page=${giftTypeFilterParameters?.pageIndex + 2}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				console.log(res.data, 'TETETETE')
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getGiftTypeFilterParametersFunc(filterPrm))
				dispatch(getGiftType(res.data.result.items))
			}
		})
	}

	const goToPage = (index) => {
		request(`/gift/type?Page=${index + 1}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getGiftTypeFilterParametersFunc(filterPrm))
				dispatch(getGiftType(res.data.result.items))
			}
		})
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: giftTypeFilterParameters,
	}


	return (
		<div className='panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
				<div className='topButtonGroup-container'>
					{/* <Loading width="20px" height="20px" wholePage={false} borderWidth = "3px"/> */}
					<Button
						onclick={handleDownloadXlsx}
						name={t('exportAsExcel')}
						img={images.exit}
						color='#008814'
						backgroundColor='transparent'
						height='38px'
					/>
					{currentUser?.permissions[permissionTypes.GiftTypesUpsert] && <Button
						onclick={() => dispatch(openModal({ open: true, type: { modalId: 3, modalStatus: 'adminPanel' } }))}
						name={t('addGiftType')}
						img={images.plus}
						color='#fff'
						backgroundColor='#1e74ad'
						height='38px'
					/>}
				</div>
			</div>
			
				<div className='tableCard'>
					<Table
					    gridCSS="gift-type"
						deleteBtn={true}
						paginationAllFunctionsList={paginationAllFunctionsList}
						data={data}
						columns={COLUMNS}
						filterType={'none'}
						isLoading = {loading}
					/>
				</div>
	
			{modalId == 3 && modalToggle && modalStatus == 'adminPanel' && (
				<Modal>
					{/* <div className='modal-type1 modal-type1-changed'>
						<Title name={t('addGiftType')} color='#242424' fontweight='bold' size='24px' />

						<div className='location-group-addingModal'>
							<Input
								value={giftTypeAddingText}
								setValue={setGiftTypeAddingText}
								labelColor='#292929'
								label={t('giftType')}
								type='text'
							/>
						</div>
						<div style={{ marginTop: '-25px' }} className='location-group-addingModal'>
							<Input
								value={giftTypeAddingDescription}
								setValue={setGiftTypeAddingDescription}
								labelColor='#292929'
								label={t('description')}
								type='text'
							/>
						</div>
						<div className='modal-type1-checkArea'>
							<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
							<div>{t('active')}</div>
						</div>
						<div className='modal-type1-checkArea'>
                              <input onChange={e => setIncomingApprovalRequiredChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
							  <div>{t('mandatoryIncomingGiftConfirmation')}</div>
						 </div>
						 <div className='modal-type1-checkArea'>
                              <input onChange={e => setOutgoingApprovalRequiredChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
							  <div>{t('outgoingGiftConsentMandatory')}</div>
						 </div>
						 <div className='modal-type1-checkArea'>
                              <input onChange={e => setExcludeFromIncomingLimitChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
							  <div>{t('noIncomingGiftLimitCheck')}</div>
						 </div>
						 <div className='modal-type1-checkArea'>
                              <input onChange={e => setExcludeFromOutgoingLimitChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
							  <div>{t('outgoingGiftNoLimitCheck')}</div>
						 </div>
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftTypeAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div> */}
					<div className='tab-modal'>
							<div className='tab-modal-lgTitle'>
								<Title name={t('addGiftType')} color='#242424' fontweight='bold' size='24px' />
							</div>
							<div className='tab-modal-titles'>
								<div className={tabModal == 0 ? 'active' : ''} onClick={() => setTabModal(0)}>
								{t('default')}
								</div>
								<div className={tabModal == 1 ? 'active' : ''} onClick={() => setTabModal(1)}>
								{t('english')}
								</div>
							</div>
							{tabModal == 0 ? (
								<div className='tab-modal-tab1'>
									<div key="0" className='tab-modal-tab1-inputArea'>
									<Input
										value={giftTypeAddingText}
										setValue={setGiftTypeAddingText}
										labelColor='#292929'
										label={t('giftType')}
										type='text'
									/>
									<Input
									    style={{marginTop: "20px"}}
										value={giftTypeAddingDescription}
										setValue={setGiftTypeAddingDescription}
										labelColor='#292929'
										label={t('description')}
										type='text'
									/>
									</div>
								
								<div style={{marginTop: '20px'}} className='modal-type1-checkArea'>
								<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
								<div>{t('active')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setIncomingApprovalRequiredChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('mandatoryIncomingGiftConfirmation')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setOutgoingApprovalRequiredChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('outgoingGiftConsentMandatory')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setExcludeFromIncomingLimitChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('noIncomingGiftLimitCheck')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setExcludeFromOutgoingLimitChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('outgoingGiftNoLimitCheck')}</div>
								</div>
								
													
								</div>
							) : (
								<div className='tab-modal-tab1'>
									<div key="1" className='tab-modal-tab1-inputArea'>
									<Input
										value={tGiftTypeAddingText}
										setValue={setTGiftTypeAddingText}
										labelColor='#292929'
										label={t('giftType')}
										type='text'
									/>
									<Input
									    style={{marginTop: "20px"}}
										value={tGiftTypeAddingDescription}
										setValue={setTGiftTypeAddingDescription}
										labelColor='#292929'
										label={t('description')}
										type='text'
									/>
									</div>
								
								<div style={{marginTop: '20px'}} className='modal-type1-checkArea'>
								<input onChange={(e) => setStatusChecked(e.target.checked ? 1 : 0)} type='checkbox' name='' id='' />
								<div>{t('active')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setIncomingApprovalRequiredChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('mandatoryIncomingGiftConfirmation')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setOutgoingApprovalRequiredChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('outgoingGiftConsentMandatory')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setExcludeFromIncomingLimitChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('noIncomingGiftLimitCheck')}</div>
								</div>
								<div style={{marginTop: '4px'}}  className='modal-type1-checkArea'>
									<input onChange={e => setExcludeFromOutgoingLimitChecked(e.target.checked ? true : false)} type="checkbox" name="" id="" />
									<div>{t('outgoingGiftNoLimitCheck')}</div>
								</div>
								
													
								</div>
							)}
							<div className='tab-modal-modalGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftTypeAddBtn} name={t('add')} color='#fff' backgroundColor='#1392e5' height='48px' />
							</div>
						</div>
				</Modal>
			)}
		</div>
	)
}

export default GiftType
