import React from 'react'
import { PropTypes } from 'prop-types'

const Button = ({onclick, name, img, imgOnce, color, backgroundColor, height}) => {
  const buttonStyle = {
    color: color, 
    backgroundColor: backgroundColor,
    height: height,
  }
  return (
    <button onClick={onclick} className='button'>
       {
        imgOnce ? (
          <div style={buttonStyle} className='cabin-bold'>
            {img && <img src={img} alt="" />}
             <div>{name}</div>
          </div>
        ) :(
          <div style={buttonStyle} className='cabin-bold'>
             <div>{name}</div>
             {img && <img src={img} alt="" />}
          </div>
        )
       }
    </button>
  )
}

Button.propTypes = {
	name: PropTypes.string,
	img: PropTypes.string,
  imgOnce: PropTypes.bool,
	color: PropTypes.string,
	backgroundColor: PropTypes.string,
  height: PropTypes.string,
  onclick: PropTypes.func
}

export default Button