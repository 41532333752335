/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import images from 'assets/images/images';
import { useTranslation } from 'react-i18next';
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice';

const Pagination = ({ paginationAllFunctionsList, data }) => {
    const { t } = useTranslation();
    
    const renderPageButtons = () => {
        const totalPages = paginationAllFunctionsList?.usageData?.totalPages;
        const currentPage = paginationAllFunctionsList?.usageData?.pageIndex + 1;

        const visiblePages = []; 

        const maxVisiblePages = 5;

        let startPage = 1;
        if (totalPages > maxVisiblePages) {
            if (currentPage > Math.floor(maxVisiblePages / 2)) {
                startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
            }
        }

        for (let i = startPage; i <= Math.min(startPage + maxVisiblePages - 1, totalPages); i++) {
            visiblePages.push(
                <button
                    key={i}
                    type='button'
                    className={`${paginationAllFunctionsList?.usageData?.pageIndex === i - 1 ? 'activePage' : ''}`}
                    style={{
                        fontWeight: paginationAllFunctionsList?.usageData?.pageIndex === i - 1 ? 'bold' : 'normal',
                    }}
                    onClick={() => paginationAllFunctionsList?.goToPage(i - 1)}
                >
                    {i}
                </button>
            );
        }

        return visiblePages;
    };

    return (
        <div className='table-pagination'>
            <div className='table-pagination-title'>
                {t('total')} {paginationAllFunctionsList?.usageData.totalCount} {t('data')} 1-{paginationAllFunctionsList?.usageData?.totalPages} {t('listingdatabetween')}
            </div>
            <div className='table-pagination-bottom'>
                <img className='table-pagination-bottom-imgLeft' src={images.paginationLeft} alt='' onClick={paginationAllFunctionsList?.goToPrevPage} />
                {renderPageButtons()} 
                <img className='table-pagination-bottom-imgRight' src={images.paginationRight} alt='' onClick={paginationAllFunctionsList?.goToNextPage} />
            </div>
        </div>
    );
};

Pagination.propTypes = {
    data: PropTypes.object.isRequired,
    paginationAllFunctionsList: PropTypes.object
};

export default Pagination;
