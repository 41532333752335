/* eslint-disable */
import Switch from "react-switch";
import { PropTypes } from 'prop-types'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { permissionTypes } from "types/permissionTypes";
import { FaAngleDown } from "react-icons/fa";
import DatePicker from 'react-datepicker'
import { useEffect, useRef, useState } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import Locale from "utils/Locale";


const SwitchComp = ({switchOpen,setSwitchOpen, setDateFilter, dateFilter}) => {
  const { t } = useTranslation()
  const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations

  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef()

  const handleSelect = (ranges) => {
    console.log(ranges, "ranges");
    setDateFilter(ranges.selection);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
 }, [])

 const handleClickOutside = (e) => {
      if(!e.target.closest('.switchComp-dateContainer') && inputRef.current && !inputRef.current.contains(event.target)){
         setIsOpen(false)
      }
 }

  return (
    <div className="switchComp">
     <div className="switchComp-dateContainer">
        <div className='table-filter-date'>
						<DatePicker
							placeholderText={t('startAccordingtoDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={dateFilter?.startDate}
							onChange={(date) => {setDateFilter(prev => ({...prev, startDate: date}))}}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
						/>
				</div>
        <div className='table-filter-date'>
						<DatePicker
							placeholderText={t('endAccordingtoDate')}
							//showTimeSelect
							dateFormat='dd/MM/yyyy'
							selected={dateFilter?.endDate}
							onChange={(date) => {setDateFilter(prev => ({...prev, endDate: date}))}}
							showPopperArrow={false}
							locale={Locale()}
							isClearable
              minDate={dateFilter?.startDate}
						/>
					</div>
     </div>
      {(currentUser?.permissions?.[permissionTypes.GiftsManagerApprove] ||
				currentUser?.permissions?.[permissionTypes.GiftsViewAllCompanies] ||
				currentUser?.permissions?.[permissionTypes.GiftsViewOwnCompany]) && 
        <div className="switchComp-container">
          <div>{switchOpen ? t('myOwnRecordings')  : t('allRecords')}</div>
          <Switch 
            onChange={setSwitchOpen} 
            checked={switchOpen} 
            uncheckedIcon={false}
            checkedIcon={false}
            //offColor={""}
            //width={48}
            //height={24}
          />
       </div>
        }
       
    </div>
  )
}

SwitchComp.propTypes = {
	switchOpen: PropTypes.boolean,
	setSwitchOpen: PropTypes.func,
  setDateFilter: PropTypes.func,
  dateFilter: PropTypes.object,
}

export default SwitchComp