import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MdOutlineNotificationsActive } from 'react-icons/md'
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { BsFillPersonFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'

const User = () => {
	const { t } = useTranslation()
	const [openUserMenu, setOpenUserMenu] = useState(false)
	const { permissions } = useSelector((state) => state.configuration)
	const { currentUser } = permissions.configurations
	const [request] = useRequest()

	const navigate = useNavigate()

	useEffect(() => {
		document.addEventListener('click', handleClickOutside)

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const handleClickOutside = (e) => {
		if (!e.target.closest('.user')) {
			setOpenUserMenu(false)
		}
	}

	const logoutFunc = () => {
		request('/auth/logout', {
			method: 'POST',
		}).then((res) => {
			if (res.data.ok) {
				localStorage.removeItem('auth')
				window.location.href = '/error/401'
			}
		})
	}

	const notificationFunc = () => {
		navigate('/notification')
		setOpenUserMenu(false)
	}
	const profileFunc = () => {
		navigate('/profile')
		setOpenUserMenu(false)
	}

	return (
		<div className='user' style={{ position: 'relative' }}>
			<div onClick={() => setOpenUserMenu(!openUserMenu)} className='user-title'>
				{currentUser.isAuthenticated && currentUser.fullName.split(' ').map((user, i) => <div key={i}>{user[0]}</div>)}
			</div>
			{openUserMenu && (
				<div className='language-content'>
					<div onClick={profileFunc} className='language-content-cell'>
						<BsFillPersonFill size={19} />
						<div className='cabin-bold'>{t('myAccount')}</div>
					</div>
					<div onClick={notificationFunc} className='language-content-cell'>
						<MdOutlineNotificationsActive size={19} />
						<div className='cabin-bold'>{t('notifications')}</div>
					</div>
					<div onClick={logoutFunc} className='language-content-cell'>
						<RiLogoutCircleRLine size={18} />
						<div className='cabin-bold'>{t('logOut')}</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default User
