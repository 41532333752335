/* eslint-disable */
import Button from 'components/common/Button'
import Input from 'components/common/Input'
import Loading from 'components/common/Loading'
import SelectDr from 'components/common/SelectDr'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

const Settings = () => {
	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState(0)
	const [request, loading] = useRequest()
	const [settings, setSettings] = useState()
	const [currencyIdSelectList, setCurrencyIdSelectList] = useState()
	const [currencyIdList, setCurrencyIdList] = useState()

	useEffect(() => {
		request('/currency/select-list?Export=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setCurrencyIdSelectList(res.data.result.items)
			}
		})

		request('/setting', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setSettings({
					...res.data.result.email,
					...res.data.result.gift,
					includedEmails: res.data.result.email.includedEmails || [],
					excludedEmails: res.data.result.email.excludedEmails || []
				})
			}
		})
	}, [])

	const updateSettinsFunc = () => {
		request('/setting', {
			method: 'POST',
			data: {
				email: {
					displayName: settings?.displayName,
					email: settings?.email,
					enableSsl: settings?.enableSsl,
					host: settings?.host,
					port: settings?.port,
					useDefaultCredentials: settings?.useDefaultCredentials,
					username: settings?.username,
					includedEmails: settings?.includedEmails,
					excludedEmails: settings?.excludedEmails,
				},
				gift: {
					//managerApprovalWaitDay: settings?.managerApprovalWaitDay,
					approvalReminderWaitDay: settings?.approvalReminderWaitDay,
					giftApprovalRevertTimeout : settings?.giftApprovalRevertTimeout,
					defaultIncomingLimit: settings?.defaultIncomingLimit,
					defaultOutgoingLimit: settings?.defaultOutgoingLimit,
					defaultLimitCurrencyId: settings?.defaultLimitCurrencyId,
				},
			},
		}).then((res) => {
			if (res.data.ok) {
				request('/setting', {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setSettings({
							...res.data.result.email,
							...res.data.result.gift,
							includedEmails: res.data.result.email.includedEmails || [],
							excludedEmails: res.data.result.email.excludedEmails || []
						})
						
						toast.success(t('updatesuccessful'))
					}
				})
			}
		})
	}

	useEffect(() => {
		setCurrencyIdList({
			value: settings?.defaultLimitCurrencyId,
			label: currencyIdSelectList?.find((currency) => currency.name == settings?.defaultLimitCurrencyId)?.value,
		})
	},[settings])

	const handleAddIncludedEmail = (emails) => {
		setSettings((prev) => ({ ...prev, includedEmails: emails }))
	}

	const handleAddExcludedEmail = (emails) => {
		setSettings((prev) => ({ ...prev, excludedEmails: emails }))
	}

	return loading ? (
		<div className='settingPage-loading'>
			<Loading width='45px' height='45px' borderWidth='5px' />
		</div>
	) : (
		<div className='settings'>
			<div className='settings-tab'>
				<div className={activeTab == 0 ? 'activeSettings' : ''} onClick={() => setActiveTab(0)}>
				{t('emailSettings')}
				</div>
				<div className={activeTab == 1 ? 'activeSettings' : ''} onClick={() => setActiveTab(1)}>
				{t('giftSettings')}
				</div>
			</div>
			<div className='settings-content'>
				{activeTab == 0 ? (
					<>
						<div className='settings-content-tab1'>
							<Input
								name='email'
								value={settings?.email}
								multipleState={true}
								setValue={setSettings}
								labelColor='#292929'
								label={'Email'}
								placeholder=''
								type='text'
							/>
							<Input
								name='displayName'
								value={settings?.displayName}
								multipleState={true}
								setValue={setSettings}
								labelColor='#292929'
								label={t('emailSenderName')}
								placeholder=''
								type='text'
							/>
							<Input
								name='host'
								value={settings?.host}
								multipleState={true}
								setValue={setSettings}
								labelColor='#292929'
								label={'Host'}
								placeholder=''
								type='text'
							/>
							<Input
								name='port'
								value={settings?.port}
								multipleState={true}
								setValue={setSettings}
								labelColor='#292929'
								label={'Port'}
								placeholder=''
								type='text'
							/>
							<Input
								name='username'
								value={settings?.username}
								multipleState={true}
								setValue={setSettings}
								labelColor='#292929'
								label={t('username')}
								placeholder=''
								type='text'
							/>
							<Input
								name='password'
								value={settings?.password}
								multipleState={true}
								setValue={setSettings}
								labelColor='#292929'
								label={t('password')}
								placeholder=''
								type='password'
							/>
							<div>
								<label>{t('includedEmails')}</label>
								<TagsInput
							
									value={settings?.includedEmails || []}
									onChange={(emails) => handleAddIncludedEmail(emails)}
									addKeys={[13, 9]} // Enter and Tab keys
									onlyUnique
									addOnBlur
									//inputProps={{ placeholder: t('addEmail') }}
								/>
							</div>
							<div>
								<label>{t('excludedEmails')}</label>
								<TagsInput
									value={settings?.excludedEmails || []}
									onChange={(emails) => handleAddExcludedEmail(emails)}
									addKeys={[13, 9]} // Enter and Tab keys
									onlyUnique
									addOnBlur
									//inputProps={{ placeholder: t('addEmail') }}
								/>
							</div>
						</div>
						<div className='settings-content-checkInput'>
							<div className=''>
								<input
									onChange={(e) => setSettings((prev) => ({ ...prev, enableSsl: e.target.checked ? true : false }))}
									checked={settings?.enableSsl}
									type='checkbox'
									name=''
									id='ssl'
								/>
								<label htmlFor='ssl'>SSL</label>
							</div>
							<div className=''>
								<input
									onChange={(e) => setSettings((prev) => ({ ...prev, useDefaultCredentials: e.target.checked ? true : false }))}
									checked={settings?.useDefaultCredentials}
									type='checkbox'
									name=''
									id='ssl'
								/>
								<label htmlFor='ssl'>{t('useDefaultCredentials')}</label>
							</div>
						</div>
					</>
				) : (
					<div className='settings-content-tab1'>
						{/* <Input
							name='managerApprovalWaitDay'
							value={settings?.managerApprovalWaitDay}
							multipleState={true}
							setValue={setSettings}
							labelColor='#292929'
							label={t('executiveApprovalWaitingDay')}
							placeholder=''
							type='number'
						/> */}
						<Input
							name='approvalReminderWaitDay'
							value={settings?.approvalReminderWaitDay}
							multipleState={true}
							setValue={setSettings}
							labelColor='#292929'
							label={t('approvalReminderWaitDay')}
							placeholder=''
							type='number'
						/>
						<Input
							name='giftApprovalRevertTimeout'
							value={settings?.giftApprovalRevertTimeout }
							multipleState={true}
							setValue={setSettings}
							labelColor='#292929'
							label={t('giftApprovalRevertTimeoutMinute')}
							placeholder=''
							type='number'
						/>
						<Input
							name='defaultIncomingLimit'
							value={settings?.defaultIncomingLimit}
							multipleState={true}
							setValue={setSettings}
							labelColor='#292929'
							label={t('defaultIncomingGiftLimit')}
							placeholder=''
							type='number'
						/>
						<Input
							name='defaultOutgoingLimit'
							value={settings?.defaultOutgoingLimit}
							multipleState={true}
							setValue={setSettings}
							labelColor='#292929'
							label={t('defaultOutgoingGiftLimit')}
							placeholder=''
							type='number'
						/>
						<SelectDr
							onChange={(value) => setSettings((prev) => ({ ...prev, defaultLimitCurrencyId: value ? value.value : '' }))}
							type={0}
							//isLoading={loading}
							defaultValue={currencyIdList}
							options={currencyIdSelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
							placeholder={t('select')}
							label={t('defaultGiftLimitCurrency')}
						/>
					</div>
				)}
				<div className='buttonContainer'>
					<Button height={'48px'} backgroundColor='#1392E5' color='white' name={t('update')} onclick={updateSettinsFunc} />
				</div>
			</div>
		</div>
	)
}

export default Settings
