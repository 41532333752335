/* eslint-disable */
import images from 'assets/images/images'
import Button from 'components/common/Button'
import Table from 'components/common/table/Table'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import { useTranslation } from 'react-i18next'
import Loading from 'components/common/Loading'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { useSelector } from 'react-redux'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import Input from 'components/common/Input'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import { getGiftType, getGiftTypeFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/giftTypeSlice'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { FaRegEdit } from "react-icons/fa";
import { getNotification, getNotificationFilterParametersFunc } from 'redux/features/dataManagement/notifications/notificationSlice'
import { IoCheckmarkDoneSharp,IoCheckmarkSharp} from "react-icons/io5";
import { getMailTemplate, getMailTemplateFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailTemplateSlice'
import { permissionTypes } from 'types/permissionTypes'
import { getMailLog, getMailLogFilterParametersFunc } from 'redux/features/dataManagement/adminPanel/mailLogSlice'
import moment from 'moment'
import { downloadFileFromStream } from 'utils/ExcelOutput'
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai'



const MailLog = () => {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const [request, loading] = useRequest()
	const { modals } = useSelector((state) => state.general)
    const { adminPanel } = useSelector((state) => state.mailLog)


	const { modalId, modalStatus, modalToggle } = modals
    const { mailLog, mailLogFilterParameters } = adminPanel


	const [sortingOrderByVal, setSortingOrderByVal] = useState('asc')

	//filter object
	const [filters, setFilters] = useState({
		search: '',
		startDate: null,	
		endDate: null,	
		sortingOrderByVal: "desc",
		sortingColumnName: "",
		page: 1,
	})

	const navigate = useNavigate()

	const COLUMNS = [
		{
			label: t('emailTemplateName'),
			renderCell: (item) => item.emailTemplateName,
			name: 'emailTemplateName',
		},
		{
			label: t('subject'),
			renderCell: (item) => item.subject,
			name: 'subject',
		},
		{
			label: t('toAddress'),
			renderCell: (item) => item.toAddress,
			name: 'toAddress',
		},
		{
			label: t('toName'),
			renderCell: (item) => item.toName,
			name: 'toName',
		},
		{
			label: t('userFullName'),
			renderCell: (item) => item.userFullName,
			name: '',
		},
		{
			label: t('isSent'),
			renderCell: (item) =>
				item.isSent ? <AiOutlineCheckSquare  color='green' size={27}/> : <AiOutlineCloseSquare  size={27} color='#c1312f'/>,
			name: 'isSent',
		},
		{
			label: t('retryCount'),
			renderCell: (item) => item.retryCount,
			name: 'retryCount',
		},
		{
			label: t('createdDate'),
			renderCell: (item) => new Date(item.createdDate).toLocaleDateString(i18n.language),
			name: "createdDate"
		},
		// {
		// 	label: '',
		// 	renderCell: (item) => (
		// 		<div className='lastCellFlex'>
		// 			<div 
		// 			className={`cabin-semibold ${
		// 				item.status == 1
		// 					? 'tableActiveStatus'
		// 					: 'tablePassiveStatus'
		// 			}`}
		// 			>{item.status == 1 ? t('active') : t('passive')}</div>
		// 		</div>
		// 	),
		// 	name: '',
		// },
	]

	useEffect(() => {
        setFilters(prev => ({...prev, page: 1}))
	}, [filters.startDate, filters.endDate, filters.search, filters.sortingColumnName, filters.sortingOrderByVal])

	useEffect(() => {
		request(`/email-template/email-notification?Page=${filters?.page}&Search=${filters?.search}&StartDate=${
			filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
		}&EndDate=${
			filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''
		}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				let filterPrm = {
					pageIndex: res.data.result.pageIndex,
					pageSize: res.data.result.pageSize,
					totalCount: res.data.result.totalCount,
					totalPages: res.data.result.totalPages,
					indexFrom: res.data.result.indexFrom,
				}
				dispatch(getMailLogFilterParametersFunc(filterPrm))
				dispatch(getMailLog(res.data.result.items))
			}
		})
	}, [filters])


	let nodes =  [...mailLog]?.sort((a,b) => moment(b.createdDate) - moment(a.createdDate))

	let data = { nodes }


	const goToPrevPage = () => {
		if (mailLogFilterParameters?.pageIndex == 0) return
		setFilters(prev => ({...prev, page: mailLogFilterParameters?.pageIndex - 1}))
	}
	const goToNextPage = () => {
		if (mailLogFilterParameters?.pageIndex + 1 >= mailLogFilterParameters?.totalPages) return
		setFilters(prev => ({...prev, page: mailLogFilterParameters?.pageIndex + 2}))
	}

	const goToPage = (index) => {
		setFilters(prev => ({...prev, page: index + 1}))
	}

	const sortTable = (e, column) => {
		e.preventDefault()
		e.stopPropagation()
		setFilters(prev => ({...prev, sortingColumnName: column.name, sortingOrderByVal: prev.sortingOrderByVal == "asc" ? 'desc' : 'asc'}))
	}

	let paginationAllFunctionsList = {
		goToPrevPage: goToPrevPage,
		goToNextPage: goToNextPage,
		goToPage: goToPage,
		usageData: mailLogFilterParameters,
	}

	const handleFilterChange = (filterName, value) => {
		setFilters({ ...filters, [filterName]: value })
	}

	const handleDownloadXlsx = () => {
		request(`/email-template/export-email-notification-excel?Page=${filters?.page}&Search=${filters?.search}&StartDate=${
			filters?.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : ''
		}&EndDate=${
			filters?.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : ''
		}&OrderBy=${filters.sortingColumnName ? filters.sortingColumnName + " "+ filters.sortingOrderByVal : ""}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				downloadFileFromStream('mail-log.xlsx', res.data.result)
			}
		})
	}

	return (
		<div className='panel-table location-group incoming-gift'>
			<div className='topButtonGroup'>
			<div className='topButtonGroup-container'>
				<Button
					onclick={handleDownloadXlsx}
					name={t('exportAsExcel')}
					img={images.exit}
					color='#008814'
					backgroundColor='transparent'
					height='38px'
				/>
			</div>
			</div>
				<div className='tableCard'>
					<Table
					  gridCSS="mailLog"
						deleteBtn={true}
						paginationAllFunctionsList={paginationAllFunctionsList}
						data={data}
						columns={COLUMNS}
						filterType={7}
						handleFilterChange={handleFilterChange}
						setFilters={setFilters}
						filters={filters}
						sortTable={sortTable}
						isLoading = {loading}
					/>
				</div>

		</div>
	)
}

export default MailLog
