import axios from 'axios'
import { useCallback, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify'
//import { toast } from 'react-toastify';

export const useRequest = () => {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState()

	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(err) => {
			setError(err)
			if (err.response?.status === 401) {
				window.location.href = process.env.REACT_APP_LOGIN_ENDPOINT
				return;
			}
			//console.log(error.config?.url, "TESTTT");

			if (err?.response?.data?.metadata?.message) {
				toast.error((
					<div>
					   {err?.response?.data?.metadata?.message?.split('\r\n').map((err,i) => (
						<div key={i}>
						  {err}
						</div>
					   ))}
					</div>
				))
			} else {
				toast.error('Beklenmedik bir hata oluştu, lütfen daha sonra tekrar deneyin.')
			}
		}
	)
	let apiUrl = process.env.REACT_APP_API_ENDPOINT
	  
	axios.defaults.baseURL = apiUrl

	const request = useCallback(async (url, options = {}, urlPopApi = false) => {
		setLoading(true)
		const lang = localStorage.getItem('language')
		const auth = JSON.parse(localStorage.getItem('auth'))
			options.headers = {
				 Authorization: `Bearer ${auth}`,
				'Content-Type': 'application/json',
				'X-Culture': lang ? lang : 'tr',
				...options.headers,
			}
			options.data = options.data ? options.data : { ...options.body }
			if (urlPopApi) {
				axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT.replace('/api', '');
			  } else {
				axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
			  }
			const response = await axios(url, options)
			.finally(() => setLoading(false))
			return response
	}, [])
	return [request, loading, error]
}
