import { createSlice } from "@reduxjs/toolkit"
import { initialState } from './../../../initialState';


  
  export const relationPartyTypeSlice = createSlice({
    name: 'relationPartyType',
    initialState,
    reducers: {
      getRelationPartyType: (state, action) => {      
          state.adminPanel.relationPartyType = action.payload    
      },
      getRelationPartyTypeFilterParametersFunc :(state, action) => {      
        state.adminPanel.relationPartyTypeFilterParameters = action.payload    
    },
      // addRelationPartyType : (state, action) => {      
      //   state.adminPanel.relationPartyType = [...state.adminPanel.relationPartyType, action.payload ]   
      // }
    },
  })
  
  export const { getRelationPartyType, getRelationPartyTypeFilterParametersFunc } = relationPartyTypeSlice.actions
  
  export default relationPartyTypeSlice.reducer