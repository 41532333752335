import React from 'react'
import { PropTypes } from 'prop-types'
import { IoCheckmarkDoneSharp, IoCheckmarkSharp, IoArrowForwardOutline  } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'


const NotificationCard = ({ isRead, title, description, date, onClick, openUrlStatus, id }) => {
	const { t, i18n } = useTranslation()
	return (
		<div onClick={onClick} className='notificationCard'>
			{/* <div className={`${borderType == "green" ? "greenBorder" : "redBorder"}`}> </div> */}
			<div style={{ width: '20px' }}>
				{isRead ? <IoCheckmarkDoneSharp color={'green'} size={20} /> : <IoCheckmarkSharp color={'red'} size={20} />}
			</div>
			<div className='notificationCard-right'>
				<div className='notificationCard-right-top'>
					<div className='notificationCard-right-title cabin-bold'>{title}</div>
					<div className='notificationCard-right-date'>{new Date(date).toLocaleString(i18n.language)}</div>
				</div>

				<div className='notificationCard-right-description'>{description}</div>
                {
					id == openUrlStatus?.index && openUrlStatus?.url && openUrlStatus?.open && (
                       <a target="_blank" rel="noreferrer" href={openUrlStatus?.url}>{t('view')}<IoArrowForwardOutline/></a>
					)
				}
				
			</div>
		</div>
	)
}

NotificationCard.propTypes = {
	isRead: PropTypes.bool,
	title: PropTypes.string,
	date: PropTypes.string,
	description: PropTypes.string,
	onClick: PropTypes.func,
	openUrlStatus: PropTypes.object,
	id: PropTypes.number
}

export default NotificationCard
