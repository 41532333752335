import Title from 'components/common/Title'
import React from 'react'
import Notification from './navbarComp/Notification'
import User from './navbarComp/User'
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from 'react-redux';
import { openSidebar } from 'redux/features/general/generalSlice';
import Language from './navbarComp/Language';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {permissions} = useSelector(state => state.configuration)
	const {currentUser} = permissions.configurations

  return (
    <div className='navbar'>
       <Title onclick={() => navigate('/')} size="24px" name={t('giftandHospitalitySystem')} color="#1392e5" fontweight="bold"/>
       <div className='navbar-right'>
          <Language/>
          {
            currentUser.isAuthenticated && (
              <>
               <Notification/>
               <User/>
              </>
            )
          }
         
          <div className='hamburgerMenu'>
            <GiHamburgerMenu size={22} onClick={() => dispatch(openSidebar())}/>
          </div>
       </div>
    </div>
  )
}

export default Navbar